<script setup lang="ts">
import { computed, useAttrs } from "vue";
import { storeToRefs } from "pinia";
import { useEnvironment } from "@store/environment";

interface IProps {
    src: string;
    alt: string;
    srcRetina?: string;
}

const props = defineProps<IProps>();

const attrs = useAttrs();
const { isMockerMode } = storeToRefs(useEnvironment());

const srcset = computed(() => {
    if (props.srcRetina) {
        return `${props.src} 1x, ${props.srcRetina} 2x`;
    }
});

const classes = computed(() => {
    let res: string = "";
    if (Array.isArray(attrs.class)) {
        res = attrs.class.join(" ");
    } else if (typeof attrs.class === "object") {
        if (attrs?.class) {
            res = Object.keys(attrs.class).filter((key) => (attrs.class as Record<string, unknown>)[key]).join(" ");
        }
    }

    return res || attrs.class || "";
});

const loading = computed(() => (isMockerMode.value ? "eager" : "lazy"));

</script>
<template>
    <img
        :class="classes"
        :src="src"
        :srcset="srcset"
        :alt="alt"
        :loading="loading"
    >
</template>

<style lang="scss">
object {
    pointer-events: none;
}
</style>
