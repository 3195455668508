import type { Method } from "./http/types";
import { jsonHttp } from "./http";
import { v4 as uuid } from "uuid";

enum SecuredUrlType {
    SECURED = "secured",
    ANON = "anon",
}

export enum SecuredType {
    SECURED = "PublicSecured",
    ANON = "PublicAnon",
}

export interface PublicApiV1Params {
    url: string;
    type(securedType: string): string;
    secured?: boolean;
    data?: Record<string, unknown>;
}
export enum PublicApiV1ErrorType {
    VALIDATION = "VALIDATION",
    NOT_FOUND = "NOT_FOUND",
    UNAUTHORIZED = "UNAUTHORIZED",
}
export interface PublicApiV1ResponsePagination {
    pageNumber: number;
    perPage: number;
    total: number;
}

export interface PublicApiV1ResponseSuccess <T> {
    responseId: string;
    error: null;
    data: T;
    pagination?: PublicApiV1ResponsePagination;
}
export interface PublicApiV1ResponseError {
    responseId: string;
    error: {
        type: PublicApiV1ErrorType;
        description: string;
    };
    data: null;
}
export type PublicApiV1Response <T> = PublicApiV1ResponseSuccess<T> | PublicApiV1ResponseError;
export function publicApiV1<R = any>(params: PublicApiV1Params) {
    const {
        url,
        type,
        secured = false,
        data = {},
    } = params;
    const trimmedUrl = url[0] === "/" ? url.slice(1) : url;

    const securedType = secured ? SecuredUrlType.SECURED : SecuredUrlType.ANON;
    return jsonHttp<PublicApiV1Response<R>>(`/public-api/v1/json/${securedType}/${trimmedUrl}`, {
        method: "post",
        body: JSON.stringify({
            type: type(secured ? SecuredType.SECURED : SecuredType.ANON),
            requestId: uuid(),
            ...data,
        }),
    });
}

export function publicApiV2<R = any>(params: PublicApiV1Params) {
    const {
        url,
        type,
        secured = false,
        data = {},
    } = params;
    const trimmedUrl = url[0] === "/" ? url.slice(1) : url;

    const securedType = secured ? SecuredUrlType.SECURED : SecuredUrlType.ANON;
    return jsonHttp<PublicApiV1Response<R>>(`/public-api/v2/json/${securedType}/${trimmedUrl}`, {
        method: "post",
        body: JSON.stringify({
            type: type(secured ? SecuredType.SECURED : SecuredType.ANON),
            requestId: uuid(),
            ...data,
        }),
    });
}

export interface IPublicApiParams {
    data?: Record<string, unknown>;
    method?: Method;
}
export function publicApi<R = any>(url: string, params: IPublicApiParams = {}) {
    const { data } = params;
    const method = params.method || "POST";

    const trimmedUrl = url[0] === "/" ? url.slice(1) : url;
    return jsonHttp<R>(`/public-api/json/${trimmedUrl}`, {
        method,
        body: data ? JSON.stringify(data) : undefined,
    });
}
