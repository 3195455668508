import i18n from "@i18n";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import redirectToLang from "@helpers/redirectToLang";
import { getAvailableLocales, getLocale, setLocaleCookie } from "@helpers/locales";
import { DEFAULT_COUNTRY, DEFAULT_LOCALE } from "@theme/configs/constsLocales";
import type { Locales, UserLocaleParams } from "@src/types/multilang";

export const useMultiLang = defineStore("multilang", () => {
    const localeInCookies = getLocale();
    const availableLocales = getAvailableLocales();

    const country = ref(DEV ? DEFAULT_COUNTRY : "");
    const defaultLocale = ref(DEFAULT_LOCALE);
    const locale = ref("");
    const locales = ref<Locales[]>([]);
    const userGeo = ref(DEV ? DEFAULT_COUNTRY : "");
    const userGeoRegion = ref("");

    const userLocale = computed(() => {
        return (localeInCookies && availableLocales[localeInCookies])
            ? localeInCookies
            : locale.value || defaultLocale.value;
    });
    const userLanguage = computed(() => {
        return userLocale.value.slice(0, 2);
    });

    const setLocale = (localeName: string) => {
        if (i18n.instance) {
            i18n.instance.locale.value = localeName;
        }
        locale.value = localeName;
    };
    const setUserGeo = (geo: string) => {
        userGeo.value = geo;
    };
    const setUserGeoRegion = (region: string) => {
        userGeoRegion.value = region;
    };
    const loadLocales = async () => {
        const localesMock: Locales[] = Object.keys(availableLocales).map((availableLocale, index) => {
            return {
                code: availableLocale,
                name: availableLocale,
                default: index === 0,
            };
        });

        const currentLocale = userLocale.value || defaultLocale.value;

        setLocale(currentLocale);

        locales.value = localesMock;

        return Promise.resolve();
    };
    const updateUserLocale = (lang: string) => {
        setLocaleCookie(lang);
        redirectToLang(defaultLocale.value, locales.value, lang);
        setLocale(lang);
    };
    const updateLocalUserLocale = async (payload: UserLocaleParams) => {
        const { locale: localeName, redirectUrl } = payload;

        if (localeName && availableLocales[localeName] && localeInCookies !== localeName) {
            setLocaleCookie(localeName);
            redirectToLang(defaultLocale.value, locales.value, localeName, redirectUrl);
        }

        return Promise.resolve();
    };

    return {
        country,
        defaultLocale,
        locale,
        locales,
        userGeo,
        userGeoRegion,
        userLocale,
        userLanguage,
        loadLocales,
        setLocale,
        setUserGeo,
        setUserGeoRegion,
        updateUserLocale,
        updateLocalUserLocale,
    };
});
