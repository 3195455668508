<template>
    <div class="timer">
        <template v-if="timerText[0] && days && days !== '00'">
            <span class="timer__item day">
                <span class="timer__count">{{ days }}</span>
                <span class="timer__desc">{{ timerText[0] }}</span>
            </span>
            <span class="timer__dots day">:</span>
        </template>

        <template v-if="timerText[1]">
            <span class="timer__item hour">
                <span class="timer__count">{{ hours }}</span>
                <span class="timer__desc">{{ timerText[1] }}</span>
            </span>
            <span class="timer__dots hour">:</span>
        </template>

        <template v-if="timerText[2]">
            <span class="timer__item minute">
                <span class="timer__count">{{ minutes }}</span>
                <span class="timer__desc">{{ timerText[2] }}</span>
            </span>
            <span class="timer__dots minute">:</span>
        </template>

        <template v-if="timerText[3]">
            <span class="timer__item second">
                <span class="timer__count">{{ seconds }}</span>
                <span class="timer__desc">{{ timerText[3] }}</span>
            </span>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useEnvironment } from "@store/environment";

const i18n = useI18n();
const { isMockerMode } = storeToRefs(useEnvironment());

interface IProps {
    end?: Date | number | null;
    text?: (string | null)[];
}
const props = defineProps<IProps>();

interface IEmits {
    (e: "end"): void;
}
const emit = defineEmits<IEmits>();

const now = ref<number>(0);
const diff = ref(0);
const interval = ref<ReturnType<typeof setInterval>>();
const ms = ref(1000);

const endValue = computed(() => {
    if (props.end instanceof Date) {
        return props.end.getTime();
    }
    return props.end;
});

const timerText = computed(() => {
    if (props.text) {
        return props.text;
    }
    return [
        i18n.t("COMMON.DAY"),
        i18n.t("COMMON.HOUR"),
        i18n.t("COMMON.MINUTE"),
        i18n.t("COMMON.SECOND"),
    ];
});

function dateFormat(value: number): string {
    return value < 10 ? `0${ value }` : String(value);
}

const seconds = computed(() => {
    if (isMockerMode.value) {
        return "48";
    }
    return dateFormat(Math.trunc(diff.value) % 60) || null;
});

const minutes = computed(() => {
    if (isMockerMode.value) {
        return "59";
    }
    return dateFormat(Math.trunc(diff.value / 60) % 60) || null;
});

const hours = computed(() => {
    if (isMockerMode.value) {
        return "23";
    }
    return dateFormat(Math.trunc(diff.value / 60 / 60) % 24) || null;
});

const days = computed(() => {
    if (isMockerMode.value) {
        return "9876";
    }
    return dateFormat(Math.trunc(diff.value / 60 / 60 / 24)) || null;
});

const date = computed(() => {
    if (endValue.value) {
        return Math.trunc(endValue.value / ms.value);
    }
});

function start() {
    if (isMockerMode.value) {
        return;
    }
    now.value = Math.trunc((new Date()).getTime() / ms.value);

    interval.value = setInterval(() => {
        now.value = Math.trunc((new Date()).getTime() / ms.value);
    }, ms.value);
}

watch(now, () => {
    diff.value = Number(date.value) - now.value;

    if (diff.value <= 0) {
        diff.value = 0;
        clearInterval(interval.value);
        emit("end");
    }
});
watch(endValue, () => {
    start();
});

onMounted(() => {
    start();
});
onBeforeUnmount(() => {
    clearInterval(interval.value);
});

</script>

<style>
    .timer,
    .timer__item {
        display: flex;
    }
</style>
