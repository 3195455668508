import { TournamentAccessVoter } from "@api";
import { PROMO_MECHANIC, PROMOTION_TYPE, type IPromotionCard } from "@controllers/services/promotions";
import { useUserStatusService } from "@controllers/services/user";
import { useLevels } from "@store/levels";
import { usePromo } from "@store/promo";
import { useTournaments } from "@store/tournaments";
import { useUserBalance } from "@store/userBalance";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export function usePromotion(promo: IPromotionCard) {
    const tournamentStore = useTournaments();
    const promoStore = usePromo();
    const userBalanceStore = useUserBalance();
    const i18n = useI18n();
    const levelsStore = useLevels();
    const { getStatusNameByCode } = useUserStatusService();

    const isPromoPageTypeStatic = ref<boolean>(promo.pageType === PROMO_MECHANIC.STATIC);
    const isPromoTypePromo = ref<boolean>(promo.type === PROMOTION_TYPE.PROMO);
    const isPromoTypeTournament = ref<boolean>(promo.type === PROMOTION_TYPE.TOURNAMENT);
    const isPromoLocked = computed(() => {
        if (isPromoTypePromo.value) {
            return promo?.access ?? (promo.access?.length as number) > 0;
        }
        return promo?.access && Object.keys(promo.access).length > 0;
    });

    const isMembership = computed(() => {
        if (isPromoTypePromo.value) {
            return promoStore.isMembershipByPromoId(promo.id);
        }
        if (isPromoTypeTournament.value) {
            return tournamentStore.isMembershipByTournamentId(promo.id);
        }
        return false;
    });

    function computeStatusVoterVariable(item: number[]) {
        const vars: string[] = [];

        if (item.length) {
            const sortVars = item.sort((first, second) => first - second);

            sortVars.forEach((el: number) => {
                vars.push(i18n.t(getStatusNameByCode(el)));
            });
        }

        return vars.join(", ");
    }

    const accessText = computed(() => {
        let result = "";
        const access = promo?.access;
        const currency = userBalanceStore.userCurrency;

        if (access) {
            if (isPromoTypePromo.value && (access.length as number) > 0) {
                result = i18n.t("PROMO.PROMO_USER_STATUS_VOTER", {
                    // @ts-expect-error incorrect types
                    statusList: computeStatusVoterVariable(access),
                });
            } else {
                for (const item in access) {
                    if (Object.prototype.hasOwnProperty.call(access, item)) {
                        const itemStr = String(item);

                        if (itemStr === TournamentAccessVoter.PARTNER) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_PARENT_VOTER");
                        } else if (itemStr === TournamentAccessVoter.USER) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_USER_VOTER");
                        } else if (itemStr === TournamentAccessVoter.ACTION) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_ACTION_VOTER");
                        } else if (itemStr === TournamentAccessVoter.LEVEL) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_LEVEL_VOTER", {
                                firstLvl:
                                // @ts-expect-error Object is of type unknown
                                    access[item].levels[0] && levelsStore.getLevelByIndex(access[item].levels[0])?.name,
                                lastLvl:
                                // @ts-expect-error Object is of type unknown
                                    access[item].levels[1] && levelsStore.getLevelByIndex(access[item].levels[1])?.name,
                            });
                        } else if (itemStr === TournamentAccessVoter.STATUS) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_USER_STATUS_VOTER", {
                                // @ts-expect-error Object is of type unknown
                                statusList: computeStatusVoterVariable(access[item].statuses),
                            });
                        } else if (itemStr === TournamentAccessVoter.DEPOSIT) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_DEPOSIT_VOTER", {
                                // @ts-expect-error Object is of type unknown
                                sum: `${access[item].sum_from[currency] / 100} ${currency}`,
                            });
                        } else if (itemStr === TournamentAccessVoter.DEPOSIT_LT) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_DEPOSIT_LT_VOTER", {
                                // @ts-expect-error Object is of type unknown
                                sum: `${access[item].sum_from[currency] / 100} ${currency}`,
                            });
                        } else if (itemStr === TournamentAccessVoter.BOT) {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_INVITE");
                        } else {
                            result = i18n.t("TOURNAMENTS.TOURNAMENT_INVITE");
                        }
                    }
                }
            }
        }

        return result;
    });

    return {
        isPromoPageTypeStatic,
        isPromoTypePromo,
        isPromoTypeTournament,
        isPromoLocked,
        isMembership,
        accessText,
    };
}
