export default function isPwaMode(mode) {
    const isStandalone = mode === "standalone";

    if (typeof window !== "undefined") {
        const params = window.location.search || "";

        return isStandalone ||
            params.includes("standalone") ||
            window.navigator.standalone === true ||
            window.matchMedia("(display-mode: standalone)").matches;
    }

    return isStandalone;
}
