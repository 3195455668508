import { publicApi } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";
import type { IAchievementResource } from "@api";

export default {
    async loadPersonalizedAchievements() {
        try {
            const { data } = await publicApi<{
                data: IAchievementResource[],
            }>("/achievements", { method: "GET" });
            return data;
        } catch (error) {
            log.error("LOAD_PERSONALIZED_ACHIEVEMENTS", error);
            throw error;
        }
    },
};
