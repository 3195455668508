<template>
    <LiveChatWidget
        license="12144738"
        :chat-between-groups="false"
        :group="group"
        :visibility="visibility"
        :customer-name="customerName"
        :customer-email="customerEmail"
        :session-variables="sessionVariables"
        @visibility-changed="visibilityChanged"
        @new-event="onNewEvent"
    />
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { LiveChatWidget, useWidgetIsReady } from "@livechat/widget-vue";
import { useUserInfo } from "@store/userInfo";
import { useChats } from "@store/chats";
import chatConfig from "@theme/configs/config";
import bus from "@bus";
import config from "@theme/configs/config";
import { useUserStatusService } from "@controllers/services/user/statusService";
import { useMultiLang } from "@store/multilang";

const chatsStore = useChats();
const userInfoStore = useUserInfo();
const multiLangStore = useMultiLang();
const isWidgetReady = useWidgetIsReady();
const { userVipStatus, quarterStatus } = useUserStatusService();

const DEFAULT_NAME = " Visitor ";

const VISIBILITY = {
    MAXIMIZED: "maximized",
    MINIMIZED: "minimized",
    HIDDEN: "hidden",
};
const defaultSessionVariables = {
    Id: " ",
    Link: " ",
    Status: "1SL",
    Project: config.liveChat.code,
    Language: multiLangStore.userLanguage,
};

const visibility = ref(VISIBILITY.HIDDEN);
const group = ref(chatConfig.liveChat.guestGroup);
const customerName = ref(DEFAULT_NAME);
const customerEmail = ref(" ");
const sessionVariables = ref(defaultSessionVariables);

function setChatOpen() {
    chatsStore.lcSetChatOpen();
}

function setMessage(message) {
    chatsStore.lcSetMessage(message);
}

function resetMessageCount() {
    chatsStore.lcResetMessageCount();
}

function hideChat() {
    visibility.value = VISIBILITY.HIDDEN;
    setChatOpen();
}

function detectLiveChatUserGroup() {
    if (!userInfoStore.isLogged) {
        return chatConfig.liveChat.guestGroup;
    }

    return userVipStatus.value
        ? chatConfig.liveChat.vipGroup
        : chatConfig.liveChat.group;
}

function open() {
    if (!isWidgetReady.value) {
        return;
    }

    visibility.value = VISIBILITY.MAXIMIZED;
    setChatOpen();
}

function visibilityChanged(data) {
    if (data.visibility === VISIBILITY.MAXIMIZED) {
        visibility.value = data.visibility;
    } else {
        hideChat();
    }
}

function onNewEvent(event) {
    if ([ "message", "emoji" ].includes(event.type) && event.author?.type === "agent") {
        if (!(visibility.value === VISIBILITY.MAXIMIZED)) {
            setMessage(event);
        }
    }
}

function setLiveChatData(userInfo = {}) {
    if (userInfo.firstname || userInfo.lastname) {
        customerName.value = `${userInfo.firstname || ""} ${userInfo.lastname || ""}`;
    } else {
        customerName.value = userInfo.nick_name;
    }

    customerEmail.value = userInfo.emails[0].email;
    group.value = detectLiveChatUserGroup();

    const link = `https://ws.upstr.to/${chatConfig.liveChat.code}/client-card/${userInfo.user_id}/real`;

    sessionVariables.value = {
        ...defaultSessionVariables,
        Id: userInfo.user_id,
        Link: link,
        Status: quarterStatus.value,
    };
}

function resetLiveChatData() {
    customerName.value = DEFAULT_NAME;
    customerEmail.value = " ";
    group.value = chatConfig.liveChat.guestGroup;
    sessionVariables.value = defaultSessionVariables;

    resetMessageCount();
}

onMounted(() => {
    bus.$on("chat.toggle", open);
    bus.$on("user.login", setLiveChatData);
    bus.$on("user.logout", resetLiveChatData);
});

onBeforeUnmount(() => {
    bus.$off("chat.toggle", open);
    bus.$off("user.login", setLiveChatData);
    bus.$off("user.logout", resetLiveChatData);
});

</script>
