import { publicApi, publicApiV1 } from "@api/publicApi";
import { jsonApi } from "@api/jsonApi";
import { JsonHttpServerError } from "@api/http/errors";
import log from "@front/core/controllers/LoggerController";
import type {
    IBetResource,
    ICurrencyRatesResource,
    IGeneralLimitResource,
    IInitDepositResource,
    InitPaymentDTO,
    IInitPayoutResource,
    ILoadPaymentMethodsResource,
    LoadTransactionHistoryDTO,
    IPayoutItemResource,
    ISumRangeResource,
    ITransactionHistoryItemResource,
} from "@api";

export default {
    async loadMethodsIn(): Promise<ILoadPaymentMethodsResource> {
        try {
            return await publicApi<ILoadPaymentMethodsResource>("/treasury/methods-in", { method: "GET" });
        } catch (error) {
            log.error("CASHBOX_LOAD_METHODS_IN", error);

            return {
                data: [],
                meta: {
                    depositManagerEnabled: false,
                },
            };
        }
    },
    async loadMethodsOut(): Promise<ILoadPaymentMethodsResource> {
        try {
            return await publicApi<ILoadPaymentMethodsResource>("/treasury/methods-out", { method: "GET" });
        } catch (error) {
            log.error("CASHBOX_LOAD_METHODS_OUT", error);

            return {
                data: [],
                meta: {
                    depositManagerEnabled: false,
                },
            };
        }
    },
    async loadWithdrawalDefaultAmount() {
        try {
            const response = await publicApiV1<Array<{
                amount: number;
                currency: string;
            }>>({
                url: "/treasury/withdrawal-defaults/list",
                type: (securedType) => `Treasury.V1.${securedType}.WithdrawalDefaults.List`,
            });
            return response.data || [];
        } catch (error) {
            log.error("CASHBOX_LOAD_WITHDRAWAL_DEFAULTS_AMOUNT", error);
        }
        return [];
    },
    async initDeposit(data: InitPaymentDTO) {
        try {
            return await publicApi<IInitDepositResource>("/treasury/deposit/init", { data });
        } catch (error: unknown) {
            log.error("CASHBOX_INIT_DEPOSIT", error);

            if (error instanceof JsonHttpServerError) {
                return error.error.data as IInitDepositResource;
            }
            throw error;
        }
    },
    async initPayout(data: InitPaymentDTO) {
        try {
            return await publicApi<IInitPayoutResource>("/treasury/payout/init", { data });
        } catch (error: unknown) {
            log.error("CASHBOX_INIT_PAYOUT", error);

            if (error instanceof JsonHttpServerError) {
                return error.error.data as IInitPayoutResource;
            }
            throw error;
        }
    },
    async loadWithdrawalRequests() {
        try {
            const { data } = await jsonApi<{
                data: IPayoutItemResource[],
            }>("/treasury/user/new-payouts", { method: "GET" });
            return data;
        } catch (error) {
            log.error("CASHBOX_LOAD_WITHDRAW_REQUESTS", error);
        }
        return [];
    },
    async cancelWithdrawRequest(transaction_id: string) {
        try {
            const response = await jsonApi<{
                status: boolean;
            }>("/treasury/user-payout/cancel", {
                data: { transaction_id },
            });
            return response.status;
        } catch (error) {
            log.error("CASHBOX_REMOVE_WITHDRAW_REQUEST", error);
        }
        return false;
    },
    async loadSumRange() {
        try {
            const { data } = await jsonApi<{
                data: ISumRangeResource[];
            }>("/treasury/sum-range", { method: "GET" });
            return data;
        } catch (error) {
            log.error("CASHBOX_LOAD_SUM_RANGE", error);
        }
        return [];
    },
    async loadGeneralLimit() {
        try {
            const { data } = await jsonApi<{
                data: IGeneralLimitResource[];
            }>("/treasury/general-limit", { method: "GET" });
            return data;
        } catch (error) {
            log.error("CASHBOX_LOAD_GENERAL_LIMIT", error);
        }
        return [];
    },
    async loadLastBet() {
        try {
            const { data } = await jsonApi<{
                data: IBetResource,
            }>("/treasury/user/last-bet", { method: "GET" });
            return data;
        } catch (error) {
            log.error("CASHBOX_LOAD_LAST_BET", error);
            throw error;
        }
    },
    async loadUserPayoutFlow() {
        try {
            const { data } = await publicApiV1<{
                flow: string;
            }>({
                url: "/treasury/user-payout-flow",
                type: (securedType) => `Treasury.V1.${securedType}.GetUserPayoutFlow`,
                secured: true,
            });
            return data?.flow;
        } catch (error) {
            log.error("CASHBOX_LOAD_USER_PAYOUT_FLOW", error);
            throw error;
        }
    },
    async loadTreasuryHistory(data: LoadTransactionHistoryDTO) {
        try {
            return await publicApiV1<ITransactionHistoryItemResource[]>({
                url: "/treasury/transaction-history",
                type: (securedType) => `Treasury.V1.${securedType}.GetTransactionHistory`,
                secured: true,
                data: {
                    pagination: {
                        pageNumber: data.page || 1,
                        perPage: data.perPage || 10,
                    },
                    filter: {
                        dateFrom: data.dateFrom,
                        dateTo: data.dateTo,
                        type: data.type,
                    },
                },
            });
        } catch (error) {
            log.error("LOAD_TREASURY_HISTORY", error);
            throw error;
        }
    },
    async loadCurrencyRates() {
        try {
            const response = await publicApiV1<ICurrencyRatesResource>({
                url: "/core/currency-rates",
                type: () => "Api.Core.CurrencyRates",
            });
            return response.data;
        } catch (error) {
            log.error("CASHBOX_LOAD_RATES_DATA", error);
            throw error;
        }
    },

    async loadUserTransactionNumbers() {
        try {
            return await publicApiV1<{ depositNumbers: number }>({
                url: "/treasury/user-transaction-numbers",
                secured: true,
                type: (securedType) => `Treasury.V1.${securedType}.GetUserTransactionNumbers`,
            });
        } catch (error) {
            log.error("CASHBOX_LOAD_USER_TRANSACTION_NUMBER", error);
            throw error;
        }
    },
};
