import type { HTTPOptions } from "./types";
import { SERVER_TIMEOUT, CLIENT_TIMEOUT, RETRY_COUNT } from "./config";
import {
    exponentialBackoff,
    concatBaseUrl,
    checkTimeoutExceed,
    createHttpAbortController,
} from "./helpers";
import { pinia } from "@store/pinia";
import { useServerStore } from "@store/server";
import { useMultiLang } from "@store/multilang";
import type { JsonHttpError } from "@api/http/errors";
import {
    JsonHttpJsonParseError,
    JsonHttpServerError,
    JsonHttpTimeoutError,
    JsonHttpUnknownError,
} from "@api/http/errors";

const isServer = import.meta.env.SSR;
const timeout = isServer ? SERVER_TIMEOUT : CLIENT_TIMEOUT;

export function jsonHttp<R = unknown>(url: string, request?: RequestInit, options: HTTPOptions = {}): Promise<R> {
    const serverStore = useServerStore(pinia);
    const multiLangStore = useMultiLang(pinia);
    const baseUrl: string = options?.baseURL || serverStore.apiUrl || "/";
    const requestUrl = concatBaseUrl(baseUrl, url);

    const headers = new Headers(request?.headers);
    headers.set("X-Requested-With", "XMLHttpRequest");
    headers.set("Accept", "application/json");
    if (request?.body) {
        headers.set("Content-Type", "application/json");
    }

    if (isServer) {
        headers.append("Cookie", `locale=${multiLangStore.userLocale};`);
        headers.append("geoip-country-code", multiLangStore.userGeo);
        headers.append("geoip-region-code", multiLangStore.userGeoRegion);
    }

    return new Promise((resolve, reject: (error: JsonHttpError) => void) => {
        function requestAttempt(attempt = 1) {
            const { signal } = createHttpAbortController(timeout);

            fetch(requestUrl, {
                ...request,
                signal,
                headers,
            }).then((response) => {
                if (response.ok) {
                    response.json().then((data: R) => {
                        resolve(data);
                    }).catch(() => {
                        response.text().then((text) => {
                            reject(new JsonHttpJsonParseError({
                                textOutput: text,
                                url: requestUrl,
                                method: request?.method || "GET",
                            }));
                        }).catch(() => {
                            reject(new JsonHttpJsonParseError({
                                textOutput: "",
                                url: requestUrl,
                                method: request?.method || "GET",
                            }));
                        });
                    });
                } else {
                    response.json().then((data: unknown) => {
                        reject(new JsonHttpServerError({
                            status: response.status,
                            statusText: response.statusText,
                            data,
                            url: requestUrl,
                            method: request?.method || "GET",
                        }));
                    }).catch(() => {
                        response.text().then((data: string) => {
                            reject(new JsonHttpServerError(({
                                status: response.status,
                                statusText: response.statusText,
                                data,
                                url: requestUrl,
                                method: request?.method || "GET",
                            })));
                        }).catch(() => {
                            reject(new JsonHttpServerError({
                                status: response.status,
                                statusText: response.statusText,
                                url: requestUrl,
                                method: request?.method || "GET",
                            }));
                        });
                    });
                }
            }).catch((error: unknown) => {
                const isTimeoutExceed = checkTimeoutExceed(signal);
                const isOnLine = isServer || navigator.onLine;

                if (isTimeoutExceed || !isOnLine && attempt < RETRY_COUNT) {
                    exponentialBackoff(attempt).then(() => {
                        requestAttempt(attempt + 1);
                    }).catch((retryError: unknown) => {
                        reject(new JsonHttpTimeoutError({
                            error: retryError,
                            url: requestUrl,
                            method: request?.method || "GET",
                        }));
                    });
                } else {
                    reject(new JsonHttpUnknownError({
                        error,
                        url: requestUrl,
                        method: request?.method || "GET",
                    }));
                }
            });
        }

        requestAttempt();
    });
}
