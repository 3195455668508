import type { IUserBalanceResource, IUserWinbackDataResource } from "@api";
import { userAPI } from "@api";
import { Currency } from "@api/resources/commonResources";
import { DEFAULT_CURRENCY } from "@theme/configs/constsCurrencies";

import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useUserBalance = defineStore("userBalance", () => {
    function baseBalanceData(): IUserBalanceResource {
        return {
            user_id: "",
            balance: 0, // user's main balance with coins
            currency: DEFAULT_CURRENCY as Currency,
            is_active_bonus: false, // whether bonus funds are available to the user
            bonus: 0, // bonus user balance with coins
            wagering: 0, // wagering amount with coins
            totalWagering: 0,
            bonuses: {
                // bonus balances like: sport, freeBet...
                sport: null,
                freeBet: null,
            },
            bonusesWagering: {
                casino: null,
                sport: null,
            },
        };
    }
    const balanceData = ref<IUserBalanceResource>(baseBalanceData());

    async function loadUserBalance() {
        const data = await userAPI.loadUserBalance();
        setUserBalance(data);
    }

    function setUserBalance(data: IUserBalanceResource) {
        balanceData.value = {
            ...balanceData.value,
            ...data,
        };
    }

    function baseWinbackData() {
        return {
            winback: 0,
            multiplier: 1,
            winbackStatus: false,
        };
    }

    const winbackData = ref<IUserWinbackDataResource>(baseWinbackData());

    async function loadWinbackData() {
        const data = await userAPI.loadWinbackData();

        if (data) {
            winbackData.value = data;
        }
    }

    const userCurrency = computed<Currency>(() => {
        return balanceData.value.currency;
    });

    function setUserCurrency(currency: Currency) {
        balanceData.value.currency = currency;
    }

    function cleanUserBalances() {
        balanceData.value = baseBalanceData();
        winbackData.value = baseWinbackData();
    }

    return {
        balanceData,
        loadUserBalance,
        setUserBalance,
        winbackData,
        loadWinbackData,
        userCurrency,
        setUserCurrency,
        cleanUserBalances,
    };
});
