import licenseImg from "@theme/images/license.png";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { contacts } from "@theme/configs/contacts.json";

import icon18 from "@modules/Footer/components/TrustBlock/img/icon-18.svg";
import iconSsl from "@modules/Footer/components/TrustBlock/img/icon-ssl.svg";
import iconGdpr from "@modules/Footer/components/TrustBlock/img/icon-gdpr.svg";
import iconECorga from "@modules/Footer/components/TrustBlock/img/icon-ecorga.svg";

import * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import * as FeTextConfig from "@ui-kit/FeText/index.vue";

import { colorsList } from "@ui-kit/lib";

export default {
    liveChat: {
        code: "Thor",
        vipGroup: "22",
        group: "21",
        guestGroup: "20",
    },
    featureFlags: getFeatureFlags(),
    licence: true,
    licenseImg,
    hotjar_id: "",
    fastTrackBrand: "winspirit",

    gameSlugs: {
        bonusWagering: "bonus-wagering",
    },
    links: {
        bonusPage: "/bonus",
        bonusTermsPage: "/bonus-terms-and-conditions",
        bonusSportTermsPage: "/sports-bonus-terms-and-conditions",
        termsAndConditionsPage: "/terms-and-conditions",
    },
    termsConditionsSlug: {
        valid: "terms-and-conditions",
        noValid: "terms-and-conditions-second",
    },
    privacyPolicySlug: {
        valid: "privacy-policy",
        noValid: "privacy-policy-second",
    },
    PROMO_TOOLTIP_TIMER_MINUTES: 10,
    REGISTRATION_TERMS_BEFORE_BUTTON: false,
    trust: [
        { icon: icon18, to: "#", index: 1, hideToSpecialDomain: true },
        { icon: iconSsl, to: "#", index: 2 },
        { icon: iconGdpr, to: "#", index: 3 },
        {
            icon: iconECorga,
            blank: true,
            to: "https://secure.ecogra.org/certification/2c34e111-e575-438f-8c5e-9c90352a22a8",
            index: 4,
        },
    ],
    contacts,
    buttons: {
        Quest: {
            button: FeButtonConfig.themes.PrimaryBig,
        },
        TournamentPage: {
            button: FeButtonConfig.themes.PrimaryBig,
        },
        GameRow: {
            rowNavigtion: FeButtonConfig.themes.SecondarySmall,
        },
        Notice: {
            button: FeButtonConfig.themes.SecondaryExtraSmall,
        },
        DropdownBalance: {
            profile: FeButtonConfig.themes.SecondarySmall,
        },
    },
    texts: {
        ProfileGiftEmpty: {
            size: FeTextConfig.size.Small,
        },
    },
    colors: {
        HomeBannerSlide: "#54206C",
        infoIconTheme: "DARK",
        scrollBar: "rgba(88, 72, 139, 0.72)",
    },
    margins: {
        headerAnchorMargin: "6rem",
    },
    theme: "theme-dark",
    footerProducerTheme: "theme-dark",
    SHOW_PROMO_MODAL_ACTION: true,
    LEVELS_COUNT: 12,
    defaultAcceptTerms: false,
    idlePageTitle: {
        enabled: true,
        idleStartDelay: 0,
        changeDelay: 2000,
    },
    cashbox: {
        sumButtonsCount: 4,
    },
    FeTooltip: {
        defaultTheme: {
            color: colorsList.danger,
        },
    },
    pwaPromoLink: "/play-with-the-app",
    pwaInstallLink: "/#install-pwa",
    defaultGradient: `linear-gradient(
                    90deg,
                    #3CE4A8 0%,
                    #3CE4A8 calc(50% - 1300px),
                    #3CE4A8 calc(50% - 750px),
                    #4D78FF calc(50% + 750px),
                    #4D78FF calc(50% + 1300px),
                    #4D78FF 100%
              )`,
};
