import { inject } from "vue";
import { CLOSE_MODAL_INJECTION_KEY } from "./injectionKeys";

export function useModalControls() {
    const close = inject(CLOSE_MODAL_INJECTION_KEY);
    return {
        closeHandler() {
            if (close) {
                close();
            }
        },
    };
}
