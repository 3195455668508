import type {
    IChangePasswordDTO,
    ILoginDTO,
    ILoginResource,
    ILogoutResource,
    IRegisterDTO,
    IRegisterResource,
    IVerifyEmailResource,
} from "@api";
import { jsonApi } from "@api/jsonApi";
import { publicApi, publicApiV1 } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";

import { isServer } from "@helpers/ssrHelpers";
import { mainDomain } from "@theme/configs/configDomains";

export default {
    async login(data: ILoginDTO) {
        const response = await jsonApi<ILoginResource>("/login", {
            // @ts-expect-error Type ILoginDTO is not assignable to type Record<string, unknown>
            data,
        });
        return response.step;
    },
    async logout() {
        return await jsonApi<ILogoutResource>("/logout");
    },
    async register(data: IRegisterDTO) {
        return await jsonApi<IRegisterResource>("/users/register", {
            // @ts-expect-error Type IRegisterDTO is not assignable to type Record<string, unknown>
            data,
        });
    },
    async changePassword(data: IChangePasswordDTO) {
        try {
            return await publicApi("/set-password", {
                // @ts-expect-error Type IChangePasswordDTO is not assignable to type Record<string, unknown>
                data,
            });
        } catch (error) {
            log.error("SET_NEW_PASSWORD", error);
        }
    },
    async verifyEmail(email: string) {
        try {
            const { data } = await publicApiV1<IVerifyEmailResource>({
                url: "/verify-email",
                type: (securedType) => `PublicApi.V1.${securedType}.Email.Verify`,
                data: {
                    data: {
                        email,
                    },
                },
            });
            return data;
        } catch (error) {
            log.error("VERIFY_EMAIL", error);
        }
    },
    async getAutologinUrl(path = "/"): Promise<string> {
        try {
            const { data } = await publicApi<{
                data: {
                    url: string;
                };
            }>("/auto-login", {
                data: {
                    path,
                    domain: isServer ? mainDomain : window.location.hostname,
                },
            });

            return data.url;
        } catch (error) {
            log.error("GET_AUTOLOGIN_URL", error);
            throw error;
        }
    },
};
