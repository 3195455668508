import type { ILevelResources, IUserLevelResources } from "@api";
import { publicApiV1 } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";

export default {
    async loadLevelsData() {
        try {
            const { data } = await publicApiV1<ILevelResources[]>({
                type: () => "Level.PublicAnon.Level.List",
                url: "/level/list",
            });
            return data || [];
        } catch (error) {
            log.error("LOAD_LEVEL_DATA", error);
        }
        return [];
    },
    async loadUserLevelData() {
        try {
            const { data } = await publicApiV1<IUserLevelResources>({
                type: (securedType) => `Level.V1.${securedType}.UserLevel.Get`,
                url: "/level/user-level/get",
                secured: true,
            });
            return data || {} as IUserLevelResources;
        } catch (error) {
            log.error("LOAD_USER_LEVEL_DATA", error);
        }
    },
};
