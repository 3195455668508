import { publicApiV1 } from "@api/publicApi";
import type { IBannerData } from "@api";
import log from "@front/core/controllers/LoggerController";

export default {
    async loadBannersDataByTag(secured: boolean, tags: string | string[] = []) {
        const tagList = Array.isArray(tags) ? tags : [ tags ];
        const type = (securedType: string) =>
            (secured ? `Banners.V1.${securedType}.Banner.List` : `Banners.${securedType}.Banner.List`);
        try {
            const { data } = await publicApiV1<IBannerData[]>({
                url: "/banners/list",
                secured,
                type,
                data: {
                    filter: {
                        tags: tagList,
                    },
                },
            });
            return data ?? [];
        } catch (error) {
            log.error("LOAD_BANNERS_DATA_BY_PAGE", error);
            return [];
        }
    },
};
