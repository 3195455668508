<script setup lang="ts">
import { MODAL_QUEST } from "@config/modalNames";
import QuestsModal from "./QuestsModal.vue";
import { useModalsStore } from "@store/modals";
import type { IAchievementResource } from "@api";
import FeProgressBar from "@ui-kit/FeProgressBar/FeProgressBar.vue";
import Timer from "@components/Timer/Timer.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import { useQuestData } from "../composables/useQuests";

interface IProps {
    quest: IAchievementResource;
    showTimerLabel?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
    showTimerLabel: false,
});

const modalsStore = useModalsStore();

const {
    questLevel,
    questTitle,
    labelText,
    timerText,
    progressText,
    progressBar,
    timerTime,
    questClasses,
} = useQuestData(props.quest);

const showFullDescription = () => {
    modalsStore.showModal({
        name: MODAL_QUEST,
        component: QuestsModal,
        props: {
            quest: props.quest,
        },
        mobileFriendly: true,
    });
};
</script>

<template>
    <div class="quest-item" @click="showFullDescription">
        <div class="quest__label" :class="questClasses">
            {{ labelText }}
        </div>
        <div v-if="timerTime" class="quest__timer">
            <div v-if="showTimerLabel" class="quest__timer-text">
                {{ timerText }}
            </div>
            <Timer :end="timerTime" :text="[ $t('COMMON.DAY'), ' ', ' ', ' ' ]" dots />
        </div>
        <div class="quest__wrapper-image">
            <div class="quest__image">
                <FeImage v-if="quest.image" :src="quest.image" alt="quest level" />
                <div v-if="quest.level" class="quest__achive-level">
                    {{ questLevel }}
                </div>
            </div>
        </div>

        <h3 class="quest__title">
            {{ questTitle }}
        </h3>
        <div class="quest__target">
            <FeProgressBar
                v-if="progressText"
                class="quest__progress"
                :progress="progressBar"
                big
            >
                <template #inside>
                    {{ progressText }}
                </template>
            </FeProgressBar>
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
