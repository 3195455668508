import { licenseDomains, specialDomain, type ILicenseDomain } from "@theme/configs/configDomains";
import { onMounted, ref } from "vue";

export function getIsLicenseDomain(hostName?: string): boolean {
    return licenseDomains.some((domain: ILicenseDomain) => {
        return domain.domainName === hostName;
    });
}
export function getIsSpecialDomain(hostName: string): boolean {
    return specialDomain.some((host) => {
        return host === hostName;
    });
}
export function useDomainConfig() {
    const isLicenseDomain = ref<boolean>(false);
    const isSpecialDomain = ref<boolean>(false);

    onMounted(() => {
        isLicenseDomain.value = getIsLicenseDomain(window.location.hostname);
        isSpecialDomain.value = getIsSpecialDomain(window.location.hostname);
    });

    return {
        isLicenseDomain,
        isSpecialDomain,
    };
}
