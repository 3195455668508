import type { IAchievementResource } from "@api";
import { questsTypes, QuestType } from "../config/config";

function sortByStatus(prev: IAchievementResource, next: IAchievementResource) {
    const prevIndex = questsTypes[prev.typeLabel].index;
    const nextIndex = questsTypes[next.typeLabel].index;

    return prevIndex - nextIndex;
}

function sortActive(prev: IAchievementResource, next: IAchievementResource) {
    const active = QuestType.ACTIVE;

    if (prev.typeLabel === active && next.typeLabel === active) {
        const prevProgress = prev.progress.current / prev.progress.max;
        const nextProgress = next.progress.current / next.progress.max;

        return prevProgress - nextProgress || 0;
    }

    return 0;
}

function sortAccess(prev: IAchievementResource, next: IAchievementResource) {
    const access = questsTypes.access.name;

    if (prev.typeLabel === access && next.typeLabel === access) {
        return (prev.progress.createdAt && next.progress.createdAt) ?
            (new Date(prev.progress.createdAt)).getTime() - (new Date(next.progress.createdAt)).getTime() :
            0;
    }

    return 0;
}

function sortSoon(prev: IAchievementResource, next: IAchievementResource) {
    const soon = questsTypes.soon.name;
    if (prev.typeLabel === soon && next.typeLabel === soon) {
        return (prev.startTime && next.startTime) ?
            (new Date(prev.startTime)).getTime() - (new Date(next.startTime)).getTime() :
            0;
    }

    return 0;
}

function sortDone(prev: IAchievementResource, next: IAchievementResource) {
    const done = questsTypes.done.name;

    if (prev.typeLabel === done && next.typeLabel === done) {
        return (prev.progress.doneAt && next.progress.doneAt) ?
            (new Date(next.progress.doneAt)).getTime() - (new Date(prev.progress.doneAt)).getTime() :
            0;
    }

    return 0;
}

export function sortAchievements(payload: IAchievementResource[]) {
    return [ ...payload ]
        .sort(sortByStatus)
        .sort(sortActive)
        .sort(sortAccess)
        .sort(sortSoon)
        .sort(sortDone);
}

export function filterActiveAccessSoon(payload: IAchievementResource[]) {
    return payload.filter((item) => {
        return item.typeLabel === QuestType.ACTIVE ||
            item.typeLabel === QuestType.ACCESS ||
            item.typeLabel === QuestType.SOON;
    });
}

export function filterActiveAccess(payload: IAchievementResource[]) {
    return payload.filter((item) => {
        return item.typeLabel === QuestType.ACTIVE || item.typeLabel === QuestType.ACCESS;
    });
}

export function filterDone(payload: IAchievementResource[]) {
    return payload.filter((item) => {
        return item.typeLabel === QuestType.DONE;
    });
}
