import type { I18nInstance, MessageSchema } from "./types";
import { createI18n as _createI18n } from "vue-i18n";
import config from "./config";
import { useMultiLang } from "@store/multilang";
import type { Pinia } from "pinia";

export const createI18n = (pinia?: Pinia) => {
    const multiLangStore = useMultiLang(pinia);
    const oldLocale = multiLangStore.userLocale;

    return _createI18n<[MessageSchema], string, false>({
        ...config(oldLocale, oldLocale),
        legacy: false,
        allowComposition: true,
        warnHtmlMessage: false,
    });
};

const i18n: I18nInstance = {
    instance: null,
    init() {
        const _i18n = createI18n();
        this.instance = _i18n.global;
        return this.instance;
    },
};

export default i18n;
