import { useI18n } from "vue-i18n";
import { useUserBalance } from "@store/userBalance";

function useParserContent() {
    const i18n = useI18n();
    const userBalanceStore = useUserBalance();

    function varsByUserCurrency(paramsObj: Record<string, any>) {
        const obj: Record<string, any> = {};

        for (const [ key, val ] of Object.entries(paramsObj)) {
            obj[key] = val[userBalanceStore.userCurrency] || val.USD;
        }

        return obj;
    }

    return {
        parseContent: (content: string, paramsObj?: Record<string, any>): string => {
            if (!content) {
                return "";
            }
            if (paramsObj && Object.keys(paramsObj).length !== 0) {
                const params = varsByUserCurrency(paramsObj);
                return i18n.rt(content, params);
            }
            return i18n.rt(content);
        },
        parseContentByKey: (key: string, paramsObj?: Record<string, any>) => {
            if (paramsObj && Object.keys(paramsObj).length !== 0) {
                const params = varsByUserCurrency(paramsObj);
                return i18n.t(key, params);
            }
            return i18n.t(key);
        },
    };
}

export {
    useParserContent,
};
