import Emitter from "tiny-emitter";

const emitter = new Emitter.TinyEmitter();

type CallbackHandler<T> = ((args?: T) => void) | ((...args: T[]) => void)

export interface EventBus {
    $on<T = unknown>(event: string, callback: CallbackHandler<T>): void
    $off<T = unknown>(event: string, callback: CallbackHandler<T>): void
    $emit(event: string, ...args: unknown[]): void
    $once(event: string, callback: (...args: unknown[]) => void): void
}

const bus: EventBus = {
    $on(event, callback) {
        emitter.on(event, callback);
    },
    $off(event, callback) {
        emitter.off(event, callback);
    },
    $emit(event, ...args) {
        emitter.emit(event, ...args);
    },
    $once(event, callback) {
        const onceCallback = (...args: unknown[]) => {
            callback(...args);
            emitter.off(event, onceCallback);
        };
        emitter.on(event, onceCallback);
    },
};

export default bus;
