enum QuestType {
    ACTIVE = "active",
    ACCESS = "access",
    SOON = "soon",
    DONE = "done",
    FALSY = "falsy",
}

const questsTypes: Record<QuestType, {
    index: number;
    name: string;
    label: string;
}> = {
    [QuestType.ACTIVE]: {
        index: 0,
        name: QuestType.ACTIVE,
        label: "QUESTS.LABEL_ACTIVE",
    },
    [QuestType.ACCESS]: {
        index: 1,
        name: QuestType.ACCESS,
        label: "QUESTS.LABEL_ACCESS",
    },
    [QuestType.SOON]: {
        index: 2,
        name: QuestType.SOON,
        label: "QUESTS.LABEL_SOON",
    },
    [QuestType.DONE]: {
        index: 3,
        name: QuestType.DONE,
        label: "QUESTS.LABEL_DONE",
    },
    [QuestType.FALSY]: {
        index: 4,
        name: QuestType.FALSY,
        label: "QUESTS.LABEL_FALSY",
    },
};

export {
    QuestType,
    questsTypes,
};
