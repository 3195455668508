<script setup lang="ts">
import type { IAchievementResource } from "@api";
import { LabelSize } from "@modules/Quests/components/LabelSize";
import { useQuestData } from "../composables/useQuests";
import FeButton from "@ui-kit/FeButton/index.vue";
import * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeProgressBar from "@ui-kit/FeProgressBar/FeProgressBar.vue";
import BaseModal from "@components/BaseModal.vue";
import QuestsTimer from "./QuestsTimer.vue";
import QuestsLabel from "./QuestsLabel.vue";

interface IProps {
    quest: IAchievementResource;
}
const props = defineProps<IProps>();

const {
    questType,
    questLevel,
    questTitle,
    labelText,
    soonQuest,
    progressText,
    progressBar,
    questSubDescription,
    questButtonName,
    showButton,
    timerData,
    rewardJoined,
    routeTo,
} = useQuestData(props.quest);
</script>

<template>
    <BaseModal class="quests-card-modal" :title="$t('QUESTS.TITLE_2')">
        <div class="quests-card-modal__top">
            <QuestsTimer
                v-if="timerData.time"
                :data="timerData"
                :size="LabelSize.medium"
            />
            <QuestsLabel
                class="quests-card-modal__label"
                :type="questType"
                :size="LabelSize.medium"
            >
                {{ labelText }}
            </QuestsLabel>
        </div>

        <div class="quests-card-modal__content gap-s">
            <div class="quests-card-modal__wrapper-image">
                <div class="quests-card-modal__image">
                    <FeImage v-if="quest.image" :src="quest.image" alt="quest level" />
                    <div v-if="quest.level" class="quests-card-modal__quest-level">
                        {{ questLevel }}
                    </div>
                </div>
            </div>
            <div class="quests-card-modal__text text--align-center">
                <div class="h3">
                    {{ questTitle }}
                </div>
            </div>
        </div>
        <div class="quests-card-modal__target gap-xs">
            <div v-if="quest.rewards.length" class="quests-card-modal__rewards">
                <span class="caption-1 color-text-body">
                    {{ $t("QUESTS.REWARD") }}:
                </span>
                <div class="quests-card-modal__rewards-items caption-2-bold">
                    {{ rewardJoined }}
                </div>
            </div>
            <FeProgressBar
                v-if="progressText"
                class="text--align-center"
                :progress="progressBar"
                big
            >
                <template #inside>
                    <span> {{ $t("QUESTS.PROGRESS_BAR_TEXT") }} {{ progressText }}</span>
                </template>
            </FeProgressBar>
        </div>

        <div
            v-if="questSubDescription"
            class="caption-2 color-text-body text--align-center"
            v-html="questSubDescription"
        />
        <FeButton
            v-if="showButton"
            data-test="quest__action--go-to"
            full-width
            :theme="FeButtonConfig.themes.PrimarySmall"
            :disabled="soonQuest"
            @click="routeTo"
        >
            {{ questButtonName }}
        </FeButton>
    </BaseModal>
</template>

<style lang="scss" scoped>
@import "~@theme/styles/index";
.quests-card-modal {
    display: grid;
    gap: 1rem;

    &__top {
        display: flex;
    }
    &__label {
        margin-left: auto;
    }
    &__image {
        width: 6rem;
        height: 6rem;
        position: relative;
        margin: 0 auto;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__quest-level {
        @include pin;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: $border-radius-M;
        line-height: initial;
        height: initial;
        padding: 0 0.25rem;
        min-height: 1rem;
    }
    &__rewards-items {
        color: $color-secondary-5;
        margin-left: auto;
    }
    &__rewards {
        display: flex;
    }
}
</style>
