<script setup lang="ts">
import { LabelSize } from "./LabelSize";
import { QuestType } from "../config/config";
import { computed } from "vue";
import Timer from "@components/Timer/Timer.vue";

interface IProps {
    data: {
        time: Date | number | null;
        statusText: string;
        status: QuestType;
    };
    size?: LabelSize;
}
const props = withDefaults(defineProps<IProps>(), {
    size: LabelSize.small,
});
const statusClasses = computed(() => [ props.data.status ]);
const classes = computed(() => [ `quests-timer--${props.size}` ]);
</script>

<template>
    <div v-if="data.time || data.status === QuestType.DONE" class="quests-timer color-text-alt" :class="classes">
        <span class="quests-timer__text color-text-body" :class="statusClasses">
            {{ data.statusText }}
        </span>
        <Timer
            v-if="data.status !== QuestType.DONE"
            class="quests-timer__timer"
            :end="data.time"
            :text="[ $t('COMMON.DAY'), ' ', ' ', ' ' ]"
            dots
        />
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";

.quests-timer {
    display: flex;
    align-items: center;
    border-radius: $border-radius-M;
    border: 1px solid $color-tertiary-2;
    gap: 0.25rem;

    &--sm {
        padding: 0 0.25rem;
        @include font-size--SmallCaption;
    }
    &--md {
        padding: 0.25rem 0.5rem;
        @include font-size--Caption2($font-weight--normal);
    }

    &__timer {
        font-weight: $font-weight--bold;

        &:deep(.day) {

            &.timer__dots {
                display: none;
            }
            .timer__desc {
                margin: 0 0.125rem;
            }
        }
        &:deep(.second) {
            .timer__count {
                width: 1rem;
            }
        }
    }

    &__text {
        display: flex;
        align-items: center;

        &.active,
        &.access,
        &.done {
            @include red-dot;
        }
        &.soon {
            @include green-dot;
        }
        .quests-timer & {
            &:before {
                margin-right: 0.25rem;
            }
        }

    }

    &-text {
        padding-right: 0.25rem;
    }

}

</style>
