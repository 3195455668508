/* eslint-disable */
import log from "./LoggerController";

/**
 *
 * `This controller for work with Google analytics`
 *
 * `Example`
 *  ABTestController.load({
 *      pobeda: "NxTP264gS8akM34gmi5bjQ"
 *  });
 *
 *  ABTestController.load("pobeda").then((variation) => {
 *      this.chooseViewRegistration(variation);
 *  });
 *
 * `or`
 *
 * ABTestController.load({
 *      pobeda: "NxTP264gS8akM34gmi5bjQ"
 * }).then((variation) => {
 *      this.chooseViewRegistration(variation);
 * });
 *
 *
 */

function loadABExp(experimentId) {
    return new Promise(((resolve, reject) => {
        // TODO реазиловать експеременты на основе GTM
        resolve({ value: 0, name: "" });
    }));
}

class ABTestController {
    get DEFAULT_VARIATION() {
        return "0";
    }

    get AAB_VARIANTS() {
        return {
            VARIANT_FIRST_A: "0",
            VARIANT_SECOND_A: "1",
            VARIANT_B: "2",
        };
    }

    get AB_VARIANTS() {
        return {
            VARIANT_A: "0",
            VARIANT_B: "1",
        };
    }

    constructor() {
        this.gaexps = {};
    }

    addGaExp(name, value) {
        this.gaexps[name] = value >= 0 ? value : this.DEFAULT_VARIATION;
    }

    loadAB(experimentId, ms = 8000) {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error("Request Timeout"));
            }, ms);
            loadABExp(experimentId).then(({ name, value }) => {
                clearTimeout(timeout);
                resolve(value);
            }).catch(() => {
                reject("Request Error");
            });
        });
    }

    hasExp(name) {
        return this.gaexps.hasOwnProperty(name);
    }

    formatGaExp(gaexpData) {
        let name = null,
            gaexp = null;
        if (typeof gaexpData === "object") {
            name = gaexpData.name;
            gaexp = gaexpData.key;
        } else {
            // TODO: Remove not object gaexpData variation using
            name = gaexpData;
            gaexp = this.gaexps[name];
        }

        if (!gaexp) {
            log.error(
                "AB_TEST_EXPERIMENT_NOT_FOUND",
                new Error("Experiment not found"),
            );
        }

        return { name, gaexp };
    }

    load(gaexpData, ms) {
        const { name, gaexp } = this.formatGaExp(gaexpData);
        if (!this.hasExp(name)) {
            return new Promise((resolve) => {
                this.loadAB(gaexp, ms).then((variant) => {
                    this.addGaExp(name, variant);
                    resolve(this.selectContext(name));
                }).catch(() => {
                    this.addGaExp(name, this.DEFAULT_VARIATION);
                    resolve(this.DEFAULT_VARIATION);
                });
            });
        }
        return Promise.resolve(this.selectContext(name));
    }

    selectContext(name) {
        return this.hasExp(name) ? this.gaexps[name] : this.DEFAULT_VARIATION;
    }
}

export default new ABTestController();
