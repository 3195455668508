// return timezone offset in specific format, for ex. "1000" its UTC+10:00 or "-230" its UTC-2:30
export function getFreshchatUserTimezone() {
    let offset = new Date().getTimezoneOffset();
    const offsetSign = Math.sign(offset) === 1 ? "-" : "";
    offset = Math.abs(offset);
    const hours = Math.floor(offset / 60);
    const minutes = String(offset % 60).padStart(2, "0");

    return `${offsetSign}${hours}${minutes}`;
}
