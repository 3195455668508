import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { ICurrencyRatesResource } from "@api/resources/cashboxResources";
import cashboxAPI from "@api/requests/cashbox";

export const useRatesStore = defineStore("rates", () => {
    const ratesData = ref<ICurrencyRatesResource>({
        date: "",
        rates: {},
        base: "USD",
    });

    const setRatesData = (payload: ICurrencyRatesResource) => {
        ratesData.value = Object.assign({}, ratesData.value, payload);
    };
    const loadRatesData = async () => {
        const data = await cashboxAPI.loadCurrencyRates();

        if (data) {
            setRatesData(data);
        }

        return data;
    };

    return {
        ratesData,
        rates: computed(() => ratesData.value.rates),
        loadRatesData,
    };
});
