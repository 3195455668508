<script setup lang="ts">
import type { IAchievementResource } from "@api";
import Timer from "@components/Timer/Timer.vue";
import { useQuestData } from "../composables/useQuests";
import FeButton from "@ui-kit/FeButton/index.vue";
import * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import FeProgressBar from "@ui-kit/FeProgressBar/FeProgressBar.vue";

interface IProps {
    quest: IAchievementResource;
}
const props = defineProps<IProps>();

const {
    questLevel,
    questTitle,
    labelText,
    soonQuest,
    timerText,
    progressText,
    progressBar,
    timerTime,
    questSubTitle,
    questDescription,
    questSubDescription,
    questButtonName,
    showButton,
    questClasses,
    rewardsTitle,
    getImgSrc,
    routeTo,
} = useQuestData(props.quest);
</script>

<template>
    <div class="quest-modal">
        <div class="quest-modal__title">
            {{ $t("QUESTS.TITLE") }}
        </div>
        <div class="quest-modal__inner full-view">
            <div class="quest__label" :class="questClasses">
                {{ labelText }}
            </div>
            <div v-if="timerTime" class="quest__timer">
                <div class="quest__timer-text">
                    {{ timerText }}
                </div>
                <Timer :end="timerTime" :text="[ $t('COMMON.DAY'), ' ', ' ', ' ' ]" dots />
            </div>
            <div class="quest__wrapper-image">
                <div class="quest__image">
                    <FeImage v-if="quest.image" :src="quest.image" alt="quest level" />
                    <div v-if="quest.level" class="quest__achive-level">
                        {{ questLevel }}
                    </div>
                </div>
            </div>

            <h3 class="quest__title">
                {{ questTitle }}
            </h3>
            <div v-if="quest.subTitle" class="quest__sub-title">
                {{ questSubTitle }}
            </div>
            <div class="quest__target">
                <div v-if="quest.rewards.length" class="quest__rewards">
                    <span class="quest__rewards-title">{{ $t("QUESTS.REWARD") }}:</span>
                    <span v-for="(item, index) in quest.rewards" :key="index" class="quest__rewards-item">
                        <FeImage v-if="item.type" :src="getImgSrc(item.type)" :alt="rewardsTitle(item)" />
                        {{ rewardsTitle(item) }}
                    </span>
                </div>
                <FeProgressBar
                    v-if="progressText"
                    class="quest__progress"
                    :progress="progressBar"
                    big
                >
                    <template #inside>
                        {{ progressText }}
                    </template>
                </FeProgressBar>
            </div>

            <div v-if="quest.description" class="quest__description" v-html="questDescription" />
            <FeButton
                v-if="showButton"
                class="quest__button"
                data-test="quest__action--go-to"
                full-width
                :theme="FeButtonConfig.themes.PrimarySmall"
                :disabled="soonQuest"
                @click="routeTo"
            >
                {{ questButtonName }}
            </FeButton>
            <div v-if="quest.subDescription" class="quest__description" v-html="questSubDescription" />
        </div>
    </div>
</template>

<style lang="scss" src="./style.scss"></style>
