import type { IAchievementResource, IAchievementRewards } from "@api";
import bus from "@bus";
import { TYPES as GiftTypes } from "@config/gift";
import { MODAL_QUEST } from "@config/modalNames";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import { useParserContent } from "@helpers/ParserContent";
import { useModalsStore } from "@store/modals";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { questsTypes, QuestType } from "../config/config";
import bonusIcon from "../images/bonus.png";
import coinIcon from "../images/coin.png";
import giftIcon from "../images/gift.png";
import medalIcon from "../images/medal.png";

export function useQuestData(quest: IAchievementResource) {
    const router = useRouter();
    const { parseContent } = useParserContent();
    const i18n = useI18n();
    const { closeModal } = useModalsStore();

    const localisationVars = computed(() => quest?.localisation?.variables || {});
    const questType = computed(() => quest.typeLabel || questsTypes.access.name);
    const questLevel = computed<number>(() => {
        const level = quest.level;
        if (level) {
            return level.current < level.max ? level.current + 1 : level.max;
        }

        return 1;
    });

    const questTitle = computed(() => parseContent(quest.title, localisationVars.value));
    const labelText = computed(() => i18n.t(questsTypes[questType.value].label));
    const soonQuest = computed(() => questType.value === questsTypes.soon.name);
    const isQuestFinished = computed(() => {
        return questType.value === QuestType.FALSY || questType.value === QuestType.DONE;
    });
    const timerText = computed(() => {
        if (getFeatureFlags().enabledQuestsNew) {
            if (quest.typeLabel === QuestType.SOON) {
                return i18n.t("QUESTS.BEFORE_START_2");
            }
            if (quest.typeLabel === QuestType.ACTIVE || quest.typeLabel === QuestType.ACCESS) {
                return i18n.t("QUESTS.BEFORE_END_2");
            }
            return i18n.t("QUESTS.FINISHED");
        }
        return soonQuest.value ? i18n.t("QUESTS.BEFORE_START") : i18n.t("QUESTS.BEFORE_END");
    });

    const progressText = computed<string>(() => {
        const progress = quest.progress;
        if (progress) {
            const currentProgress = Math.floor(progress.current * progress.multiplier);
            const maxProgress = Math.ceil(progress.max * progress.multiplier);

            const text = `${currentProgress} ${progress.unit || ""} /
                    ${maxProgress} ${progress.unitMax || ""}`;

            return parseContent(text, localisationVars.value);
        }

        return "";
    });
    const progressBar = computed<number>(() => {
        if (quest.progress && quest.progress.current) {
            return Math.floor((quest.progress.current / quest.progress.max) * 100);
        }
        return 0;
    });
    const timerTime = computed<Date | null>(() => {
        if (isQuestFinished.value) {
            return null;
        }

        if (quest.startTime && new Date(quest.startTime).getTime() - Date.now() > 0) {
            return new Date(quest.startTime);
        } else if (quest.endTime && new Date(quest.endTime).getTime() - Date.now() > 0) {
            return new Date(quest.endTime);
        }

        return null;
    });
    const timerData = computed(() => {
        return {
            time: timerTime.value,
            statusText: timerText.value,
            status: quest.typeLabel,
        };
    });
    const questSubTitle = computed(() => parseContent(quest.subTitle, localisationVars.value));
    const questDescription = computed(() => parseContent(quest.description, localisationVars.value));
    const questSubDescription = computed(() => {
        if (quest?.subDescription) {
            return parseContent(quest.subDescription, localisationVars.value);
        }
        return null;
    });
    const questButtonName = computed(() => parseContent(quest.buttonName, localisationVars.value));
    const showButton = computed(() => !isQuestFinished.value && quest.buttonName && quest.buttonLink);

    const questClasses = computed(() => {
        return [ `quest__label--${questType.value}`, !timerTime.value ? "quest__label--no-timer" : "" ];
    });

    const rewardJoined = computed(() => {
        const text: string[] = [];

        quest.rewards.forEach((reward: IAchievementRewards) => {
            text.push(rewardsTitle(reward));
        });

        return text.join(", ");
    });

    const routeTo = () => {
        closeModal(MODAL_QUEST);

        if (quest.buttonLink !== "/cashbox") {
            router.push({ path: `${quest.buttonLink}` });
        } else {
            bus.$emit("open-cashbox", { location: "bonuses" });
        }
    };

    const rewardsTitle = (reward: IAchievementRewards) => {
        const _localisationVars = reward?.localisation?.variables || {};

        return parseContent(reward.title, _localisationVars);
    };

    const getImgSrc = (type: string) => {
        switch (type) {
            case GiftTypes.UniversalGamingType:
            case GiftTypes.FreeSpins:
            case GiftTypes.Spin:
                return bonusIcon;
            case GiftTypes.Cash:
            case GiftTypes.Bonus:
            case GiftTypes.Cashback:
            case GiftTypes.Custom:
            case GiftTypes.CustomGift:
            case GiftTypes.Group:
                return giftIcon;
            case GiftTypes.XP:
                return coinIcon;
            case GiftTypes.ActionCurrency:
            case GiftTypes.ActionMiles:
                return medalIcon;
            default:
                return giftIcon;
        }
    };
    return {
        questType,
        questLevel,
        questTitle,
        labelText,
        soonQuest,
        timerText,
        progressText,
        progressBar,
        timerTime,
        timerData,
        questSubTitle,
        questDescription,
        questSubDescription,
        questButtonName,
        showButton,
        isQuestFinished,
        questClasses,
        rewardJoined,

        rewardsTitle,
        getImgSrc,
        routeTo,
    };
}
