/* eslint-disable */
class Dmp {
    /**
     * Метод инициализации метрики
     * @param url адресс сервиса
     * @param project код проекта
     * @param disabled признак включенности
     * @param router роутер для отправки событий навигации
     * @param userId ID пользователя для персонализации данных
     * @param cmd Внутренний код метода
     */
    init({ url, project, disabled } = {}, router, userId = null, cmd = "0011") {
        this.url = url;
        this.project = project;
        this.enabled = !disabled;
        this.cmd = cmd;
        this.userId = userId;

        this.initRouter(router);
    }

    /**
     * Метод который подписывается на жизненный хук роутера и при каждой навигации отправляет событие в DMP
     * @param router
     */
    initRouter(router) {
        if (this.enabled) {
            router.afterEach((to, from) => {
                this.emit("hit");
            });
        }
    }

    /**
     * Очистка пользовательских данных
     */
    resetUser() {
        this.userId = null;
    }

    /**
     * Метод возвращает басозый форматированый адрес картинки (картинка для обхода CORS)
     * @returns {string}
     */
    get baseUrl() {
        return `${ this.url }?cmd=${ this.cmd }&p=${ this.project }&pc=adsf234`;
    }

    /**
     * Универсильный метод дополнения квери параметров запроса
     * @param name Название операции
     * @param transaction_id Id транзакции пополнения счета
     * @param game_id Id сессии игры
     * @returns {string}
     */
    getUrl({ name, transaction_id, game_id }) {
        let url = this.baseUrl;
        if (name) {
            url = `${url }&e=${ name }`;
        }
        if (this.userId) {
            url = `${url }&uid=${ this.userId }`;
        }
        if (transaction_id) {
            url = `${url }&trid=${ transaction_id }`;
        }
        if (game_id) {
            url = `${url }&eventid=${ game_id }`;
        }

        url = `${url }&t=${ Date.now() }`;

        return url;
    }

    /**
     * Вместо запроса XHR - запрос картинки для обхода CORS
     * @param url
     */
    tickImage(url) {
        const img = document.createElement("img");
        img.src = url;
        img.onload = img.onerror = function() {
            document.body.removeChild(img);
        };
        document.body.appendChild(img);
    }

    /**
     * Единая точка отправки событий
     * @param name
     * @param transaction_id
     * @param game_id
     * @returns {boolean|void}
     */
    baseEmit({ name, transaction_id = null, game_id = null }) {
        if (!this.enabled) {
            return false;
        }
        return this.tickImage(this.getUrl({ name, transaction_id, game_id }));
    }

    /**
     * Метод отправки стандартных событий без доп параметров (reg, auth)
     * @param name
     * @returns {boolean|void}
     */
    emit(name) {
        return this.baseEmit({ name });
    }

    /**
     * Отправка события депозита
     * @param name
     * @param transaction_id Id транзакции
     * @returns {boolean|void}
     */
    emitTransaction(name, transaction_id) {
        return this.baseEmit({ name, transaction_id });
    }

    /**
     * Событие запуска игры
     * @param name
     * @param game_id Id игровой сесии
     * @returns {boolean|void}
     */
    emitGame(name, game_id) {
        return this.baseEmit({ name, game_id });
    }

    /**
     * Установка данных пользователя после логина
     * @param userId
     */
    identify(userId) {
        this.userId = userId;
    }
}

const dmp = new Dmp();

export default dmp;
