import type { IPhoneCodeList, ISeoMetaResource, IServerData, IStaticPageResource, IStaticPagesItemResource } from "@api";
import { jsonApi } from "@api/jsonApi";
import { publicApiV1 } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";

export default {
    async loadServerData() {
        try {
            const { data } = await jsonApi<{
                data: IServerData,
            }>("/serverdata", { method: "GET" });
            return data;
        } catch (error) {
            log.error("LOAD_SERVER_DATA", error);
        }
    },

    async loadSeoMeta(url: string) {
        try {
            const { data } = await jsonApi<{
                data: ISeoMetaResource,
            }>(`/seo/meta?path=${encodeURIComponent(url)}`, {
                method: "GET",
            });
            return data;
        } catch (error) {
            log.error("LOAD_SEO_META", error);
        }
    },

    async loadHreflangList() {
        try {
            const { data } = await publicApiV1<{
                data: { items: Record<string, string> }
            }>({
                url: "/seo/hreflang/list",
                type: () => "Seo.V1.PublicAnon.Hreflang.List",
            });
            return data;
        } catch (error) {
            log.error("LOAD_HREFLANG_LIST", error);
        }
    },
    async sendPromoCode(code: string) {
        try {
            return await jsonApi<{ success: boolean; }>("/promo-codes/activate", {
                data: { promo_code: code },
            });
        } catch (error) {
            log.error("SEND_PROMO_CODE", error);
        }
    },
    async getCurrentStaticPage(slug: string) {
        try {
            const { data } = await jsonApi<{
                data: IStaticPageResource,
            }>(`/static-pages/${ slug }`, { method: "GET" });

            return data;
        } catch (error) {
            log.error("GET_CURRENT_STATIC_PAGE", error);
        }
    },
    async loadStaticPages() {
        try {
            const { data } = await jsonApi<{ data: IStaticPagesItemResource[] }>("/static-pages", {
                method: "GET",
            });

            return data;
        } catch (error) {
            log.error("LOAD_STATIC_PAGES", error);
        }
    },
    async loadCountriesData() {
        try {
            const { data } = await jsonApi<{
                data: IPhoneCodeList,
            }>("/treasury/phone-codes", { method: "GET" });
            return data;
        } catch (error) {
            log.error("LOAD_PHONE_CODE", error);
        }
    },
};
