import type { Component } from "vue";
import { ref, shallowRef } from "vue";
import { defineStore } from "pinia";
import bus from "@bus";
import {
    type IModalAnalytics,
    type ICreateModalAnalytics,
    createModalAnalyticParams,
} from "@modules/ModalController/analytics";

type IModalBase = {
    component: Component;
    name: string;
    props?: Record<string, unknown>;
    showFirst?: boolean;
    callback?(): void;
}
export type IModalNewBase = IModalBase & {
    // TODO: FP-1933 change "new:true|false" to "type:'new'|'old'" it will fix errors in ModalController
    new: true;
    disableBackdropClosing?: boolean;
}
export type IModalOldBase = IModalBase & {
    new?: false;
    mobileFriendly?: boolean;
    alwaysBottom?: boolean;
    fullHeight?: boolean;
    minHeight?: boolean;
}
export type IModalNew = IModalNewBase & {
    analyticsParams?: IModalAnalytics | false;
}
export type IModalOld = IModalOldBase & {
    analyticsParams?: IModalAnalytics | false;
}
export type Modal = IModalOldBase | IModalNewBase;
export type ModalWithAnalytics = IModalOld | IModalNew;
export type ShowModalData = Modal & {
    analyticsParams?: ICreateModalAnalytics;
}

export const useModalsStore = defineStore("modals", () => {
    const modals = ref<ModalWithAnalytics[]>([]);
    const isModalShown = ref(false);

    function showModal(modal: ShowModalData) {
        const modalAlreadyOpen = modals.value.some((item) => {
            return item.name === modal.name;
        });

        if (!modalAlreadyOpen) {
            const analyticsParams = createModalAnalyticParams(modal.name, modal.analyticsParams);

            isModalShown.value = true;
            const _modal: ModalWithAnalytics = { ...modal, analyticsParams, component: shallowRef(modal.component) };
            if (modal.showFirst) {
                modals.value.unshift(_modal);
            } else {
                modals.value.push(_modal);
            }
        }
    }

    function closeModal(name: string, immediate = false) {
        const index = modals.value.findIndex((modal) => {
            return modal.name === name;
        });
        if (index >= 0) {
            modals.value.splice(index, 1);
        }

        if (modals.value.length === 0) {
            if (immediate) {
                isModalShown.value = false;
            } else {
                setTimeout(() => {
                    if (modals.value.length === 0) {
                        isModalShown.value = false;
                    }
                }, 500);
            }
        }
        bus.$emit("modal.closed", name);
    }

    return {
        modals,
        isModalShown,
        showModal,
        closeModal,
    };
});
