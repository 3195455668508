import type { Method } from "./http/types";
import { jsonHttp } from "./http";

export interface IJsonApiParams {
    data?: Record<string, unknown>;
    method?: Method;
}
export function jsonApi<R = any>(url: string, params: IJsonApiParams = {}) {
    const { data } = params;
    const method = params.method || "POST";

    const trimmedUrl = url[0] === "/" ? url.slice(1) : url;
    return jsonHttp<R>(`/json-api/${trimmedUrl}`, {
        method,
        body: data ? JSON.stringify(data) : undefined,
    });
}
