export type Localisation = Record<string, any>;
export enum registrationTypes {
    PHONE = "phone",
    EMAIL = "email",
}

export enum Currency {
    AUD = "AUD",
    NZD = "NZD",
    CAD = "CAD",
    EUR = "EUR",
    USD = "USD",
    INR = "INR",
    BRL = "BRL",
}

export interface ITag {
    id: string;
    name: string;
}
