export enum TransactionType {
    DEPOSIT = "deposit",
    PAYOUT = "payout",
}

export interface ITransactionHistoryItemResource {
    cancelReason: unknown;
    createdAt: number;
    currency: string;
    customerPurse: string;
    feeMessage: string;
    id: string;
    paymentSystem: string;
    status: string;
    statusText: string;
    sum: number;
    sumWithFees: number;
    type: TransactionType;
    updatedAt: number;
}

export interface IBetResource {
    amount: number;
    currency: string;
    date: number;
}

export interface IPaymentMethodResourceLimit {
    currency: string;
    min: number;
    max: number;
}

export interface IPaymentMethodResource {
    id: string;
    fee: number;
    icons: {
        name: string;
        url: string;
    }[];
    isLocked: boolean;
    kycVerificationRequired: boolean;
    limits: IPaymentMethodResourceLimit[],
    name: string;
    originalRouteName: string;
    routeName: string;
    tags: string[];
    treasuryOrderingPosition: number;
}

export interface ILoadPaymentMethodsResource {
    data: IPaymentMethodResource[];
    meta: {
        depositManagerEnabled: boolean;
    };
}

export interface IInitDepositResource {
    operationId?: string;
    paymentSystemOperationId?: string;
    redirectUrl?: string;
    fraud?: boolean;
    message?: string;
    staticClass?: string;
}
export interface IInitPayoutResource {
    redirectUrl: string;
    fraud?: boolean;
    message?: string;
    staticClass?: string;
    verification: {
        status: string;
        verificationStatus: string;
    };
}

export interface IPayoutItemResource {
    amount: number;
    currency: string;
    customer_purse: string;
    date: number;
    id: string;
    payment_system: string;
    reserved_amount: number;
}

export interface ISumRangeResource {
    amount: number;
    currency: string;
}

export interface IGeneralLimitResource {
    currency: string;
    default: number;
    max: number;
    min: number;
}

export interface ICurrencyRatesResource {
    base: string;
    date: string;
    rates: Record<string, number>;
}
