import type {
    IExchangePrizeResource,
    IPromoDataResource,
    IPromoListResource,
    IUserPromoResource,
} from "@api";
import { promoAPI } from "@api";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { useUserInfo } from "@store/userInfo";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const usePromo = defineStore("promo", () => {
    const userInfoStore = useUserInfo();

    const promoList = ref<IPromoListResource[]>([]);

    async function loadPromoList(tag?: string | string[], page: number = 1, perPage: number = 15) {
        const response = await promoAPI.loadPromoList(tag, page, perPage);

        if (response?.data) {
            promoList.value = response.data;
        }
    }

    const activePromoData = ref<IPromoDataResource>();
    const loadActivePromo = promiseMemoizer(async (): Promise<IPromoDataResource> => {
        const data = await promoAPI.loadActivePromo(await userInfoStore.isLoggedAsync());
        activePromoData.value = data;
        return data;
    });

    async function exchangePrize(
        actionId: string,
        prizeId: string,
        giftsNumber: number,
    ): Promise<IExchangePrizeResource> {
        const data = await promoAPI.exchangePrize(
            {
                actionId,
                prizeId,
                giftsNumber,
            },
            await userInfoStore.isLoggedAsync(),
        );

        await loadActivePromo();

        if (currentPromoData.value?.slug) {
            await loadCurrentPromoBySlug(currentPromoData.value.slug);
        }
        return data;
    }

    const currentPromoData = ref<IPromoDataResource>();
    async function loadCurrentPromoBySlug(slug: string): Promise<IPromoDataResource> {
        if (activePromoData.value?.slug === slug) {
            currentPromoData.value = activePromoData.value;
            return currentPromoData.value;
        } else if (currentPromoData.value?.slug === slug) {
            return currentPromoData.value;
        }
        const data = await promoAPI.loadCurrentPromoBySlug(slug, await userInfoStore.isLoggedAsync());
        currentPromoData.value = data;
        return data;
    }

    function cleanCurrentPromo() {
        currentPromoData.value = undefined;
    }
    const userPromo = ref<IUserPromoResource[]>([]);
    async function loadUserPromoData(): Promise<IUserPromoResource[] | void> {
        userPromo.value = await promoAPI.loadUserPromoData();
    }

    function updateUserPromoData(data: IUserPromoResource): void {
        userPromo.value = userPromo.value.map((promo: IUserPromoResource) => {
            return promo.id === data.id ? data : promo;
        });
    }

    function cleanUserPromoData() {
        userPromo.value = [];
    }

    async function participateInPromotions(actionId: string) {
        const data = await promoAPI.participateInPromotions(actionId);

        if (data?.actionId === activePromoData.value?.id) {
            await loadUserPromoData();
            await loadActivePromo();

            if (activePromoData.value?.slug) {
                await loadCurrentPromoBySlug(activePromoData.value.slug);
            }
        }
    }

    function getUserPromoById(id: string): IUserPromoResource | undefined {
        return userPromo.value?.find((promo: IUserPromoResource) => {
            return promo.id === id;
        });
    }

    function getUserMembership(promoId: string): boolean {
        const isCurrentPromo: boolean = currentPromoData.value?.id === promoId;

        if (isCurrentPromo && !currentPromoData.value?.useParticipantBtn) {
            return true;
        }

        const userData = getUserPromoById(promoId);

        return Boolean(userData?.membership);
    }

    const isMembership = computed<boolean>(() => {
        const id = activePromoData.value?.id || currentPromoData.value?.id;

        if (userInfoStore.isLogged && id) {
            return getUserMembership(id);
        }
        return false;
    });

    function isMembershipByPromoId(promoId: string): boolean {
        if (userPromo.value.length > 0) {
            return userPromo.value.some((promo: IUserPromoResource) => {
                return promo.id === promoId;
            });
        }
        return false;
    }

    return {
        promoList,
        loadPromoList,

        activePromoData,
        loadActivePromo,

        currentPromoData,
        loadCurrentPromoBySlug,
        cleanCurrentPromo,
        exchangePrize,

        userPromo,
        loadUserPromoData,
        updateUserPromoData,
        cleanUserPromoData,
        participateInPromotions,

        isMembership,
        isMembershipByPromoId,
    };
});
