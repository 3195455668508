<script setup lang="ts">
import { FeIconConfig } from "@theme/configs/icons";
import config from "@theme/configs/config";

import FeButton from "@ui-kit/FeButton/index.vue";

interface ICarouselNavigationProps {
    backward?: boolean;
    forward?: boolean;
}

withDefaults(defineProps<ICarouselNavigationProps>(), {
    backward: false,
    forward: false,
});
const emit = defineEmits([ "change" ]);

const navigationTheme = config.buttons.GameRow.rowNavigtion;

const pagination = (index: number) => emit("change", index);
</script>

<template>
    <div class="carousel-scroll__navigation">
        <FeButton
            :icon="FeIconConfig.dropdownLeft"
            :theme="navigationTheme"
            aria-label="Previous page"
            :tabindex="backward ? 0 : -1"
            class="carousel-scroll__navigation-button carousel-scroll__navigation-prev"
            :class="{ 'carousel-scroll__navigation--hidden': !backward }"
            @click="pagination(-1)"
        />
        <FeButton
            :icon="FeIconConfig.dropdownRight"
            :theme="navigationTheme"
            aria-label="Next page"
            :tabindex="forward ? 0 : -1"
            class="carousel-scroll__navigation-button carousel-scroll__navigation-next"
            :class="{ 'carousel-scroll__navigation--hidden': !forward }"
            @click="pagination(1)"
        />
    </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>
