import type { IUpdateUserProfileDTO } from "@api";
import { useUserInfo } from "@store/userInfo";
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";

type IServerValidMessages<T = Omit<IUpdateUserProfileDTO, "state" | "dfpc" | "middlename" | "localization">> = {
    [K in keyof T]-?: {
        status: boolean;
        message: string;
    };
};

export const useUserDataService = () => {
    const i18n = useI18n();
    const userInfoStore = useUserInfo();
    const { contactOnVerificationByType, createContact } = userInfoStore;

    const serverValid = reactive<IServerValidMessages>({
        firstname: {
            status: true,
            message: "",
        },
        lastname: {
            status: true,
            message: "",
        },
        email: {
            status: true,
            message: "",
        },
        phone: {
            status: true,
            message: "",
        },
        birthday: {
            status: true,
            message: "",
        },
        region: {
            status: true,
            message: "",
        },
        city: {
            status: true,
            message: "",
        },
        street: {
            status: true,
            message: "",
        },
        zip: {
            status: true,
            message: "",
        },
    });

    const hasPhoneOnVerification = computed(() => contactOnVerificationByType("phone"));
    const hasEmailOnVerification = computed(() => contactOnVerificationByType("email"));
    const phoneOnVerification = computed(() => hasPhoneOnVerification.value?.value);

    const birthdayServerRule = () => {
        return () => {
            return serverValid.birthday.status || serverValid.birthday.message;
        };
    };
    const cityServerRule = () => {
        return () => {
            return serverValid.city.status || serverValid.city.message;
        };
    };
    const emailServerRule = () => {
        return () => {
            return serverValid.email.status || serverValid.email.message || i18n.t("ERRORS.USER_DATA_EMAIL_FATAL");
        };
    };
    const firstNameServerRule = () => {
        return () => {
            return serverValid.firstname.status || serverValid.firstname.message;
        };
    };
    const lastNameServerRule = () => {
        return () => {
            return serverValid.lastname.status || serverValid.lastname.message;
        };
    };
    const regionServerRule = () => {
        return () => {
            return serverValid.region.status;
        };
    };
    const streetServerRule = () => {
        return () => {
            return serverValid.street.status || serverValid.street.message;
        };
    };
    const submitUserPhone = async (phone: string) => {
        const value = phone.replace(/[^0-9]/g, "");

        try {
            const { error } = await createContact(value);

            if (serverValid.phone) {
                if (error) {
                    serverValid.phone.message = error.description;
                    serverValid.phone.status = false;
                    throw error;
                }

                serverValid.phone.status = true;
            }
        } catch (err) {
            if (serverValid.phone) {
                serverValid.phone.status = false;
            }
            return err;
        }
    };
    const phoneServerRule = () => {
        return () => {
            return serverValid.phone.status || serverValid.phone.message || i18n.t("ERRORS.USER_DATA_PHONE_FATAL");
        };
    };

    return {
        isVerifiedEmail: computed(() => userInfoStore.isVerifiedEmail),
        isVerifiedPhone: computed(() => userInfoStore.isVerifiedPhone),
        hasEmailOnVerification,
        hasPhoneOnVerification,
        serverValid,
        phoneOnVerification,
        userCountryCode: computed(() => userInfoStore.userCountryCode),
        userEmail: computed(() => userInfoStore.userEmail),
        userInfo: computed(() => userInfoStore.userInfo),
        userPhone: computed(() => userInfoStore.userPhone),
        birthdayServerRule,
        cityServerRule,
        emailServerRule,
        firstNameServerRule,
        lastNameServerRule,
        regionServerRule,
        streetServerRule,
        submitUserPhone,
        phoneServerRule,
    };
};
