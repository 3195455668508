interface IStringDictionary {
    [key: string]: string;
}
interface IBooleanDictionary {
    [key: string]: boolean;
}
const LOCALES = {
    ENGLISH: "en",
    CANADA_ENGLISH: "en-CA",
    AUSTRALIA_ENGLISH: "en-AU",
    NEW_ZEALAND_ENGLISH: "en-NZ",
    INDIAN_ENGLISH: "en-IN",
    FRENCH: "fr",
    CANADA_FRENCH: "fr-CA",
    PORTUGUESE: "pt",
    BRASILIA_PORTUGUESE: "pt-BR",
    SPANISH: "es",
    GERMAN: "de",
    ITALIAN: "it",
};

const COUNTRIES = {
    CANADA: "CA",
    AUSTRALIA: "AU",
    NEW_ZEALAND: "NZ",
    USA: "US",
    INDIA: "IN",
    ENGLAND: "GB",
    BRASILIA: "BR",
    GERMANY: "DE",
    AUSTRIA: "AT",
    ITALY: "IT",
};

const REGIONS_BY_COUNTRIES = {
    [COUNTRIES.CANADA]: [ "AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT" ],
};

const COOKIE_BY_LOCALE: IStringDictionary = {
    [LOCALES.ENGLISH]: "en",
    [LOCALES.CANADA_ENGLISH]: "en-CA",
    [LOCALES.AUSTRALIA_ENGLISH]: "en-AU",
    [LOCALES.NEW_ZEALAND_ENGLISH]: "en-NZ",
    [LOCALES.INDIAN_ENGLISH]: "en-IN",
    [LOCALES.FRENCH]: "fr",
    [LOCALES.CANADA_FRENCH]: "fr-CA",
    [LOCALES.PORTUGUESE]: "pt",
    [LOCALES.BRASILIA_PORTUGUESE]: "pt-BR",
    [LOCALES.SPANISH]: "es",
    [LOCALES.GERMAN]: "de",
    [LOCALES.ITALIAN]: "it",
};

const COUNTRY_BY_HOST: IStringDictionary = {
    "winspirit.com": COUNTRIES.CANADA,
    "winspirit1.com": COUNTRIES.AUSTRALIA,
    "winspirit-de.com": COUNTRIES.GERMANY,
    "winspirit-it.com": COUNTRIES.ITALY,
    "thor.staging.wlabel.site": COUNTRIES.CANADA,
    "thor-aws.staging.wlabel.site": COUNTRIES.CANADA,
    // for develop
    "thor.development.wlabel.site": COUNTRIES.CANADA,
    "localhost": COUNTRIES.CANADA,
    // for dynamic instances
    "fp-2307-thor.development.wlabel.site": COUNTRIES.CANADA,
};

const COMMON_CACHE_CONFIG: Record<string, string[]> = {
    [COUNTRIES.CANADA]: [
        LOCALES.CANADA_ENGLISH,
        LOCALES.CANADA_FRENCH,
        LOCALES.ENGLISH,
        LOCALES.FRENCH,
    ],
    [COUNTRIES.AUSTRALIA]: [
        LOCALES.AUSTRALIA_ENGLISH,
        LOCALES.ENGLISH,
    ],
    [COUNTRIES.GERMANY]: [
        LOCALES.GERMAN,
        LOCALES.ENGLISH,
    ],
    [COUNTRIES.ITALY]: [
        LOCALES.ITALIAN,
    ],
};

const AVAILABLE_LOCALES_LICENSE_DOMAINS: IBooleanDictionary = {
    [LOCALES.ENGLISH]: true,
    [LOCALES.CANADA_ENGLISH]: true,
    [LOCALES.NEW_ZEALAND_ENGLISH]: true,
    [LOCALES.INDIAN_ENGLISH]: true,
    [LOCALES.FRENCH]: true,
    [LOCALES.CANADA_FRENCH]: true,
    [LOCALES.PORTUGUESE]: true,
    [LOCALES.BRASILIA_PORTUGUESE]: true,
    [LOCALES.SPANISH]: true,
    [LOCALES.GERMAN]: true,
    [LOCALES.ITALIAN]: true,
};

const AVAILABLE_LOCALES: IBooleanDictionary = {
    [LOCALES.ENGLISH]: true,
    [LOCALES.CANADA_ENGLISH]: true,
    [LOCALES.AUSTRALIA_ENGLISH]: true,
    [LOCALES.NEW_ZEALAND_ENGLISH]: true,
    [LOCALES.INDIAN_ENGLISH]: true,
    [LOCALES.FRENCH]: true,
    [LOCALES.CANADA_FRENCH]: true,
    [LOCALES.PORTUGUESE]: true,
    [LOCALES.BRASILIA_PORTUGUESE]: true,
    [LOCALES.SPANISH]: true,
    [LOCALES.GERMAN]: true,
    [LOCALES.ITALIAN]: true,
};

const DEFAULT_LOCALE_BY_COUNTRY: IStringDictionary = {
    default: LOCALES.ENGLISH,
    [COUNTRIES.ENGLAND]: LOCALES.ENGLISH,
    [COUNTRIES.CANADA]: LOCALES.CANADA_ENGLISH,
    [COUNTRIES.AUSTRALIA]: LOCALES.AUSTRALIA_ENGLISH,
    [COUNTRIES.NEW_ZEALAND]: LOCALES.NEW_ZEALAND_ENGLISH,
    [COUNTRIES.INDIA]: LOCALES.INDIAN_ENGLISH,
    [COUNTRIES.USA]: LOCALES.ENGLISH,
    [COUNTRIES.BRASILIA]: LOCALES.BRASILIA_PORTUGUESE,
    [COUNTRIES.GERMANY]: LOCALES.GERMAN,
    [COUNTRIES.ITALY]: LOCALES.ITALIAN,
};

const DEFAULT_COUNTRY = COUNTRIES.ENGLAND;
const DEFAULT_LOCALE = LOCALES.ENGLISH;

const PATH_WITH_LANG_REDIRECT: IBooleanDictionary = {
    "/registration": true,
};

export {
    LOCALES,
    COUNTRIES,
    COOKIE_BY_LOCALE,
    COUNTRY_BY_HOST,
    AVAILABLE_LOCALES,
    AVAILABLE_LOCALES_LICENSE_DOMAINS,
    DEFAULT_LOCALE_BY_COUNTRY,
    DEFAULT_COUNTRY,
    DEFAULT_LOCALE,
    REGIONS_BY_COUNTRIES,
    PATH_WITH_LANG_REDIRECT,
    COMMON_CACHE_CONFIG,
};
