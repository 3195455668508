import type { Localisation } from "@api/resources/commonResources";

export enum PROMOTION_TYPE {
    TOURNAMENT = "TOURNAMENT",
    PROMO = "PROMO",
}

export enum PROMOTION_STATUS {
    PAST = "past",
    ACTIVE = "active",
    FUTURE = "future",
}
export interface IPromotionLabel {
    name: string;
    icon?: string;
}

export interface IPromotionCard {
    id: string;
    slug: string;
    status: string;
    endAt: string;
    startAt: string;
    title: string;
    sortOrder: number;
    image: string;
    pageType: PROMO_MECHANIC | null;
    type: PROMOTION_TYPE;
    localisation?: Localisation;
    access: number[] | Record<string, unknown> | null;
    bgColor?: string;
    prizeFund?: string;
    pageTypeText?: string;
    tags: string[];
    gameTypes: string[];
    labels?: Array<{
        name: string;
        icon?: string;
    }>;
}

export enum PROMO_MECHANIC {
    STATIC = "static",
    EXCHANGE = "exchange",
}

export const PROMO_COINS = 10000;
