import type { ILevelResources, IUserLevelResources } from "@api";
import { levelsAPI } from "@api";
import config from "@theme/configs/config";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLevels = defineStore("levels", () => {
    const levels = ref<ILevelResources[]>([]);

    async function loadLevelsData() {
        const data = await levelsAPI.loadLevelsData();
        levels.value = data.slice(0, config.LEVELS_COUNT);
    }
    function getLevelByIndex(index: number): ILevelResources | undefined {
        return levels.value?.find((el) => {
            return el.index === index;
        });
    }
    function getLevelImageByIndex(index: number): string {
        const item = levels.value?.find((el) => {
            return el.index === index;
        });

        return item?.image || "";
    }
    function cleanLevelData() {
        levels.value = [];
        userLevelData.value = baseUserLevelData();
    }

    function baseUserLevelData(): IUserLevelResources {
        return {
            leftXp: 0,
            level: 1,
            levelName: "",
            levelProgress: 0,
            totalXp: 0,
            xp: 0,
            xpProgress: 0,
        };
    }

    const userLevelData = ref<IUserLevelResources>(baseUserLevelData());
    async function loadUserLevelData() {
        const data = await levelsAPI.loadUserLevelData();

        if (data) {
            userLevelData.value = data;
        }
    }
    function setUserLevelData(data: IUserLevelResources | undefined) {
        const selected = {} as IUserLevelResources;

        if (data?.levelProgress) {
            selected.levelProgress = data.levelProgress;
        }

        if (data?.leftXp) {
            selected.leftXp = data.leftXp;
        }

        if (data?.levelName) {
            selected.levelName = data.levelName;
        }

        if (data?.totalXp) {
            selected.totalXp = data.totalXp;
        }

        if (data?.level) {
            selected.level = data.level;
        }

        if (data?.xpProgress) {
            selected.xpProgress = data.xpProgress;
        }

        userLevelData.value = {
            ...userLevelData.value,
            ...selected,
        };
    }

    return {
        levels,
        loadLevelsData,
        getLevelByIndex,
        getLevelImageByIndex,
        cleanLevelData,

        userLevelData,
        loadUserLevelData,
        setUserLevelData,
    };
});
