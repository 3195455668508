<script setup lang="ts">
import type { IAchievementResource } from "@api";
import { useContextStore } from "@store/context";
import { computed } from "vue";
import { routeNames } from "@router/routeNames";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import QuestsItem from "./QuestsItem.vue";
import QuestsItemEmpty from "./QuestsItemEmpty.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import CarouselScroll from "@ui-kit/CarouselScroll/CarouselScroll.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { isGamePage } from "@controllers/services/games/pageService";
import { type RouteLocationRaw, useRoute } from "vue-router";
import { storeToRefs } from "pinia";

interface IProps {
    quests: IAchievementResource[];
    title?: string;
    disableFadeEffect?: boolean;
    propsRoute?: RouteLocationRaw;
}

interface IEmits {
    (event: "showAll"): void;
}
defineEmits<IEmits>();

const props = defineProps<IProps>();
const route = useRoute();

const { isMobile } = storeToRefs(useContextStore());
const showQuestItemEmpty = computed(() => {
    const minQuests = isMobile.value ? 2 : 8;
    return isGamePage(route) || props.quests.length > minQuests;
});
const toRoute = computed(() => {
    if (props.propsRoute) {
        return props.propsRoute;
    }
    return { name: routeNames.promotionsCasinoQuests };
});
</script>
<template>
    <div class="quests-carusel">
        <div v-if="title" class="quests-carusel__head">
            <div class="quests-carusel__title" data-test="title">
                {{ title }}
            </div>
            <FeLink :to="toRoute" class="quests-carusel__link" @click="$emit('showAll')">
                <FeButton v-if="!isMobile" :theme="FeButtonConfig.themes.TertiaryExtraSmall" data-test="show-all">
                    {{ $t("COMMON.SHOW_ALL") }}
                </FeButton>
                <FeIcon v-else class="quests-carusel__arrow" :icon="FeIconConfig.dropdownRight" />
            </FeLink>
        </div>
        <CarouselScroll :disable-fade-effect="disableFadeEffect">
            <QuestsItem v-for="item in quests" :key="item.id" :quest="item" />
            <QuestsItemEmpty v-if="showQuestItemEmpty" />
        </CarouselScroll>
    </div>
</template>

<style lang="scss">
@import "~@theme/styles/index";
.quests-carusel {
    overflow: hidden;
    --carousel-item-width: 11rem;
    --carousel-gap: 0.5rem;

    @include media(M) {
        --carousel-gap: 1rem;
    }
    &__head {
        display: flex;
        justify-content: space-between;
        color: $color-text-alt;
        margin-bottom: 0.5rem;

        @include media(M) {
            margin-bottom: 1rem;
        }
    }

    &__link {
        text-decoration: none;
    }

    &__title {
        @include font-size--Header2;
    }

    .carousel-scroll__item {
        height: 12rem;
    }
}
</style>
