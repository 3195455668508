import { configAPI, type IClientContextResource } from "@api";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export const useContextStore = defineStore("context", () => {
    const context = ref<IClientContextResource>();
    const pending = ref<boolean>(false);

    const getContext = promiseMemoizer(async () => {
        if (!context.value?.userAgent) {
            pending.value = true;
            context.value = await configAPI.getClientContext();
            pending.value = false;
        }

        return context.value;
    });

    return {
        getContext,
        context,
        pending: computed(() => pending.value),
        isMobile: computed(() => context.value?.isMobile),
        isIOS: computed(() => context.value?.isIOS),
        isMacOS: computed(() => context.value?.isMacOS),
        browser: computed(() => context.value?.browser),
        isBot: computed(() => context.value?.isBot),
    };
});
