import { userAPI, VerificationsStatus, verificationsStatusOld } from "@api";
import type {
    ICreateContactResource,
    IUpdateUserProfileDTO,
    IUserContactsOnVerificationResource,
    IUserFeatureResource,
    IUserProfileResource,
    IUserStatusResource,
    IVipManagerResource,
} from "@api";
import { Currency, registrationTypes } from "@api/resources/commonResources";
import bus from "@bus";
import { USER_REGULAR_STATUS, UserVipStatus } from "@controllers/services/user";
import { promiseMemoizer } from "@helpers/promiseHelpers";
import { DEFAULT_CURRENCY } from "@theme/configs/constsCurrencies";
import { COUNTRIES, DEFAULT_LOCALE } from "@theme/configs/constsLocales";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useMultiLang } from "@store/multilang";

export interface ILoadUserProfileParams {
    reload?: boolean;
    localeRedirectUrl?: string;
}

export const useUserInfo = defineStore("userInfo", () => {
    const { updateLocalUserLocale } = useMultiLang();
    function baseUserInfoData(): IUserProfileResource {
        return {
            id: "",
            user_id: "",
            lastname: null,
            firstname: null,
            middlename: null,
            address: null,
            country: "",
            chosen_country: "",
            status: 0,
            state: null,
            city: null,
            street: null,
            zip: null,
            gender: null,
            registration_contact_type: "",
            birthday: null,
            birthday_verified: false,
            nick_name: null,
            multi_account: false,
            support_manager_id: null,
            localization: DEFAULT_LOCALE,
            hash: "b1927c79f364c5bcaebecb38aeabaae155ffa215",
            phones: [
                {
                    phone: "",
                    is_primary: true,
                    is_verified: false,
                },
            ],
            emails: [
                {
                    email: "",
                    is_primary: true,
                    is_verified: false,
                },
            ],
            verification: {
                isVerified: false,
                isAntiFraudVerified: false,
                status: verificationsStatusOld.INITIAL,
                verificationStatus: VerificationsStatus.INITIAL,
            },
            user_type: "",
            vipManager: null,
            selfExclusionStatus: null,
        };
    }

    const userInfo = ref<IUserProfileResource>(baseUserInfoData());
    const userInfoLoaded = ref<boolean>(false);
    const availableFeatures = ref<IUserFeatureResource[]>([]);
    const availableFeaturesLoaded = ref(false);
    const userBettingToken = ref<string>();
    const refcode = ref<{ isLoaded: boolean; types: string[] }>({
        isLoaded: false,
        types: [], //  "Betting", "Gambling" ...
    });
    const contactsOnVerification = ref<IUserContactsOnVerificationResource[]>([]);
    const userStatusData = ref<IUserStatusResource>(baseUserStatusData());
    const isGuest = ref(false);

    const vipManagerData = computed<IVipManagerResource | null>(() => {
        return userInfo.value.vipManager;
    });
    const getUserId = computed(() => {
        return userInfo.value.user_id;
    });
    const isLogged = computed(() => {
        return Boolean(userInfo.value.user_id);
    });
    const userCountryCode = computed(() => {
        return userInfo.value.chosen_country;
    });
    const isUserFromCanada = computed(() => {
        return userCountryCode.value === COUNTRIES.CANADA;
    });
    const isVerifiedEmail = computed(() => {
        const emails = userInfo.value.emails;
        const primaryEmail = emails.find((item) => item.is_primary);

        return primaryEmail ? primaryEmail.is_verified : false;
    });
    const isVerifiedPhone = computed(() => {
        const phones = userInfo.value.phones;
        const primaryPhone = phones.find((item) => item.is_primary);

        return primaryPhone ? primaryPhone.is_verified : false;
    });
    const userPhone = computed(() => {
        const primaryPhone = userInfo.value.phones.find((item) => item.is_primary);
        return primaryPhone?.phone || "";
    });
    const userEmail = computed(() => {
        const primaryEmail = userInfo.value.emails.find((item) => item.is_primary);
        return primaryEmail?.email || "";
    });
    const isPersonalDataSaved = computed(() => {
        let isContactsSaved = false;
        const phoneOnVerification = contactsOnVerification.value.find((item) => {
            return item.type === registrationTypes.PHONE;
        });
        const emailOnVerification = contactsOnVerification.value.find((item) => {
            return item.type === registrationTypes.EMAIL;
        });

        if (userEmail.value) {
            if (phoneOnVerification || userPhone.value) {
                isContactsSaved = true;
            }
        } else if (emailOnVerification) {
            isContactsSaved = true;
        }

        return Boolean(
            isContactsSaved && userInfo.value.firstname && userInfo.value.lastname && userInfo.value.birthday,
        );
    });
    const isAddressDataSaved = computed(() => {
        return Boolean(userInfo.value.city && userInfo.value.state && userInfo.value.street && userInfo.value.zip);
    });
    const isBrazilDataSaved = computed(() => {
        return Boolean(userInfo.value.firstname && userInfo.value.lastname && userPhone.value && userInfo.value.zip);
    });
    const verificationStatusVerified = computed(() => {
        return userInfo.value.verification.status === verificationsStatusOld.VERIFIED;
    });
    const verificationStatusPending = computed(() => {
        return userInfo.value.verification.verificationStatus === VerificationsStatus.PENDING;
    });
    const verificationStatusApproved = computed(() => {
        return userInfo.value.verification.verificationStatus === VerificationsStatus.APPROVED;
    });
    const isUserHasBettingRefcode = computed(() => {
        return refcode.value.types.includes("Betting");
    });
    const isSuspended = computed(() => {
        return Boolean(userInfo.value.isSuspended);
    });

    const loadUserProfile = promiseMemoizer<IUserProfileResource, [ILoadUserProfileParams | void]>(
        async (params) => {
            const {
                reload = false,
                localeRedirectUrl,
            } = params || {};

            if (userInfo.value.id && !reload) {
                return Promise.resolve(userInfo.value);
            }
            userInfoLoaded.value = false;

            const data = await userAPI.loadUserProfile();

            setUserProfile(data);

            if (data?.localization) {
                await updateLocalUserLocale({
                    locale: data.localization,
                    redirectUrl: localeRedirectUrl,
                });
            }
            if (data?.user_id) {
                bus.$emit("user.data.received");
            }

            userInfoLoaded.value = true;
            return data;
        },
    );

    function setUserProfile(payload: IUserProfileResource) {
        userInfo.value = Object.assign({}, userInfo.value, payload);
    }

    async function sendUserData(data: IUpdateUserProfileDTO) {
        const response = await userAPI.updateUserProfile(data);

        if (!response.success) {
            return response.errors;
        }
        await loadUserProfile({ reload: true });
    }

    async function createContact(phone: string) {
        const data = await userAPI.createContact(phone);

        if (data?.data) {
            setUserContact(data.data);
        }
        return data;
    }

    function setUserContact(data: ICreateContactResource) {
        const type = data.type;
        const baseData = {
            is_primary: true,
            is_verified: false,
        };
        if (type === registrationTypes.EMAIL) {
            userInfo.value.emails.push({
                ...baseData,
                email: data.value,
            });
        } else {
            userInfo.value.phones.push({
                ...baseData,
                phone: data.value,
            });
        }
    }

    function setEmailVerify(email: string) {
        userInfo.value.emails = userInfo.value.emails.map((item) => {
            if (item.is_primary) {
                return Object.assign({}, item, { email });
            }

            return item;
        });
    }

    function cleanUserInfo() {
        userInfo.value = baseUserInfoData();
    }

    const isLoggedAsync = promiseMemoizer(async () => {
        if (!isLogged.value) {
            await loadUserProfile();
        }
        return isLogged.value;
    });

    const loadUserFeatures = promiseMemoizer(async () => {
        if (availableFeatures.value.length > 0) {
            return Promise.resolve(availableFeatures.value);
        }

        availableFeaturesLoaded.value = false;
        const data = await userAPI.loadUserFeatures();
        setUserFeatures(data);
        availableFeaturesLoaded.value = true;
        return data;
    });

    function setUserFeatures(data: IUserFeatureResource[]) {
        availableFeatures.value = data;
    }

    function isFeatureAvailable(featureName: string) {
        const feature = availableFeatures.value.find((featureItem) => {
            return featureItem.feature === featureName;
        });
        return feature && feature.isAvailable;
    }

    async function loadUserBettingToken(): Promise<string> {
        const data = await userAPI.loadUserBettingToken();
        userBettingToken.value = data.token;

        return data.token;
    }

    async function loadRefcodeTypes() {
        const types: string[] = await userAPI.loadRefcodeTypes();

        refcode.value.isLoaded = true;
        refcode.value.types = types;
    }

    async function loadContactsOnVerification() {
        const response = await userAPI.loadContactsOnVerification();

        if (response?.data) {
            contactsOnVerification.value = response.data;
        }
    }

    function contactOnVerificationByType(type: string) {
        return contactsOnVerification.value.find((item: IUserContactsOnVerificationResource) => {
            return item.type === type;
        });
    }

    function baseUserStatusData(): IUserStatusResource {
        return {
            userId: null,
            currency: DEFAULT_CURRENCY as Currency,
            currentStatus: "NEW",
            currentStatusInt: 0,
            activeStatus: USER_REGULAR_STATUS,
            nextStatus: UserVipStatus.BRONZE,
            nextStatusInt: 0,
            depositAmountCents: 0,
            depositThresholdCents: 0,
            betSumCents: 0,
            betSumThresholdCents: 0,
            overallProgress: 0,
        };
    }

    const loadUserStatusData = promiseMemoizer(async () => {
        const data = await userAPI.loadUserStatusData();

        if (data) {
            userStatusData.value = data;
        }

        return data;
    });

    function cleanUserStatusData() {
        userStatusData.value = baseUserStatusData();
    }

    return {
        isGuest,
        userInfo,
        userInfoLoaded,
        loadUserProfile,
        sendUserData,
        createContact,
        vipManagerData,
        setEmailVerify,
        cleanUserInfo,

        availableFeatures,
        availableFeaturesLoaded,
        loadUserFeatures,
        setUserFeatures,
        isFeatureAvailable,

        userBettingToken,
        loadUserBettingToken,

        getUserId,
        isLogged,
        isLoggedAsync,
        refcode,
        loadRefcodeTypes,

        contactsOnVerification,
        loadContactsOnVerification,
        contactOnVerificationByType,

        userCountryCode,
        isUserFromCanada,
        isVerifiedEmail,
        isVerifiedPhone,
        userPhone,
        userEmail,
        isSuspended,

        isPersonalDataSaved,
        isAddressDataSaved,
        isBrazilDataSaved,

        verificationStatusVerified,
        verificationStatusPending,
        verificationStatusApproved,

        isUserHasBettingRefcode,

        userStatusData,
        loadUserStatusData,
        cleanUserStatusData,
    };
});
