import type { RouteLocationNormalized } from "vue-router";
import type { ISeoMetaResource } from "@api/resources/serverResources";
import { ref } from "vue";
import { defineStore } from "pinia";
import serverAPI from "@api/requests/server";

export const useSeoMetaStore = defineStore("seoMeta", () => {
    const seoMeta = ref<Record<string, ISeoMetaResource> | null>(null);
    const hreflangList = ref<Record<string, string> | null>(null);
    const noIndex = ref(false);

    const setSeoMeta = ({ meta, url }: { meta?: ISeoMetaResource; url: string }) => {
        if (!meta || !meta.metaTitle) {
            return;
        }

        seoMeta.value = Object.assign({}, seoMeta.value, { [url]: meta });
    };
    const setNoIndex = (value: boolean) => {
        noIndex.value = value;
    };
    const loadSeoMeta = async (route: RouteLocationNormalized): Promise<ISeoMetaResource | void> => {
        let url = route.path;

        if (route.meta && route.meta.metaUrl) {
            url = route.meta.metaUrl as string;
        }

        const meta = seoMeta.value?.[url];
        if (meta) {
            setSeoMeta({ meta, url });
            return Promise.resolve(meta);
        }

        const data = await serverAPI.loadSeoMeta(url);
        setSeoMeta({ meta: data, url });
    };
    const loadHreflangList = async () => {
        const list = hreflangList.value;

        if (list && Object.keys(list).length > 0) {
            return Promise.resolve(list);
        }

        const data = await serverAPI.loadHreflangList();

        // @ts-expect-error incorrect types
        if (data?.items) {
            // @ts-expect-error incorrect types
            hreflangList.value = data.items;
        }
    };

    return {
        seoMeta,
        hreflangList,
        noIndex,
        setSeoMeta,
        setNoIndex,
        loadSeoMeta,
        loadHreflangList,
    };
});
