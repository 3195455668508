import { FRESHCHAT_SCRIPT_SRC } from "./constants";
import type { IfcWidget } from "./types";

declare global {
    const fcWidget: IfcWidget;
}

let isFcWidgetLoaded = false;
// eslint-disable-next-line @typescript-eslint/init-declarations
let loadPromise: Promise<IfcWidget> | undefined;

export function getFreshchatWidget(): Promise<IfcWidget> {
    if (isFcWidgetLoaded) {
        return Promise.resolve(fcWidget);
    }
    if (!loadPromise) {
        loadPromise = new Promise((resolve) => {
            const element = document.createElement("script");
            element.async = true;
            element.src = FRESHCHAT_SCRIPT_SRC;
            element.onload = () => {
                isFcWidgetLoaded = true;
                resolve(fcWidget);
            };
            document.body.appendChild(element);
        });
    }
    return loadPromise;
}
