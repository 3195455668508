<script setup lang="ts">
interface ICarouselPaginationProps {
    length: number;
    activeIndex: number;
}

defineProps<ICarouselPaginationProps>();
const emit = defineEmits([ "change" ]);

const changeActivePage = (index: number) => emit("change", index);
</script>

<template>
    <div class="carousel-scroll__pagination">
        <div class="carousel-scroll__pagination-container" role="tablist">
            <button
                v-for="(_, index) in length"
                :key="index"
                class="carousel-scroll__pagination-dot"
                aria-hidden="false"
                role="tab"
                :value="index"
                :aria-label="String(index)"
                :class="{ 'carousel-scroll__pagination-dot--active': index === activeIndex }"
                @click="changeActivePage(index)"
            />
        </div>
    </div>
</template>

<style scoped lang="scss" src="./style.scss"></style>
