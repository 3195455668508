import { PROMOTION_STATUS, type IPromotionCard, usePromotion } from "@controllers/services/promotions";
import { useChooseTournament } from "@controllers/services/tournaments/chooseTournament";
import { GLOBAL_EVENT_PROMO_SLUG } from "@modules/GlobalEvent/config/slugs";
import { routeNames } from "@router/routeNames";
import { usePromo } from "@store/promo";
import { useUserInfo } from "@store/userInfo";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export function useParticipateButton(promo: IPromotionCard) {
    const { chooseTournament } = useChooseTournament();
    const {
        isMembership,
        isPromoPageTypeStatic,
        isPromoTypePromo,
        isPromoTypeTournament,
        accessText,
    } = usePromotion(promo);

    const i18n = useI18n();
    const router = useRouter();
    const userInfoStore = useUserInfo();
    const promoStore = usePromo();

    const participateButtonText = computed(() => {
        return i18n.t("PROMO.PROMO_ACTION_BUTTON_PARTICIPATE");
    });
    const detailsButtonText = computed(() => {
        if (promo.status === PROMOTION_STATUS.PAST) {
            return i18n.t("PROMO.PROMO_ACTION_BUTTON_RESULTS");
        }

        if (userInfoStore.isLogged && isMembership.value) {
            return i18n.t("PROMO.PROMO_ACTION_BUTTON_PROCEED");
        }

        return i18n.t("PROMO.PROMO_ACTION_BUTTON_DETAILS");
    });

    function routeToPromo() {
        if (isPromoPageTypeStatic.value) {
            return router.push({
                path: `/${promo.slug}`,
            });
        }
        if (isPromoTypeTournament.value) {
            return router.push({
                name: routeNames.tournamentPage,
                params: { slug: promo.slug },
            });
        }
        if (isPromoTypePromo.value) {
            const name = promo.slug === GLOBAL_EVENT_PROMO_SLUG ?
                routeNames.santasGiftShopPromoPage :
                routeNames.promoPage;
            return router.push({
                name,
                params: { slug: promo.slug },
            });
        }

        return router.push({
            path: `/${promo.slug}`,
        });
    }

    async function clickParticipationHandler() {
        if (!userInfoStore.isLogged) {
            return router.push({ name: routeNames.registration });
        }
        if (isPromoTypeTournament.value && !isMembership.value) {
            // @ts-expect-error Argument of type IPromotionCard is not assignable to parameter of type ITournamentResource
            await chooseTournament(promo);
        }
        if (isPromoTypePromo.value && !isMembership.value) {
            await promoStore.participateInPromotions(promo.id);
        }

        return routeToPromo();
    }

    const isShowParticipationButton = computed(() => {
        return (
            !isPromoPageTypeStatic.value &&
            promo.status === PROMOTION_STATUS.ACTIVE &&
            !isMembership.value &&
            !accessText.value
        );
    });

    return {
        detailsButtonText,
        participateButtonText,
        routeToPromo,
        clickParticipationHandler,
        isShowParticipationButton,
    };
}
