const STATUS = {
    RECEIVED: "received",
    ACTIVATED: "activated",
    EXPIRED: "expired",
    USED: "used",
    ERASED_BY_WITHDRAW: "erased_by_withdraw",
    WAIT_ACTIVATION: "wait_activation",
};

const ALLOWED_BET_TYPE = [
    "GIFT.GIFT_BET_TYPE_EXPRESS_SINGLE",
    "GIFT.GIFT_BET_TYPE_SINGLE",
    "GIFT.GIFT_BET_TYPE_EXPRESS",
];

const TYPES = {
    // SIMPLE
    Bonus: "Deposit bonus",
    FreeSpins: "NonDeposit",
    Group: "Group",
    UniversalGamingType: "Universal gaming",
    Cashback: "Cashback",
    Spin: "Giftspin",
    XP: "XP",
    Cash: "Cash",
    CustomGift: "Custom Gift",
    Manual: "Manual", // У нас такого нет

    // ACTIONS
    ActionTournaments: "Action tournaments",
    ActionCurrencyLevel: "Action currency with level",
    ActionCurrency: "Action currency",
    ActionMiles: "Action miles",

    // RUNNER SPECIFIC
    // #BOOONGO
    BooongoFeature: "Booongo Feature Freespins",
    BooongoFreebet: "Booongo Freebet",
    // #PLAYSON
    PlaysonFeature: "Playson Feature Trigger",
    // #SOFTGAMING
    SoftGaming: "SoftGaming Gift",

    // BETTING
    Betting: "Betting",

    // ХЗ шо
    Custom: "Custom",
    // Subtype of UniversalGamingType
    UniversalGaming: {
        giftSpin: "gs",
        featureTrigger: "ft",
    },
};

const giftUnselected = {
    id: "",
    title: "no_bonus",
    type: "Unselected",
};

export {
    STATUS,
    ALLOWED_BET_TYPE,
    TYPES,
    giftUnselected,
};
