import type { IGameObserveDTO } from "@api";
import { jsonHttp } from "@api/http";
import log from "@front/core/controllers/LoggerController";

export default {
    async gameStartObserve(data: IGameObserveDTO) {
        try {
            await jsonHttp("/fe-api/game-start-performance", {
                method: "PUT",
                body: JSON.stringify(data),
            });
        } catch (error) {
            log.error("GAME_START_OBSERVE", error);
        }
    },
};
