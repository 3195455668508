import {
    PROMO_MECHANIC,
    PROMOTION_STATUS,
    PROMOTION_TYPE,
    type IPromotionCard,
    type IPromotionLabel,
} from "@controllers/services/promotions/types";
import { useParserContent } from "@helpers/ParserContent";
import type { PromoItem } from "@store/controllers/mainPageStore";
import { useUserBalance } from "@store/userBalance";
import config from "@theme/configs/config";
import { useI18n } from "vue-i18n";

export function usePromotionToCard() {
    const userBalanceStore = useUserBalance();
    const i18n = useI18n();

    const { parseContent } = useParserContent();

    function computedPrizeFund(promo: PromoItem) {
        if (promo?.fund) {
            return parseContent(promo.fund, promo?.localisation?.variables);
        }

        // @ts-expect-error Property prizeFund does not exist on type PromoItem
        if (promo?.prizeFund) {
            // @ts-expect-error Property prizeFund does not exist on type PromoItem
            return parseContent(promo?.prizeFund, promo?.localisation?.variables);
        }
        // @ts-expect-error Property prize does not exist on type PromoItem
        return promo?.prize ? promo.prize[userBalanceStore.userCurrency] : undefined;
    }

    function promoTypeText(promo: PromoItem) {
        if (promo.type === PROMOTION_TYPE.PROMO) {
            if (promo.pageType === PROMO_MECHANIC.STATIC) {
                return i18n.t("PROMOTION_CARD.PROMO_TYPE_TEXT_STATIC_EVENT");
            }
            return i18n.t("PROMOTION_CARD.PROMO_TYPE_TEXT_EVENT");
        }
        if (promo.type === PROMOTION_TYPE.TOURNAMENT) {
            if (promo.pageType === PROMO_MECHANIC.STATIC) {
                return i18n.t("PROMOTION_CARD.PROMO_TYPE_TEXT_STATIC_TOURNAMENT");
            }
            return i18n.t("PROMOTION_CARD.PROMO_TYPE_TEXT_TOURNAMENT");
        }
        return undefined;
    }

    function promotionsBadges(promo: PromoItem) {
        const labels: IPromotionLabel[] = [];

        if (promo.status === PROMOTION_STATUS.FUTURE) {
            labels.push({
                name: i18n.t("PROMOTION_CARD.STATUS_LABEL_FUTURE"),
            });
        }
        // @ts-expect-error Property victoryType does not exist on type PromoItem
        if (promo?.victoryType && promo.pageType !== PROMO_MECHANIC.STATIC) {
            labels.push({
                // @ts-expect-error Property victoryType does not exist on type PromoItem
                name: i18n.t(`TOURNAMENTS.${promo.victoryType}`),
            });
        }
        // @ts-expect-error Property customBadge does not exist on type PromoItem
        if (promo?.customBadge) {
            // @ts-expect-error Property customBadge does not exist on type PromoItem
            labels.push({ name: promo.customBadge });
        }
        return labels;
    }

    function convertToCard(promo: PromoItem): IPromotionCard {
        return {
            id: promo.id,
            slug: promo.slug,
            status: promo.status,
            endAt: promo.endAt,
            startAt: promo.startAt,
            title: parseContent(promo.title, promo.localisation.variables),
            image: promo.image,
            pageType: promo?.pageType || null,
            bgColor: promo?.bgColor || config.defaultGradient,
            sortOrder: promo.sortOrder,
            type: promo.type,
            prizeFund: computedPrizeFund(promo),
            pageTypeText: promoTypeText(promo),
            labels: promotionsBadges(promo),
            // @ts-expect-error Type undefined is not assignable to type number[] | Record<string, unknown> | null
            access: promo?.access,
            localisation: promo.localisation,
            tags: promo?.tags || [],
            // @ts-expect-error Property gameTypes does not exist on type PromoItem
            gameTypes: promo?.gameTypes || [],
        };
    }
    return {
        convertToCard,
    };
}
