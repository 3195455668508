<script setup lang="ts">
import { computed } from "vue";
import { routeNames } from "@router/routeNames";
import { iconsOutline } from "@theme/configs/iconsOutline";
import FeButton from "@ui-kit/FeButton/index.vue";
import * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { type RouteLocationRaw } from "vue-router";

interface IProps {
    propsRoute?: RouteLocationRaw;
}

defineEmits([ "showAll" ]);

const props = defineProps<IProps>();

const toRoute = computed(() => {
    if (props.propsRoute) {
        return props.propsRoute;
    }
    return { name: routeNames.promotionsCasinoQuests };
});
</script>

<template>
    <div class="quest-item quest-item__empty">
        <FeIcon class="quest-item__empty-img" :icon="iconsOutline.quests" />
        <FeLink :to="toRoute" class="quest-item__empty-link" @click="$emit('showAll')">
            <FeButton :theme="FeButtonConfig.themes.TertiaryExtraSmall">
                {{ $t("QUESTS.SHOW_ALL_QUESTS") }}
            </FeButton>
        </FeLink>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";
.quest-item__empty {
    align-items: center;
    justify-content: center;
    height: 100%;

    &-img {
        width: 5rem;
        height: 5rem;

        svg {
            width: 100%;
            height: auto;
        }

        @include media(M) {
            width: 6rem;
            height: 6rem;
        }
    }

    &-link {
        text-decoration: none;

        .fe-button {
            padding: 0.5rem;

            @include media(M) {
                padding: 0.5rem 0.75rem;
            }
        }
    }
}
</style>
