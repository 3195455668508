import { type MaybeRefOrGetter, onBeforeUnmount, onMounted, toValue } from "vue";
import { gtag } from "@plugins/gtagPlugin";
import type { ICreateModalAnalytics, IModalAnalytics } from "./types";

export function useModalAnalytics(params?: MaybeRefOrGetter<IModalAnalytics | false>) {
    function sendModalOpenEvent() {
        const paramsValue = params ? toValue(params) : false;
        if (!paramsValue || paramsValue.disableOpenEvent === true) {
            return;
        }

        const { additionalState, name } = paramsValue;
        gtag("event", "popup_open", {
            ...additionalState,
            popup_name: name,
        });
    }

    function sendModalCloseEvent() {
        const paramsValue = params ? toValue(params) : false;
        if (!paramsValue || paramsValue.disableCloseEvent === true) {
            return;
        }

        const { additionalState, name } = paramsValue;
        gtag("event", "popup_close", {
            ...additionalState,
            popup_name: name,
        });
    }

    onMounted(() => {
        sendModalOpenEvent();
    });
    onBeforeUnmount(() => {
        sendModalCloseEvent();
    });
}

export function createModalAnalyticParams(popupName: string, params?: ICreateModalAnalytics): IModalAnalytics | false {
    if (params === false) {
        return false;
    }

    const name = params?.name || popupName;

    return {
        ...params,
        name,
    };
}
