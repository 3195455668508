<script setup lang="ts">
import type { IAchievementResource } from "@api";
import { computed } from "vue";
import { type RouteLocationRaw } from "vue-router";

import QuestsCarouselOld from "./QuestsCarouselOld.vue";
import QuestsCardCarousel from "./QuestsCardCarousel.vue";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";

interface IProps {
    quests: IAchievementResource[];
    title?: string;
    disableFadeEffect?: boolean;
    propsRoute?: RouteLocationRaw;
    showEmptyCard?: boolean;
}
const props = defineProps<IProps>();

const component = computed(() => {
    if (getFeatureFlags().enabledQuestsNew) {
        return QuestsCardCarousel;
    }
    return QuestsCarouselOld;
});

</script>
<template>
    <component :is="component" v-bind="props" />
</template>


