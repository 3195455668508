import type { ITournamentListResource, ITournamentResource, IUserTournamentListResource } from "@api";
import { tournamentsAPI } from "@api";
import bus from "@bus";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useTournaments = defineStore("tournaments", () => {
    const tournamentList = ref<ITournamentListResource[]>([]);
    async function loadTournamentList(tag: string | string[] = [], page: number = 1, perPage: number = 15) {
        const response = await tournamentsAPI.loadTournamentList(tag, page, perPage);

        if (response?.data) {
            tournamentList.value = response.data;
        }
    }
    const currentTournament = ref<ITournamentResource>();
    async function loadTournament(slug: string, reload = false): Promise<ITournamentResource | undefined> {
        if (currentTournament.value?.slug === slug && !reload) {
            return Promise.resolve(currentTournament.value);
        }

        currentTournament.value = undefined;

        const data = await tournamentsAPI.loadTournament(slug);

        currentTournament.value = data;
        return data;
    }
    function reloadTournament() {
        if (!currentTournament.value?.slug) {
            return;
        }

        loadTournament(currentTournament.value.slug, true);
    }

    bus.$on("user.login", reloadTournament);
    bus.$on("user.logout", reloadTournament);

    const userTournamentList = ref<IUserTournamentListResource[]>([]);
    async function loadUserTournamentList(): Promise<IUserTournamentListResource[] | void> {
        const data = await tournamentsAPI.loadUserTournamentList();
        if (data) {
            userTournamentList.value = data;
            return data;
        }
    }

    function cleanUserTournamentList() {
        userTournamentList.value = [];
    }

    function isMembershipByTournamentId(id: string): boolean {
        if (userTournamentList.value.length > 0) {
            return userTournamentList.value.some((tournament: IUserTournamentListResource) => {
                return tournament.id === id;
            });
        }
        return false;
    }

    return {
        tournamentList,
        loadTournamentList,
        currentTournament,
        loadTournament,
        reloadTournament,

        userTournamentList,
        loadUserTournamentList,
        cleanUserTournamentList,

        isMembershipByTournamentId,
    };
});
