<template>
    <FeToolbar class="toolbar-steps">
        <template #left>
            <FeIcon
                v-if="options.toPrev || options.close"
                :icon="FeIconConfig.arrowBack"
                @click="toPrev"
            />
        </template>

        <div
            class="toolbar-steps__info"
            :class="[ {'toolbar-steps__info--one-column': options.hideProgress} ]"
        >
            <h5>{{ options.titles.title }}</h5>
            <div class="toolbar-steps__subtitle caption-2">
                {{ options.titles.description }}
            </div>
            <ProgressBarLine
                v-if="!options.hideProgress"
                class="toolbar-steps__progress"
                :current-value="Number(options.currentStep)"
                :max-value="lastStep"
                :size="lastStep"
            />
        </div>
    </FeToolbar>
</template>

<script lang="ts" setup>
import FeToolbar from "@ui-kit/FeToolbar/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import ProgressBarLine from "@components/ProgressBarLine/index.vue";
import { computed } from "vue";

const DEFAULT_LAST_STEP = 3;

export interface IToolBarSteps {
    currentStep?: number | string;
    lastStep?: number;
    hideProgress?: boolean;
    hidePrev?: boolean;
    toPrev?: (step: number) => void;
    close?: () => void;
    titles: {
        title: string;
        description?: string;
    };
}

interface IProps {
    options: IToolBarSteps;
}

defineOptions({
    name: "ToolbarSteps",
});

const props = defineProps<IProps>();

const lastStep = computed(() => props.options.lastStep || DEFAULT_LAST_STEP);

function toPrev() {
    if (typeof props.options.currentStep === "number" && props.options.currentStep > 1) {
        if (props.options.toPrev) {
            props.options.toPrev(props.options.currentStep - 1);
        }
    } else if (props.options.close) {
        props.options.close();
    }
}

</script>

<style lang="scss" scoped>
@import "~@theme/styles";

.toolbar-steps {
    &__subtitle {
        color: $color-text-body;
    }

    &__info {
        height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        &--one-column {
            justify-content: center;
        }

    }

    &__progress {
        margin-top: 0.25rem;
    }
}
</style>
