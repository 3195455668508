<script setup lang="ts">
import { computed } from "vue";
import { routeNames } from "@router/routeNames";
import { iconsOutline } from "@theme/configs/iconsOutline";
import FeButton from "@ui-kit/FeButton/index.vue";
import * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import { type RouteLocationRaw } from "vue-router";

interface IProps {
    propsRoute?: RouteLocationRaw;
}

defineEmits([ "showAll" ]);

const props = defineProps<IProps>();

const toRoute = computed(() => {
    if (props.propsRoute) {
        return props.propsRoute;
    }
    return { name: routeNames.promotionsCasinoQuests };
});
</script>

<template>
    <section class="quests-card__empty section--light gap-s">
        <div class="gap-xs">
            <FeIcon class="quests-card__empty-img" :icon="iconsOutline.quests" />
            <div class="caption-1">
                {{ $t("QUESTS.CARD_EMPTY_TEXT") }}
            </div>
        </div>
        <FeLink :to="toRoute" class="quests-card__empty-link" @click="$emit('showAll')">
            <FeButton :theme="FeButtonConfig.themes.SecondarySmall">
                {{ $t("COMMON.VIEW_ALL") }}
            </FeButton>
        </FeLink>
    </section>
</template>

<style scoped lang="scss">
@import "~@theme/styles";
.quests-card__empty {
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;

    &-img {
        width: 4rem;
        height: 4rem;
        margin: auto;
        color: $color-text-menu-default;

        svg {
            width: 100%;
            height: auto;
        }

    }

    &-link {
        text-decoration: none;
        display: grid;

        .fe-button {
            padding: 0.5rem;

            @include media(M) {
                padding: 0.5rem 0.75rem;
            }
        }
    }
}
</style>
