import { PROMOTION_STATUS, type IPromotionCard } from "@controllers/services/promotions";

function sortByOrder(first: IPromotionCard, second: IPromotionCard) {
    return second.sortOrder - first.sortOrder;
}

function sortByDate(first: IPromotionCard, second: IPromotionCard) {
    if (first.status === PROMOTION_STATUS.ACTIVE) {
        return Date.parse(first.endAt) - Date.parse(second.endAt);
    }
    if (first.status === PROMOTION_STATUS.PAST) {
        return Date.parse(second.endAt) - Date.parse(first.endAt);
    }
    return Date.parse(first.startAt) - Date.parse(second.startAt);
}

export function sortActivePromoItems(promos: IPromotionCard[]) {
    return promos
        .filter((item: IPromotionCard) => {
            return item.status === PROMOTION_STATUS.ACTIVE;
        })
        .sort(sortByDate)
        .sort(sortByOrder)
        .splice(0, 20);
}
export function filterActiveFuturePromotions(promos: IPromotionCard[]) {
    return promos
        .filter((item: IPromotionCard) => {
            return item.status === PROMOTION_STATUS.ACTIVE || item.status === PROMOTION_STATUS.FUTURE;
        })
        .sort(sortByDate)
        .sort(sortByOrder);
}

export function sortPromoItems(promos: IPromotionCard[]) {
    const activeFuturePromos = promos.filter(
        (item) => item.status === PROMOTION_STATUS.ACTIVE || item.status === PROMOTION_STATUS.FUTURE,
    );
    const pastPromos = promos.filter((item) => item.status === PROMOTION_STATUS.PAST);

    activeFuturePromos.sort(sortByDate).sort(sortByOrder);
    pastPromos.sort(sortByOrder).sort(sortByDate);

    return [ ...activeFuturePromos, ...pastPromos ];
}
