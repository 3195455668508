<script setup lang="ts">
import { MODAL_QUEST } from "@config/modalNames";
import { LabelSize } from "@modules/Quests/components/LabelSize";
import QuestsCardModal from "./QuestsCardModal.vue";
import { useModalsStore } from "@store/modals";
import type { IAchievementResource } from "@api";
import FeProgressBar from "@ui-kit/FeProgressBar/FeProgressBar.vue";
import FeImage from "@ui-kit/FeImage/index.vue";
import { useQuestData } from "../composables/useQuests";
import FeButton from "@ui-kit/FeButton/index.vue";
import * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import QuestsTimer from "./QuestsTimer.vue";
import QuestsLabel from "./QuestsLabel.vue";

interface IProps {
    quest: IAchievementResource;
}
const props = defineProps<IProps>();

const modalsStore = useModalsStore();

const {
    soonQuest,
    questType,
    questLevel,
    questTitle,
    questDescription,
    labelText,
    timerData,
    progressText,
    progressBar,
    rewardJoined,
    questButtonName,
    showButton,
    isQuestFinished,
    routeTo,
} = useQuestData(props.quest);

const showFullDescription = () => {
    modalsStore.showModal({
        name: MODAL_QUEST,
        component: QuestsCardModal,
        props: {
            quest: props.quest,
        },
        new: true,
    });
};
</script>

<template>
    <section class="quests-card section--light" data-test="quest-card">
        <div class="quests-card__top">
            <QuestsTimer :data="timerData" :size="LabelSize.small" />
            <QuestsLabel class="quests-card__label" :type="questType" :size="LabelSize.small">
                {{ labelText }}
            </QuestsLabel>
        </div>
        <div class="quests-card__content">
            <div class="quests-card__wrapper-image">
                <div class="quests-card__image">
                    <FeImage v-if="quest.image" :src="quest.image" alt="quest level" />
                    <div v-if="quest.level" class="quests-card__quest-level">
                        {{ questLevel }}
                    </div>
                </div>
            </div>
            <div class="quests-card__text">
                <div class="quests-card__title caption-2 color-text-body">
                    {{ questTitle }}
                </div>
                <div v-if="questDescription || rewardJoined" class="caption-2">
                    <b>
                        <span class="quests-card__description" v-html="questDescription" />
                        <span class="quests-card__rewards">
                            {{ rewardJoined }}
                        </span>
                    </b>
                </div>
            </div>
        </div>

        <FeProgressBar
            v-if="progressText"
            class="text--align-center"
            :progress="progressBar"
            big
        >
            <template #inside>
                <span> {{ $t("QUESTS.PROGRESS_BAR_TEXT") }} {{ progressText }}</span>
            </template>
        </FeProgressBar>

        <div v-if="!isQuestFinished" class="quests-card__buttons">
            <FeButton
                :theme="FeButtonConfig.themes.TertiaryExtraSmall"
                data-test="quests-card-button-details"
                @click="showFullDescription"
            >
                {{ $t("QUESTS.BUTTON_DETAILS_TEXT") }}
            </FeButton>
            <FeButton
                v-if="showButton"
                :theme="FeButtonConfig.themes.PrimaryExtraSmall"
                :disabled="soonQuest"
                data-test="quests-card-button-action"
                @click="routeTo"
            >
                {{ questButtonName }}
            </FeButton>
        </div>
    </section>
</template>
<style scoped lang="scss">
@import "~@theme/styles/index";
.quests-card {
    display: grid;
    padding: 1rem;
    gap: 1rem;
    grid-template-rows: auto 4rem 1fr;
    height: 100%;
    box-sizing: border-box;

    &__top {
        display: flex;
    }

    &__label {
        margin-left: auto;
    }

    &__buttons {
        display: grid;
        gap: 1rem;
        grid-template-columns: 1fr 1fr;
        overflow: hidden;
    }

    &__image {
        width: 4rem;
        height: 4rem;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__quest-level {
        @include pin;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: $border-radius-M;
        line-height: initial;
        height: initial;
        padding: 0 0.25rem;
        min-height: 1rem;
    }

    &__content {
        display: grid;
        gap: 1rem;
        grid-template-columns: 4rem auto;
        overflow: hidden;
    }
    &__rewards {
        color: $color-secondary-5;
    }

    &__description {
        margin-right: 0.25rem;
    }
    &__text{
        gap: 0.25rem;
        display: grid;
        align-content: start;
    }
}
</style>
