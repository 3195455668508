<template>
    <BaseModal
        :title="i18n.t('TOURNAMENTS.TOURNAMENT_INFO_MODAL_TITLE')"
        gap-s
        class="note-modal"
    >
        <p>
            {{ i18n.t("TOURNAMENTS.TOURNAMENT_INFO_MODAL_DESCRIPTION", { title }) }}
        </p>
        <template #bottom>
            <div class="note-modal__buttons">
                <FeButton
                    :theme="FeButtonConfig.themes.SecondarySmall"
                    @click="confirmHandler"
                >
                    {{ i18n.t("TOURNAMENTS.TOURNAMENT_INFO_MODAL_CONFIRM") }}
                </FeButton>
                <FeButton
                    :theme="FeButtonConfig.themes.TertiarySmall"
                    @click="closeHandler"
                >
                    {{ i18n.t("TOURNAMENTS.TOURNAMENT_INFO_MODAL_CANCEL") }}
                </FeButton>
            </div>
        </template>
    </BaseModal>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import BaseModal from "@components/BaseModal.vue";
import { inject } from "vue";

interface IProps {
    confirmHandler(): void;
    title: string;
}
defineProps<IProps>();

const i18n = useI18n();
const closeHandler = inject("closeHandler", () => {});
</script>

<style scoped lang="scss">
@import "~@theme/styles";
.note-modal {
    &__buttons {
        display: grid;
        gap: 1rem;
    }
}
</style>
