import hotjar from "./HotJar";
import surveyMonkey from "./SurveyMonkey";
import config from "@theme/configs/config";
import DmpController from "./DmpController";
import ValdemoroController from "./ValdemoroController";
import { FullStory } from "@front/core/controllers/MetriksController/FullStory.ts";
import * as Sentry from "@sentry/vue";

import { random } from "@helpers/random";

const isInitFs = (isAuth) => {
    if (isAuth) {
        return true;
    }

    return random(0, 4) === 0;
};

export default {
    init({ fullStory, dmp }, { user, isAuth, isBot, router, valdemoro }) {
        if (!isBot && (!DEV || FORCE_RUN_ANALYTICS)) {
            if (fullStory && !fullStory.disabled && isInitFs(isAuth)) {
                FullStory.init(fullStory.project_id, user);

                FullStory.getCurrentSessionURL((url) => Sentry.setTag("fullstory_session_url", url));
            }

            if (config.hotjar_id) {
                hotjar.init(config.hotjar_id, 6);
            }
            if (config.surveyMonkeyId) {
                surveyMonkey.init(config.surveyMonkeyId);
            }

            if (dmp) {
                DmpController.init(dmp, router, user.user_id);
            }

            if (valdemoro && valdemoro.enabled) {
                ValdemoroController.init(valdemoro);
            }
        }
    },
};
