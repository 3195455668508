import type {
    IClientContextResource,
    IDigitainConfigResource,
    IFastTrackConfigResource,
    ILivespinsDataResource,
    IPragmaticLiveConfigResource,
    ISaturnoAddrConfigResource,
    ITopLeaders,
} from "@api";
import { jsonHttp } from "@api/http";
import log from "@front/core/controllers/LoggerController";

export default {
    async getFastTrack() {
        try {
            return await jsonHttp<IFastTrackConfigResource>("/fasttrack-config");
        } catch (error) {
            log.error("LOAD_FAST_TRACK_CONFIG", error);
        }
    },
    async getDigitain() {
        try {
            return await jsonHttp<IDigitainConfigResource>("/digitain-config");
        } catch (error) {
            log.error("LOAD_DIGITAIN_CONFIG", error);
        }
    },
    async getPragmaticLive() {
        try {
            return await jsonHttp<IPragmaticLiveConfigResource>("/pragmatic-live");
        } catch (error) {
            log.error("LOAD_PRAGMATIC_LIVE_CONFIG", error);
        }
    },
    async getSaturnoAddr() {
        try {
            return await jsonHttp<ISaturnoAddrConfigResource>("/saturno-addr");
        } catch (error) {
            log.error("LOAD_SATURNO_ADDR_CONFIG", error);
            throw error;
        }
    },
    async getClientContext() {
        try {
            return await jsonHttp<IClientContextResource>("/client-context");
        } catch (error) {
            log.error("LOAD_CLIENT_CONTEXT", error);
            throw error;
        }
    },
    async loadTopLeaders() {
        try {
            const data = await jsonHttp<ITopLeaders>("/fe-api/top-leaders", {
                method: "POST",
            });
            return data || {} as ITopLeaders;
        } catch (error) {
            log.error("LOAD_TOP_LEADERS", error);
        }

        return {} as ITopLeaders;
    },
    async loadLivespinsData() {
        try {
            const data = await jsonHttp<ILivespinsDataResource>("/fe-api/livespins-data", {
                method: "GET",
            });
            return data || {} as ILivespinsDataResource;
        } catch (error) {
            log.error("LOAD_LIVESPINS_DATA", error);
        }

        return {} as ILivespinsDataResource;
    },
};
