import { tournamentsAPI, type ITournamentResource, type IUserTournamentListResource } from "@api";
import InfoModal from "@pages/TournamentPage/components/InfoModal.vue";
import { useModalsStore } from "@store/modals";
import { useTournaments } from "@store/tournaments";

export function useChooseTournament() {
    const tournamentStore = useTournaments();
    const modalsStore = useModalsStore();

    async function chooseTournament(tournamentData: ITournamentResource) {
        let matchingTournament = null;

        for (const type of tournamentData.gameTypes) {
            matchingTournament = tournamentStore.userTournamentList.find((tournament: IUserTournamentListResource) =>
                tournament.gameTypes.includes(type),
            );

            if (matchingTournament) {
                break;
            }
        }

        if (matchingTournament) {
            return await showNoteModal(tournamentData, matchingTournament.title);
        }

        return await choose(tournamentData);
    }
    async function choose(tournamentData: ITournamentResource) {
        const { status } = await tournamentsAPI.chooseTournament(tournamentData.id);

        if (status === "ok") {
            await tournamentStore.loadTournament(tournamentData.slug, true);
            await tournamentStore.loadUserTournamentList();
        }

        return status;
    }

    async function showNoteModal(tournamentData: ITournamentResource, title: string) {
        return new Promise((resolve) => {
            modalsStore.showModal({
                name: "tournament-note-modal",
                component: InfoModal,
                new: true,
                props: {
                    confirmHandler: async () => {
                        const status = await choose(tournamentData);
                        modalsStore.closeModal("tournament-note-modal");
                        resolve(status);
                    },
                    title,
                },
                callback: () => {
                    resolve(undefined);
                },
            });
        });
    }

    return {
        chooseTournament,
    };
}
