export enum LoginResourceStep {
    EMAIL_VERIFICATION = "email_verification",
}

export interface ILoginResource {
    step?: LoginResourceStep | string;
}
export type LoginErrorDTO = {
    captcha_required?: boolean;
    message?: string;
}

export interface ILogoutResource {
    redirect: string;
    success: boolean;
}

export interface IRegisterResource {
    success: boolean;
    user_is_logged: boolean;
}

export interface IVerifyEmailResource {
    result: string;
}
