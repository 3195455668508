import { jsonHttp } from "@api/http";
import log from "@front/core/controllers/LoggerController";

interface FreshChatMessage {
    id: string
    conversation_id: string
    channel_id: string
    message_parts: Array<Partial<Record<string, string>>>
    app_id: string
    actor_id: string
    org_actor_id: string
    freshchat_conversation_id: string
    freshchat_channel_id: string
    interaction_id: string
    message_type: string
    actor_type: string
    created_time: string
    user_id: string
    restrictResponse: boolean
    botsPrivateNote: boolean
    isBotsInput: boolean
}

export default {
    async sendFreshChatMessage(userId: string, restoreId?: string) {
        try {
            return await jsonHttp<{ data: FreshChatMessage | null }>(
                "/fe-api/fresh-chat-send-message",
                { method: "POST", body: JSON.stringify({ restoreId, userId }) },
            );
        } catch (error: unknown) {
            log.error("FRESH_CHAT_MESSAGE", error);

            return { data: null };
        }
    },
};
