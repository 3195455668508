import type { Pinia } from "pinia";
import bus from "@bus";
import isPwaMode from "@helpers/isPwaMode";
import { isServer } from "@helpers/ssrHelpers";
import { getUtmParamsFromCookies } from "@front/core/controllers/utmParams";
import { getAffiliateToCookie } from "@front/core/controllers/affiliateParam";
import { useUserInfo } from "@store/userInfo";
import { useServerStore } from "@store/server";
import { useContextStore } from "@store/context";
import { useMultiLang } from "@store/multilang";

let gtagCallback: ((...args: unknown[]) => void) | null = null;

function gtag(...args: unknown[]) {
    if (gtagCallback) {
        gtagCallback(...args);
    }
}

function pushToDataLayer() {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
}


function createGtag (pinia?: Pinia) {
    return function() {
        const userInfoStore = useUserInfo(pinia);
        // eslint-disable-next-line prefer-rest-params
        const args = [ ...arguments ];
        const userId = userInfoStore.userInfo?.user_id || "";

        if (userId) {
            args[2] = {
                // eslint-disable-next-line prefer-rest-params
                ...arguments[2],
                user_id: userId,
            };
        }

        // @ts-expect-error A spread argument must either have a tuple type or be passed to a rest parameter.
        pushToDataLayer(...args);
    };
}

bus.$on("user.login", (data: unknown) => {
    const utmParamsFromCookies = getUtmParamsFromCookies() as Record<string, string>;
    const affiliateFromCookies = getAffiliateToCookie();

    gtag("set", "user_properties", {
        affiliate: affiliateFromCookies || "",
        user_currency: (data as Record<string, unknown>).currency,
        user_country: (data as Record<string, unknown>).country,
        user_platform: isPwaMode() ? "pwa" : "",
        cookie_utm_source: utmParamsFromCookies.utm_source || "",
        cookie_utm_medium: utmParamsFromCookies.utm_medium || "",
        cookie_utm_campaign: utmParamsFromCookies.utm_campaign || "",
        cookie_utm_term: utmParamsFromCookies.utm_term || "",
        cookie_utm_content: utmParamsFromCookies.utm_content || "",
    });
});

function installGMP (pinia?: Pinia) {
    gtagCallback = createGtag(pinia);
    const contextStore = useContextStore(pinia);
    const multiLangStore = useMultiLang(pinia);

    if (isServer || contextStore.isBot) {
        return;
    }

    const serverStore = useServerStore(pinia);
    const { googleAnalytics } = serverStore.metricsConfig;
    if (googleAnalytics?.project_id) {
        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtag/js?id=${ googleAnalytics.project_id }`;
        script.async = true;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        gtag("js", new Date());
        gtag("config", googleAnalytics.project_id, {
            language: multiLangStore.userLocale,
        });
    }
}

export {
    installGMP,
    gtag,
};
