import type { RouteLocationNormalized } from "vue-router";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import type { IServerData } from "@api/resources/serverResources";
import serverAPI from "@api/requests/server";
import { useRatesStore } from "@store/rates";
import { useSeoMetaStore } from "@store/seoMeta";
import { useStaticPagesStore } from "@store/staticPages";
import { DEFAULT_CURRENCY } from "@theme/configs/constsCurrencies";

export const useServerStore = defineStore("server", () => {
    const { loadRatesData } = useRatesStore();
    const { loadSeoMeta, loadHreflangList } = useSeoMetaStore();
    const { loadStaticPages } = useStaticPagesStore();

    const apiUrl = ref("/");
    const websocketUrl = ref("/sock");
    const serverData = ref<IServerData>({
        currencies: [],
        defaultCurrency: DEFAULT_CURRENCY,
        metrics: {
            fullStory: {
                project_id: "FWWXX",
                disabled: false,
            },
            googleAnalytics: {
                project_id: "G-3PHFZX9CE4",
                disabled: false,
            },
        },
    });

    const captchaKey = computed(() => serverData.value.captcha?.site_key);
    const captchaState = computed(() => serverData.value.captcha?.enabled);
    const communicationChanelUrl = computed(() => serverData.value.promoTelegramChannelUrl);
    const currencies = computed(() => serverData.value.currencies);
    const defaultCurrency = computed(() => serverData.value.defaultCurrency);
    const freshChatConfig = computed(() => serverData.value.callCenterConfig?.freshchat);
    const liveChatConfig = computed(() => serverData.value.callCenterConfig?.livechat);
    const metricsConfig = computed(() => serverData.value.metrics);
    const valdemoroConfig = computed(() => serverData.value.valdemoro);
    const webPushKey = computed(() => serverData.value.webPushKey);

    const setApiUrl = (url: string) => {
        apiUrl.value = url;
    };
    const setServerData = (payload?: IServerData) => {
        if (!payload) {
            return;
        }

        serverData.value = {
            ...serverData.value,
            ...payload,
        };
    };
    const loadServerData = async (): Promise<IServerData | undefined> => {
        if (serverData.value.isLoaded) {
            return Promise.resolve(serverData.value);
        }

        const data = await serverAPI.loadServerData();
        setServerData(data);

        return data;
    };
    const fetchServerData = (route: RouteLocationNormalized) => {
        // loadStaticPages - must be the first item in array
        return [ loadStaticPages(), loadRatesData(), loadSeoMeta(route), loadServerData(), loadHreflangList() ];
    };

    return {
        apiUrl,
        captchaKey,
        captchaState,
        communicationChanelUrl,
        currencies,
        defaultCurrency,
        freshChatConfig,
        liveChatConfig,
        metricsConfig,
        valdemoroConfig,
        webPushKey,
        serverData,
        websocketUrl,
        setApiUrl,
        fetchServerData,
    };
});
