import type { RouteRecordName } from "vue-router";
import type { IStaticPageResource, IStaticPagesItemResource } from "@api/resources/serverResources";
import { ref } from "vue";
import { defineStore } from "pinia";
import { useSeoMetaStore } from "@store/seoMeta";
import serverAPI from "@api/requests/server";

type PayloadParams = {
    slug: RouteRecordName;
    url: string;
};

export const useStaticPagesStore = defineStore("staticPages", () => {
    const { setSeoMeta } = useSeoMetaStore();
    const staticPages = ref<IStaticPagesItemResource[]>([]);
    const currentStaticPage = ref<IStaticPageResource | null>(null);

    const setStaticPages = (pages?: IStaticPagesItemResource[]) => {
        if (!pages) {
            return;
        }

        staticPages.value = pages.map((page) => {
            page.pageType = "static";

            return page;
        });
    };
    const setCurrentStaticPage = (page: IStaticPageResource | null) => {
        currentStaticPage.value = page;
    };
    const loadStaticPages = async (): Promise<IStaticPagesItemResource[] | undefined> => {
        const data = await serverAPI.loadStaticPages();
        setStaticPages(data);

        return data;
    };
    const loadCurrentStaticPage = async ({ slug, url }: PayloadParams): Promise<IStaticPageResource | null | void> => {
        if (currentStaticPage.value?.slug === slug) {
            return Promise.resolve(currentStaticPage.value);
        }

        setCurrentStaticPage(null);
        if (slug) {
            const data = await serverAPI.getCurrentStaticPage(String(slug));

            if (data) {
                setCurrentStaticPage(data);
                setSeoMeta({ meta: data.meta, url: url });
                return data;
            }
        }
    };

    return {
        staticPages,
        currentStaticPage,
        loadStaticPages,
        loadCurrentStaticPage,
    };
});
