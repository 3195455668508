import CoveryController from "@front/core/controllers/CoveryController.js";
import featureFlags from "@theme/configs/featureFlags";

export default function(dataObj) {
    if (featureFlags.dfpcEnabled) {
        return {
            ...dataObj,
            dfpc: CoveryController.deviceFingerprint(),
        };
    }

    return dataObj;
}
