export interface ILicenseDomain {
    domainName: string;
    licenceId: string;
    licenceScriptId: string;
    licenceScriptSrc: string;
}

export const mainDomain: string = "winspirit.com";
export const specialDomain: string[] = [];
export const licenseDomains: ILicenseDomain[] = [
    {
        domainName: "winspirit.com",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
    {
        domainName: "winspirit.co",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
    {
        domainName: "winspirit.casino",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
    {
        domainName: "thor.development.wlabel.site",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
    {
        domainName: "thor.staging.wlabel.site",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
    {
        domainName: "thor-aws.staging.wlabel.site",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
    {
        domainName: "localhost",
        licenceId: "apg-d952feb7-6f64-42c6-94f9-ad0ff1986d93",
        licenceScriptId: "apg_d952feb7_6f64_42c6_94f9_ad0ff1986d93",
        licenceScriptSrc: "https://d952feb7-6f64-42c6-94f9-ad0ff1986d93.snippet.antillephone.com/apg-seal.js",
    },
];

export type GCBCertificationDomain = {
    domainName: string;
    token: string;
};
export const gcbCertificationDomains: GCBCertificationDomain[] = [
    {
        domainName: "winspirit.com",
        // eslint-disable-next-line @stylistic/js/max-len
        token: "ZXlKcGRpSTZJbkZrTURaNFZ6QnlVV2RpUVVsSlVuUlZMMEpyVmxFOVBTSXNJblpoYkhWbElqb2lja1ZsTTNkTGJIaEJUR3RwVFRFclRVaFBNVlJRVVQwOUlpd2liV0ZqSWpvaU0ySmtOVEkyT1RrM1pESTVNREUxWldVeVlUUmpOVEV5WXpKaFpXWmlNVE5qTVRWbFl6RmtNVGcxTnpnNE1EQTVOMkl6WldVNU5USTVPRE5tWlRsaFlTSXNJblJoWnlJNklpSjk=",
    },
    {
        domainName: "winspirit.co",
        // eslint-disable-next-line @stylistic/js/max-len
        token: "ZXlKcGRpSTZJa0pFUVN0VVdHRmhORTR4ZUdWQlVWQXhNVlpqUTNjOVBTSXNJblpoYkhWbElqb2lkbkpqVml0SFYzaElZbFJ1ZUZsS09ESjRaeTgzWnowOUlpd2liV0ZqSWpvaVlXSTVORGswTURrMU5tUTFaRFV5TURRMlpqQmlORE5oTW1ZMk5URXlNbUZsWmpVNU5XWTJPRFV6WmpSallqbGlaalEzTkRFeFpqTTBOVFEwT1RZMU5pSXNJblJoWnlJNklpSjk=",
    },
    {
        domainName: "winspirit.casino",
        // eslint-disable-next-line @stylistic/js/max-len
        token: "ZXlKcGRpSTZJbXBqUlVKellsRnZkVE5QVVZWM1ZWbE1jRU5yTjBFOVBTSXNJblpoYkhWbElqb2lPV3BPWkdVNFdFTkNOaTkyT0dGTFNsVlNja0p2YjJwd1ZFZFlORlJKVVhWVE5IVlJUelJ1U0ZoMlFUMGlMQ0p0WVdNaU9pSmtZV1E0TVRVM01EQTVaR05rTW1JMlpETTFPVFV6WkRrMU1tWmpPVGhpWkRaaU1qYzJaakJpTWpOalpXSTFNamd6WldabE5URmxOMkUwTmpVNVlUVTFJaXdpZEdGbklqb2lJbjA9",
    },
    {
        domainName: "thor.development.wlabel.site",
        // eslint-disable-next-line @stylistic/js/max-len
        token: "ZXlKcGRpSTZJbkZrTURaNFZ6QnlVV2RpUVVsSlVuUlZMMEpyVmxFOVBTSXNJblpoYkhWbElqb2lja1ZsTTNkTGJIaEJUR3RwVFRFclRVaFBNVlJRVVQwOUlpd2liV0ZqSWpvaU0ySmtOVEkyT1RrM1pESTVNREUxWldVeVlUUmpOVEV5WXpKaFpXWmlNVE5qTVRWbFl6RmtNVGcxTnpnNE1EQTVOMkl6WldVNU5USTVPRE5tWlRsaFlTSXNJblJoWnlJNklpSjk=",
    },
    {
        domainName: "thor.staging.wlabel.site",
        // eslint-disable-next-line @stylistic/js/max-len
        token: "ZXlKcGRpSTZJa0pFUVN0VVdHRmhORTR4ZUdWQlVWQXhNVlpqUTNjOVBTSXNJblpoYkhWbElqb2lkbkpqVml0SFYzaElZbFJ1ZUZsS09ESjRaeTgzWnowOUlpd2liV0ZqSWpvaVlXSTVORGswTURrMU5tUTFaRFV5TURRMlpqQmlORE5oTW1ZMk5URXlNbUZsWmpVNU5XWTJPRFV6WmpSallqbGlaalEzTkRFeFpqTTBOVFEwT1RZMU5pSXNJblJoWnlJNklpSjk=",
    },
    {
        domainName: "thor-aws.staging.wlabel.site",
        // eslint-disable-next-line @stylistic/js/max-len
        token: "ZXlKcGRpSTZJa0pFUVN0VVdHRmhORTR4ZUdWQlVWQXhNVlpqUTNjOVBTSXNJblpoYkhWbElqb2lkbkpqVml0SFYzaElZbFJ1ZUZsS09ESjRaeTgzWnowOUlpd2liV0ZqSWpvaVlXSTVORGswTURrMU5tUTFaRFV5TURRMlpqQmlORE5oTW1ZMk5URXlNbUZsWmpVNU5XWTJPRFV6WmpSallqbGlaalEzTkRFeFpqTTBOVFEwT1RZMU5pSXNJblJoWnlJNklpSjk=",
    },
];
