export const routeNames = {
    base: "Base",
    main: "Main",
    promoPage: "PromoPage",
    tournamentPage: "TournamentPage",
    profile: "profileCabinet",
    profileGifts: "ProfileGifts",
    profileBonuses: "profileBonuses",
    profileBonusesCasino: "profileBonusesCasino",
    profileBonusesSport: "profileBonusesSport",
    profileBonusesInsurance: "profileBonusesInsurance",
    profilePromocode: "profilePromocode",
    profileVerification: "profileVerification",
    profileCabinet: "profileCabinet",
    profileMyLevel: "profileMyLevel",
    profileLimits: "profileLimits",
    gameBase: "GameBase",
    bettingPage: "Betting",
    esportPage: "Esport",
    gameItem: "GameItem",
    gameLivespins: "GameLivespins",
    gameItemDemo: "GameItemDemo",
    remind: "Remind",
    newPassword: "NewPassword",
    setPassword: "SetPassword",
    login: "Login",
    registration: "Registration",
    casinoBonusPage: "CasinoBonusPage",
    sportsBonusPage: "SportsBonusPage",
    casinoPage: "CasinoPage",
    casinoProviders: "CasinoProviders",
    casinoProvider: "CasinoProvider",
    casinoLivespins: "CasinoLivespins",
    casinoCategory: "CasinoCategory",
    casinoThemes: "CasinoThemes",
    casinoTheme: "CasinoTheme",
    livePage: "LivePage",
    liveProviders: "LiveProviders",
    liveProvider: "LiveProvider",
    liveCategories: "LiveCategories",
    liveCategory: "LiveCategory",
    loyaltyProgramPage: "LoyaltyProgramPage",
    faqPage: "FaqPage",
    verificationPage: "VerificationPage",
    vipClubPage: "VipClubPage",
    oopsPage: "OopsPage",
    unsubscribe: "Unsubscribe",
    benefitCrypto: "BenefitCrypto",
    shareExperiencePage: "ShareExperiencePage",
    promotionsDashboard: "PromotionsDashboard",
    promotionsCasinoCurrent: "PromotionsCasinoCurrent",
    promotionsCasinoActions: "PromotionsCasinoActions",
    promotionsCasinoTournaments: "PromotionsCasinoTournaments",
    promotionsCasinoQuests: "PromotionsCasinoQuests",
    promotionsLiveCurrent: "PromotionsLiveCurrent",
    promotionsLiveActions: "PromotionsLiveActions",
    promotionsLiveTournaments: "PromotionsLiveTournaments",
    promotionsLiveQuests: "PromotionsLiveQuests",
    promotionsSportCurrent: "PromotionsSportCurrent",
    promotionsSportActions: "PromotionsSportActions",
    promotionsSportTournaments: "PromotionsSportTournaments",
    promotionsSportQuests: "PromotionsSportQuests",
    christmasPage: "ChristmasPage",
    santasGiftShopPromoPage: "SantasGiftShopPromoPage",
    calendarPage: "CalendarPage",
    // tech
    featureFlagConfiguration: "FeatureFlagConfiguration",
};
