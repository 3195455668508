<script lang="ts">
import { h } from "vue";
import { colorsList } from "../lib";

/**
 * @typedef {Object} Theme
 * @property {Color} color
 * */

/**
 * @typedef {Object} ThemesList
 * @enum {Theme}
 * */
export const themes = {
    White: {
        color: colorsList.white,
    },
    Black: {
        color: colorsList.black,
    },
    Disabled: {
        color: colorsList.disabled,
    },
    Primary: {
        color: colorsList.primary,
    },
    Secondary: {
        color: colorsList.secondary,
    },
    Success: {
        color: colorsList.success,
    },
    Alert: {
        color: colorsList.alert,
    },
    Danger: {
        color: colorsList.danger,
    },
    Gray: {
        color: colorsList.gray,
    },
};

/**
 * @typedef {Object} Size
 * @property {HTMLElementTagNameMap} tag
 * @property {String} [classPrefix]
 * */

/**
 * @typedef {Object} SizeList
 * @enum {Size}
 * */
export const size = {
    H1: {
        tag: "h1",
        classPrefix: "h1",
    },
    H2: {
        tag: "h2",
        classPrefix: "h2",
    },
    H3: {
        tag: "h3",
        classPrefix: "h3",
    },
    H4: {
        tag: "h4",
        classPrefix: "h4",
    },
    Base: {
        tag: "p",
    },
    BaseRegular: {
        tag: "p",
        classPrefix: "regular",
    },
    Inline: {
        tag: "span",
    },
    Small: {
        tag: "small",
        classPrefix: "small",
    },
    SmallRegular: {
        tag: "small",
        classPrefix: "small-regular",
    },
};

/**
 * @typedef {String} Font
 * */

/**
 * @typedef {String} Align
 * */

/**
 * @typedef {Object} AlignList
 * @enum {Align}
 * */
export const align = {
    Left: "left",
    Center: "center",
    Right: "right",
};

export default {
    name: "FeText",

    props: {

        /**
         * @type {Theme}
         * */
        theme: {
            type: Object,
            default: () => {
                return {};
            },
        },

        /**
         * @type {Align}
         * */
        align: {
            type: String,
        },

        /**
         * @type {Size}
         * */
        size: {
            type: Object,
            default: () => {
                return size.Base;
            },
        },
    },

    setup(props, { slots }) {
        const params = {
            class: {
                "fe-text": true,
                [`fe-text--${props.theme.color}`]: props.theme.color,
                [`fe-text--${props.size.classPrefix}`]: props.size.classPrefix,
                [`fe-text--text-${props.align}`]: props.align,
            },
        };

        return () => h(props.size.tag, params, slots.default && slots.default());
    },
};
</script>

<style lang="scss" src="./style.scss"></style>
