export const MODAL_CASHBOX = "modal-cashbox";
export const MODAL_CASHBOX_DEPOSIT = "modal-cashbox-deposit";
export const MODAL_CASHBOX_WITHDRAWAL = "modal-cashbox-withdraw";
export const MODAL_CASHBOX_WITHDRAWAL_REQUESTS = "modal-cashbox-withdraw-requests";
export const MODAL_CASHBOX_WITHDRAWAL_MESSAGES = "modal-cashbox-withdraw-messages";
export const MODAL_CASHBOX_SERVICE_WORKS = "modal-cashbox-service-works";
export const MODAL_CANNOT_RUN_GAME = "modal-cannot-run-game";
export const MODAL_SEARCH = "modal-search";
export const MODAL_GAME_NOTE = "modal-game-note";
export const MODAL_GAME_SUSPENDED = "modal-game-suspended";
export const MODAL_GAME_COOLING_OFF = "game-cooling-off";
export const MODAL_CONFIRM_EXIT = "confirm-exit";
export const MODAL_DEPOSIT_BONUS_BALANCE_INFO = "deposit-bonus-balance-info";
export const MODAL_DEPOSIT_INSURANCE = "modal-deposit-insurance";
export const MODAL_THANKS_SPECIAL = "modal-thanks-special";
export const MODAL_QUEST = "modal-quest";
export const MODAL_PROMO_RULES = "modal-promo-rules";
export const MODAL_PROMO_ALL_PRIZES = "modal-promo-all-prizes";
export const MODAL_PRIZE_MODAL = "prize-modal";
export const MODAL_PRIZE_EXCHANGE_SUCCESS = "prize-exchange-success-modal";
export const MODAL_PRIZE_EXCHANGE_ERROR = "prize-exchange-error-modal";
export const MODAL_PWA_INSTRUCTION = "pwa-instruction";
export const MODAL_PWA_INSTRUCTION_IOS_SAFARI = "pwa-instruction-ios-safari";
export const MODAL_PWA_INSTRUCTION_MACOS_SAFARI = "pwa-instruction-macos-safari";
export const MODAL_PWA_INSTRUCTION_INSTALL_APP = "pwa-instruction-install-app";
export const MODAL_PWA_INSTRUCTION_COPY_LINK = "pwa-instruction-copy-link";
export const MODAL_CALLBACK = "modal-callback";
export const MODAL_ANDROID_WEBVIEW_ESCAPE = "android-webview-escape";
export const MODAL_IOS_WEBVIEW_ESCAPE = "ios-webview-escape";
export const MODAL_BONUS_CANCEL = "bonus-cancel";
