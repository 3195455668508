<script setup lang="ts">
import { LabelSize } from "./LabelSize";
import { computed } from "vue";
import { QuestType } from "@modules/Quests";

interface IProps {
    type: QuestType;
    size?: LabelSize;
}

const props = withDefaults(defineProps<IProps>(), {
    size: LabelSize.small,
});

const classes = computed(() => {
    const list: string[] = [ `quests-label--${props.size}` ];

    if (props.type === QuestType.DONE) {
        list.push("color-text-successfully");
    } else if (props.type === QuestType.FALSY) {
        list.push("color-text-falsy");
    } else {
        list.push("color-text-alt");
    }
    return list;
});
</script>

<template>
    <div class="quests-label" :class="classes">
        <slot />
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles";
.quests-label {
    border-radius: $border-radius-S;
    background: $color-tertiary-3;

    &--sm {
        padding: 0 0.5rem;
        @include font-size--SmallCaption;
    }
    &--md {
        padding: 0.25rem 0.5rem;
        @include font-size--Caption2($font-weight--normal);
    }
}
</style>
