import { userAPI } from "@api";
import { getFeatureFlags } from "@front/core/modules/FeatureFlagConfiguration";
import type { IFreshChatParams } from "@front/core/modules/FreshChat/types";
import { useServerStore } from "@store/server";
import { useUserInfo } from "@store/userInfo";
import { useMultiLang } from "@store/multilang";
import { FRESHCHAT_WIDGET_CUSTOM_UUID, FRESHCHAT_WIDGET_UUID } from "@theme/configs/constantsFreshChat";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useUserStatusService } from "@controllers/services/user";

export interface ILcMessage {
    timestamp: number;
}

export const useChats = defineStore("chats", () => {
    const serverStore = useServerStore();
    const userInfoStore = useUserInfo();
    const { userVipStatus } = useUserStatusService();
    const multiLangStore = useMultiLang();

    const isShowSupportChatBadge = ref(false);
    // Freshchat Data
    const fcRestoreId = ref<string>();
    const fcRestoreIdLoaded = ref<boolean>(false);
    const fcNewMessagesCount = ref<number>(0);
    // Livechat Data
    const lcMessages = ref<ILcMessage[]>([]);
    const lcNewMessagesCount = ref<number>(0);

    const fcChatData = computed<IFreshChatParams>(() => {
        const serverConfig = serverStore.freshChatConfig;
        const email = userInfoStore.userEmail;

        return {
            token: serverConfig?.token ?? "",
            widgetUuid: getFeatureFlags().freshchatCustomWidgetId
                ? FRESHCHAT_WIDGET_CUSTOM_UUID
                : FRESHCHAT_WIDGET_UUID,
            restoreId: userInfoStore.isLogged ? fcRestoreId.value : undefined,
            externalId: userInfoStore.isLogged ? userInfoStore.getUserId : undefined,
            countryCode: userInfoStore.userCountryCode,
            email: userInfoStore.isLogged ? email : undefined,
            pending: userInfoStore.isLogged && !fcRestoreIdLoaded.value,
            userLocale: multiLangStore.userLanguage,
            userVIPStatus: userInfoStore.isLogged ? (userVipStatus.value || "") : undefined,
            userVIPManagerName: userInfoStore.vipManagerData?.publicName ?? "",
            userEmailVerified: userInfoStore.isLogged ? userInfoStore.isVerifiedEmail : undefined,
        };
    });
    const newMessageCount = computed<string>(() => {
        const livechatCount: number = lcNewMessagesCount.value || 0;
        const freshchatCount: number = fcNewMessagesCount.value || 0;
        const count: number = Math.max(livechatCount, freshchatCount);

        if (count > 1) {
            return "1+";
        }
        return count ? String(count) : "";
    });

    async function loadFcRestoreId(forceReload = false) {
        if (!forceReload && fcRestoreIdLoaded.value) {
            return;
        }

        const restoreId = (await userAPI.loadFreshchatRestoreId(userInfoStore.getUserId)) || "";
        if (restoreId.trim()) {
            fcRestoreId.value = restoreId.trim();
        }
        fcRestoreIdLoaded.value = true;
    }

    function cleanRestoreId() {
        fcRestoreId.value = undefined;
        fcRestoreIdLoaded.value = false;
    }

    async function sendFcRestoreId(restoreId: string) {
        // to prevent override restore id when it is not initialized yet
        if (!fcRestoreIdLoaded.value || restoreId === fcRestoreId.value) {
            return;
        }

        fcRestoreId.value = restoreId;
        await userAPI.setFreshchatRestoreId(userInfoStore.getUserId, restoreId);
    }

    function fcSetNewMessagesCount(count: number) {
        fcNewMessagesCount.value = count;
    }

    function lcSetMessage(message: ILcMessage) {
        if (lcMessages.value.find((item: ILcMessage) => {
            return item.timestamp === message.timestamp;
        })) {
            return;
        }

        lcMessages.value.push(message);
        const lastOpen = localStorage.getItem("lastOpen");
        if (!lastOpen || message.timestamp > Number(lastOpen)) {
            lcNewMessagesCount.value++;
        }
    }

    function lcResetMessageCount() {
        lcNewMessagesCount.value = 0;
    }

    function lcSetChatOpen() {
        lcNewMessagesCount.value = 0;
        localStorage.setItem("lastOpen", String(Date.now()));
    }

    function setShowSupportChatBadge(value: boolean) {
        isShowSupportChatBadge.value = value;
    }

    return {
        // FreshChat
        loadFcRestoreId,
        cleanRestoreId,
        sendFcRestoreId,
        fcNewMessagesCount,
        fcSetNewMessagesCount,
        fcChatData,

        // LiveChat
        lcMessages,
        lcNewMessagesCount,
        lcSetMessage,
        lcResetMessageCount,
        lcSetChatOpen,

        newMessageCount,
        isShowSupportChatBadge,
        setShowSupportChatBadge,
    };
});
