<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router";
import { computed, resolveComponent, useAttrs } from "vue";
import { isServer } from "@helpers/ssrHelpers";
import isExternalUrl from "@helpers/isExternalUrl";

interface IFeLinkProps {
    to?: RouteLocationRaw;
    theme?: "primary" | "secondary" | "regular"; // regular -> no styles
}

interface IFeLinkEmits {
    (event: "click", value: MouseEvent): void;
}

defineOptions({
    inheritAttrs: false,
});

const props = withDefaults(defineProps<IFeLinkProps>(), {
    theme: "regular",
});

const attrs = useAttrs();

defineEmits<IFeLinkEmits>();

const isBelongsToCurrentDomain = computed(() => {
    if (!isServer) {
        const currentDomain = location.hostname;
        const regex = new RegExp(`^(http|https)://${currentDomain.replace(".", "\\.")}.*$`);

        return typeof props.to === "string" && regex.test(props.to);
    }
});
const isExternal = computed(() => {
    if (isBelongsToCurrentDomain.value) {
        return false;
    }

    return typeof props.to === "string" && isExternalUrl(props.to);
});
const isPhoneLink = computed(() => typeof props.to === "string" && (/tel:/).test(props.to));
const isMailToLink = computed(() => typeof props.to === "string" && (/mailto:/).test(props.to));
const isAnchorLink = computed(() => typeof props.to === "string" && props.to.startsWith("#"));
const isNativeLinkTag = computed(
    () =>
        isExternal.value ||
        isBelongsToCurrentDomain.value ||
        isAnchorLink.value ||
        isPhoneLink.value ||
        isMailToLink.value,
);

const targetAttribute = computed(() => (isExternal.value ? "_blank" : attrs.target as string | undefined));

const relAttribute = computed(() => {
    if (
        !targetAttribute.value?.includes("_blank") ||
        (targetAttribute.value?.includes("_blank") && !isExternal.value)
    ) {
        return attrs.rel || undefined;
    }

    return `${attrs.rel || ""} noreferrer noopener`.trim();
});

const url = computed(() => {
    if (!props.to) {
        return undefined;
    }

    if (isNativeLinkTag.value) {
        return { href: props.to };
    }

    return { to: props.to };
});

const component = computed(() => {
    if (!props.to) {
        return "span";
    }

    return isNativeLinkTag.value ? "a" : resolveComponent("RouterLink");
});
</script>

<template>
    <component
        :is="component"
        :class="[ 'fe-link', theme !== 'regular' ? `fe-link--${theme}` : '' ]"
        v-bind="{ ...attrs, ...url }"
        :target="targetAttribute"
        :rel="relAttribute"
        @click="$emit('click', $event)"
    >
        <slot />
    </component>
</template>

<style lang="scss" src="./style.scss"></style>
