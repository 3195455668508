import type { Locales } from "@src/types/multilang";

export default function(defaultLang: string, enableLocales: Locales[], lang: string, redirectUrl?: string) {
    if (typeof window === "undefined") {
        return;
    }

    const originalUrl = window.location.pathname + window.location.search;
    const urlLang = originalUrl.split("/")[1];
    if (lang === urlLang) {
        return;
    }

    const urlLangInEnableLocale = enableLocales?.find((item) => {
        return item.code === urlLang;
    });
    if (redirectUrl) {
        if (urlLangInEnableLocale) {
            if (lang === defaultLang) {
                window.location.href = redirectUrl;
            } else {
                window.location.href = `/${ lang }${ redirectUrl }`;
            }
        } else if (lang !== defaultLang) {
            window.location.href = `/${ lang }${ redirectUrl }`;
        }

        return;
    }

    if (urlLangInEnableLocale) {
        if (lang === defaultLang) {
            window.location.href = originalUrl.replace(/\/[a-z]{2}-?[A-Z]{0,2}\/?/, "/");
        } else {
            window.location.href = originalUrl.replace(urlLang, lang);
        }
    } else if (lang !== defaultLang) {
        window.location.href = `/${ lang }${ originalUrl }`;
    }
}
