import type { LimitSubtype, LimitType } from "@controllers/services/limits";

export interface IManageLimitDTO <T extends LimitType = LimitType>{
    subType: LimitSubtype;
    type: T;
    limit: number;
}

export interface IDisableLimitDTO <T extends LimitType = LimitType>{
    subType: LimitSubtype;
    type: T;
}

export enum SelfExclusionActivatePeriod {
    DAY = "d",
    MONTH = "m",
    YEAR = "y",
    FOREVER = "forever",
}
export interface ISelfExclusionActivateDTO {
    token: string;
    reason: string;
    period: {
        type: SelfExclusionActivatePeriod;
        value?: number;
    };
}
