import type { Pinia } from "pinia";
import { UserStatusResource } from "@api";
import type { IRegularCard, IStatusCard } from "@controllers/services/pages/vip-club";
import { useUserInfo } from "@store/userInfo";
import { computed } from "vue";

const userStatusCodes: Record<number, string> = {
    0: "NO_SET",
    10: "NEW",
    20: "ACE",
    30: "ACE2",
    40: "ACE3",
    50: "EXVIP",
    60: "STAR",
    70: "BRONZE",
    80: "SILVER",
    90: "GOLD",
    100: "PLATINUM",
    110: "DIAMOND",
};

export const USER_REGULAR_STATUS = "REGULAR" as const;

export enum UserVipStatus {
    BRONZE = "BRONZE",
    SILVER = "SILVER",
    GOLD = "GOLD",
    PLATINUM = "PLATINUM",
    DIAMOND = "DIAMOND",
}

export enum StateStatus {
    ACHIEVED = "achieved",
    ACTIVE = "active",
    PROGRESS = "progress",
    LOCK = "lock",
}

export const UserVipStatusOrder: UserVipStatus[] = [
    UserVipStatus.BRONZE,
    UserVipStatus.SILVER,
    UserVipStatus.GOLD,
    UserVipStatus.PLATINUM,
    UserVipStatus.DIAMOND,
];

export function useUserStatusService(pinia?: Pinia) {
    const userInfoStore = useUserInfo(pinia);

    const quarterStatus = computed<keyof typeof UserStatusResource>(() => {
        return userInfoStore.userStatusData.currentStatus;
    });
    const activeStatus = computed<UserVipStatus | typeof USER_REGULAR_STATUS>(() => {
        return userInfoStore.userStatusData.activeStatus;
    });

    const userVipStatus = computed<UserVipStatus | undefined>(() => {
        return UserVipStatusOrder.find((value) => {
            return value === quarterStatus.value;
        });
    });

    const nextQuarterStatus = computed<UserVipStatus | undefined>(() => {
        if (!userVipStatus.value) {
            return UserVipStatus.BRONZE;
        }

        const currentIndex = UserVipStatusOrder.indexOf(userVipStatus.value);
        const nextIndex = currentIndex + 1;

        return UserVipStatusOrder[nextIndex] || undefined;
    });

    const nextActiveStatus = computed<UserVipStatus>(() => {
        return userInfoStore.userStatusData.nextStatus;
    });

    const achievedStatus = computed<Array<UserVipStatus | typeof USER_REGULAR_STATUS>>(() => {
        if (!userVipStatus.value) {
            return [];
        }

        const currentIndex = UserVipStatusOrder.indexOf(userVipStatus.value);
        return UserVipStatusOrder.slice(0, currentIndex);
    });

    const achievedActiveStatus = computed<Array<UserVipStatus | typeof USER_REGULAR_STATUS>>(() => {
        if (!userVipStatus.value || activeStatus.value === USER_REGULAR_STATUS) {
            return [];
        }

        const currentIndex = UserVipStatusOrder.indexOf(activeStatus.value);
        return UserVipStatusOrder.slice(0, currentIndex);
    });

    function computeStatusState(card: IStatusCard | IRegularCard): StateStatus {
        if ((!userVipStatus.value && card.name === USER_REGULAR_STATUS) || card.name === userVipStatus.value) {
            return StateStatus.ACTIVE;
        }
        if (card.name === nextQuarterStatus.value) {
            return StateStatus.PROGRESS;
        }

        if (card.name === USER_REGULAR_STATUS || achievedStatus.value.includes(card.name)) {
            return StateStatus.ACHIEVED;
        }

        return StateStatus.LOCK;
    }

    function computeActiveStatusState(card: IStatusCard | IRegularCard): StateStatus {
        if (card.name === activeStatus.value) {
            return StateStatus.ACTIVE;
        }
        if (card.name === nextActiveStatus.value) {
            return StateStatus.PROGRESS;
        }

        if (card.name === USER_REGULAR_STATUS || achievedActiveStatus.value.includes(card.name)) {
            return StateStatus.ACHIEVED;
        }

        return StateStatus.LOCK;
    }

    function getStatusNameByCode(code: number): string {
        const status = userStatusCodes[code];

        return `VIP_CLUB_PAGE.STATUS_BLOCK.${status}.TITLE`;
    }

    return {
        quarterStatus,
        activeStatus,
        userVipStatus,
        nextQuarterStatus,
        nextActiveStatus,
        achievedStatus,
        computeStatusState,
        computeActiveStatusState,
        getStatusNameByCode,
    };
}
