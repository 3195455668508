<script setup lang="ts">
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
import config from "@theme/configs/config";
import "overlayscrollbars/overlayscrollbars.css";

const background = config.colors.scrollBar;
const options: Record<string, unknown> = {
    showNativeOverlaidScrollbars: false,
    scrollbars: {
        theme: "os-theme-dark",
        visibility: "auto",
        autoHide: "leave",
        autoHideDelay: 300,
        autoHideSuspend: false,
        dragScroll: true,
        clickScroll: false,
        pointers: [ "mouse", "touch", "pen" ],
    },
};
</script>

<template>
    <OverlayScrollbarsComponent
        class="fe-scroll"
        :options="options"
        defer
        body-scroll-lock-ignore
    >
        <slot />
    </OverlayScrollbarsComponent>
</template>

<style>
.fe-scroll {
    overflow: hidden;

    .os-scrollbar {
        --os-handle-bg: v-bind(background);
        --os-handle-bg-hover: v-bind(background);
        --os-handle-bg-active: v-bind(background);
    }
}
</style>
