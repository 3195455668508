import cookieController from "@front/core/controllers/CookieController";
import { getIsLicenseDomain } from "@controllers/services/domains";
import { AVAILABLE_LOCALES, AVAILABLE_LOCALES_LICENSE_DOMAINS } from "@theme/configs/constsLocales";
import { isServer } from "@helpers/ssrHelpers";

const KEY_LOCALE = "locale";

export function getLocale() {
    return cookieController.get(KEY_LOCALE);
}

export function setLocaleCookie(lang: string) {
    const localeInCookie = cookieController.get(KEY_LOCALE);

    if (localeInCookie === lang) {
        return;
    }
    cookieController.set(KEY_LOCALE, lang, { "expires": 30 * 86400, "path=/": true });
}

export function getAvailableLocales() {
    if (!isServer) {
        const domainHostname = location.hostname;
        return getIsLicenseDomain(domainHostname) ? AVAILABLE_LOCALES_LICENSE_DOMAINS : AVAILABLE_LOCALES;
    }

    return AVAILABLE_LOCALES;
}
