import log from "@front/core/controllers/LoggerController";
import { publicApiV1 } from "@api/publicApi";
import type { IRegionResource } from "@api";

export default {
    async getRegionsByCountryCode(countryCode: string) {
        try {
            const response = await publicApiV1<IRegionResource[]>({
                url: "/core/country-region/list",
                type: (securedType) => `Core.V1.${securedType}.CountryRegion.List`,
                data: {
                    filter: {
                        countryCode,
                    },
                },
            });
            if (response.error) {
                log.error("REGIONS_BY_COUNTRY_CODE_SERVER", response.error);
                return [];
            }
            return response.data;
        } catch (error) {
            log.error("REGIONS_BY_COUNTRY_CODE", error);
            return [];
        }
    },
};
