<script setup lang="ts">
import { computed } from "vue";
import { useModalControls } from "@modules/ModalController/useModalControls";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import FeScroll from "@ui-kit/FeScroll/FeScroll.vue";
import ToolbarSteps from "@components/ToolbarSteps/index.vue";
import { type IToolBarSteps } from "@components/ToolbarSteps/index.vue";
import FeLoader from "@ui-kit/FeLoader/index.vue";
const { closeHandler } = useModalControls();

interface IProps {
    title?: string;
    toolbarMode?: IToolBarSteps | undefined;
    specialType?: boolean;
    minHeight?: boolean;
    fullscreen?: boolean;
    isLoaded?: boolean;
    hideClose?: boolean;
    contentFullHeight?: boolean;
}

const props = withDefaults(defineProps<IProps>(), {
    isLoaded: true,
});

const emit = defineEmits([ "closeButton" ]);

const classes = computed(() => ({
    "modal-v2--fixed-height": props.specialType,
    "modal-v2--fullscreen": props.fullscreen,
    "modal-v2--min-height": props.minHeight,
    "modal-v2--full-height": props.contentFullHeight,
}));

function closeButtonHandler() {
    emit("closeButton");
    closeHandler();
}

</script>
<template>
    <section class="modal-v2" :class="classes">
        <div class="modal-v2__header">
            <slot name="title">
                <ToolbarSteps
                    v-if="toolbarMode"
                    :options="toolbarMode"
                />
                <h5
                    v-else-if="title"
                    class="text--align-center"
                    v-html="title"
                />
            </slot>
            <FeIcon
                v-if="!hideClose"
                class="modal-v2__close"
                :icon="FeIconConfig.close"
                @click="closeButtonHandler"
            />
        </div>
        <div v-if="!isLoaded">
            <FeLoader :size="4" />
        </div>
        <template v-else>
            <FeScroll>
                <div class="modal-v2__content" gap-s>
                    <slot />
                </div>
            </FeScroll>
            <div v-if="$slots.bottom" gap-s>
                <slot name="bottom" />
            </div>
        </template>
    </section>
</template>

<style lang="scss" scoped>
@import "~@theme/styles";
$root: modal-v2;

section.#{$root} {
    @include gap-s;
    background-color: $color-layer-body;
    border-radius: $border-radius-S $border-radius-S 0 0;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 80vh;
    min-height: 40vh;
    width: 100%;
    box-sizing: border-box;
    grid-template-rows: min-content 1fr;

    & .#{$root}__header {
        position: relative;
        color: $color-text-alt;
    }

    & .#{$root}__close {
        position: absolute;
        right: 0;
        top:0;
    }

    &.#{$root}--fixed-height {
        height: 80vh;
        max-height: 80vh;
    }

    &.#{$root}--fullscreen {
        border-top: 0.25rem solid $color-secondary-1;
        height: 100%;
        max-height: 100%;

        border-radius: 0;
    }
    &.#{$root}--min-height {
        min-height: auto;
    }

    &.#{$root}--full-height {
        .#{$root}__content {
            height: 100%;
        }
    }

    @include media(M) {
        bottom: 50%;
        transform: translate(-50%, 50%);
        width: 27rem;
        border-radius: $border-radius-S;
        max-height: 60vh;
        min-height: 25vh;

        &.#{$root}--fullscreen {
            border: 0;
            border-radius: $border-radius-S;
            height: 80vh;
            max-height: 80vh;
        }
    }
}
</style>
