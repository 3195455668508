import type {
    IChooseTournamentResource,
    ITournamentListResource,
    ITournamentResource,
    IUserTournamentListResource,
} from "@api";
import { publicApiV1 } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";
import { jsonHttp } from "../http";
import { jsonApi } from "../jsonApi";

export default {
    async loadTournament(slug: string): Promise<ITournamentResource> {
        try {
            return await jsonApi<ITournamentResource>(`/tournaments/${slug}`, {
                method: "GET",
            });
        } catch (error: unknown) {
            log.error("LOAD_TOURNAMENT_BY_SLUG", error);
            throw error;
        }
    },
    async chooseTournament(id: string): Promise<IChooseTournamentResource> {
        try {
            return await jsonHttp<IChooseTournamentResource>(`/app/tournaments/phase/choose/${id}`);
        } catch (error: unknown) {
            log.error("CHOOSE_TOURNAMENT_BY_ID", error);
            throw error;
        }
    },
    async loadTournamentList(tags: string | string[] = [], page: number, perPage: number) {
        const tagList = Array.isArray(tags) ? tags : [ tags ];
        try {
            return await publicApiV1<ITournamentListResource[]>({
                url: "/tournaments/list",
                type: (securedType) => `Tournament.${securedType}.Tournament.List`,
                secured: false,
                data: {
                    filter: {
                        tags: tagList,
                    },
                    pagination: {
                        pageNumber: page,
                        perPage,
                    },
                },
            });
        } catch (error: unknown) {
            log.error("LOAD_TOURNAMENT_LIST", error);
        }
    },
    async loadUserTournamentList(): Promise<IUserTournamentListResource[] | undefined> {
        try {
            const response = await publicApiV1({
                url: "/tournaments/participant/list",
                type: (securedType) => `Tournament.${securedType}.Tournament.UserParticipation.List`,
                secured: true,
            });
            return response.data || [];
        } catch (error: unknown) {
            log.error("LOAD_USER_TOURNAMENT_LIST", error);
        }
    },
};
