import type { RouteLocationRaw } from "vue-router";
import { routeNames } from "@router/routeNames";
import { useUserBalance } from "@store/userBalance";
import { storeToRefs } from "pinia";
import { v4 as uuid } from "uuid";
import { computed } from "vue";

export interface IBonusMessage {
    id: string;
    description: string;
    multiplier?: string;
    page?: {
        text: string;
        route: RouteLocationRaw;
    } | null;
    button: {
        text: string;
        route: RouteLocationRaw;
    };
    amount: number | null;
    amountTitle?: string;
}

export function useUserBalanceServices() {
    const { balanceData, winbackData } = storeToRefs(useUserBalance());

    const realBalance = computed<number>(() => {
        return balanceData.value.balance;
    });

    const commonBalance = computed<number>(() => {
        return realBalance.value + commonBonusBalance.value;
    });

    const totalWagering = computed<number>(() => {
        return balanceData.value.totalWagering;
    });

    const userWagered = computed<number>(() => {
        return totalWagering.value - userWagering.value;
    });

    const userWagering = computed<number>(() => {
        return balanceData.value.wagering;
    });

    const commonBonusBalance = computed<number>(() => {
        return bonusBalance.value + bettingBonusBalance.value + freeBet.value;
    });

    const bonusBalance = computed<number>(() => {
        return balanceData.value.bonus;
    });

    const bettingBonusBalance = computed<number>(() => {
        return balanceData.value.bonuses?.sport?.sum || 0;
    });

    const freeBet = computed<number>(() => {
        return balanceData.value.bonuses?.freeBet?.sum || 0;
    });

    const userBettingTotalWagering = computed<number>(() => {
        return balanceData.value.bonuses?.sport?.totalWagering || 0;
    });

    const userBettingWagered = computed<number>(() => {
        return userBettingTotalWagering.value - userBettingWagering.value;
    });

    const userBettingWagering = computed<number>(() => {
        return balanceData.value.bonuses?.sport?.sumWagering || 0;
    });

    const isWinbackOn = computed<boolean>(() => {
        return winbackData.value.winbackStatus && winbackData.value.winback > 0;
    });

    const isWithdrawalBlocked = computed<boolean>(() => {
        return freeBet.value > 0 ||
            userBettingWagering.value > 0 ||
            bettingBonusBalance.value > 0 ||
            bonusBalance.value > 0 ||
            isWinbackOn.value;
    });

    const bonusMessages = computed<IBonusMessage[]>(() => {
        const sportRoutePath = "/sports-bonuses";
        const bonusRouteSlug = "bonus-wagering";

        const PERCENTS = 100;

        const messages = [];

        if (freeBet.value) {
            messages.push({
                id: uuid(),
                description: "CASHBOX.WITHDRAW_BONUS_MESSAGE_FREEBET",
                page: {
                    text: "CASHBOX.WITHDRAW_BONUS_MESSAGE_CANCEL_BONUS",
                    route: { path: sportRoutePath },
                },
                button: {
                    text: "CASHBOX.WITHDRAW_BONUS_MESSAGE_BETTING_BUTTON",
                    route: { name: routeNames.bettingPage },
                },
                amount: null,
            });
        }
        if (userBettingWagering.value || bettingBonusBalance.value) {
            messages.push({
                id: uuid(),
                description: "CASHBOX.WITHDRAW_BONUS_MESSAGE_BETTING",
                page: {
                    text: "CASHBOX.WITHDRAW_BONUS_MESSAGE_CANCEL_BONUS",
                    route: { path: sportRoutePath },
                },
                button: {
                    text: "CASHBOX.WITHDRAW_BONUS_MESSAGE_BETTING_BUTTON",
                    route: { name: routeNames.bettingPage },
                },
                amount: userBettingWagering.value,
            });
        }
        if (bonusBalance.value) {
            messages.push({
                id: uuid(),
                description: "CASHBOX.WITHDRAW_BONUS_MESSAGE",
                page: null,
                button: {
                    text: "CASHBOX.WITHDRAW_BONUS_MESSAGE_BONUS_BUTTON",
                    route: {
                        name: routeNames.casinoCategory,
                        params: { slug: bonusRouteSlug },
                    },
                },
                amount: userWagering.value,
            });
        }
        if (isWinbackOn.value) {
            messages.push({
                id: uuid(),
                description: "CASHBOX.WITHDRAW_WINBACK_MESSAGE",
                multiplier: `${winbackData.value.multiplier * PERCENTS}%`,
                button: {
                    text: "CASHBOX.WITHDRAW_WINBACK_MESSAGE_BUTTON",
                    route: { name: routeNames.main },
                },
                amount: winbackData.value.winback,
                amountTitle: "CASHBOX.WITHDRAW_WINBACK_NUMBER_OF_BETS_TEXT",
            });
        }
        return messages;
    });

    const winback = computed(() => {
        if (isWinbackOn.value) {
            return {
                value: winbackData.value.winback,
                multiplier: winbackData.value.multiplier,
            };
        }
    });

    return {
        commonBalance,
        totalWagering,
        userWagered,
        userWagering,
        realBalance,
        commonBonusBalance,
        bonusBalance,
        bettingBonusBalance,
        freeBet,
        userBettingTotalWagering,
        userBettingWagered,
        userBettingWagering,

        isWithdrawalBlocked,
        bonusMessages,
        winback,
    };
}
