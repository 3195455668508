import type { IWinnerResource } from "@api";
import { publicApiV1 } from "@api/publicApi";
import log from "@front/core/controllers/LoggerController";

export default {
    async loadWinnersData(secured: boolean) {
        try {
            const response = await publicApiV1<IWinnerResource | null>({
                url: "/winners/get",
                secured,
                type: (securedType) => `Winners.V1.${securedType}.Winners.Get`,
            });
            return response.data;
        } catch (error) {
            log.error("LOAD_WINNERS_DATA", error);
        }
    },
};
