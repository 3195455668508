import { PROMO_MECHANIC, PROMOTION_STATUS, PROMOTION_TYPE } from "@controllers/services/promotions";
import type { Localisation } from "./commonResources";

export enum TournamentGameTypes {
    CASINO = "casino",
    LIVE = "live",
}

export enum TournamentAccessVoter {
    PARTNER = "PartnerVoter",
    USER = "UserVoter",
    ACTION = "ActionVoter",
    DEPOSIT = "DepositVoter",
    DEPOSIT_LT = "DepositLtVoter",
    BOT = "BotVoter",
    LEVEL = "LevelVoter",
    STATUS = "UserStatusVoter",
}

export interface ITournamentGift {
    id: string;
    localisation: Localisation;
    shortDescription: string;
    title: string;
}
export interface ITournamentMember {
    authorizedUser: boolean;
    nickname: string;
    gifts: ITournamentGift[];
    id: string;
    localisation: Localisation;
    shortDescription?: string;
    title: string;
    lastResult: {
        id: string;
        image: string;
        path: string;
        slug: string;
        title: string;
        nickname: string;
    };
    position: number;
    score: number;
}
export interface ITournamentResource {
    id: string;
    slug: string;
    customBadge: null;
    type: string;
    image: string;
    imageSvg: string;
    victoryType: string;
    position: null;
    membership: boolean;
    title: string;
    description: string;
    rules: string;
    prize: Record<string, string>;
    prizeFund: string;
    status: string;
    endAt: string;
    startAt: string;
    bet: {
        min: Record<string, number>;
        max: Record<string, number>;
    };
    access: Record<TournamentAccessVoter, unknown> | null;
    sortOrder: null;
    meta: Record<string, string>;
    gifts: {
        position: number;
        gifts: ITournamentGift[];
    }[];
    giftsCount: number;
    members: ITournamentMember[];
    winners: ITournamentMember[];
    games: null;
    gamesCount: number;
    pageType: null;
    bgColor: string;
    localisation: Localisation;
    gameTypes: TournamentGameTypes[];
}

export interface IChooseTournamentResource {
    status: string;
}

export interface ITournamentListResource {
    bgColor?: string;
    endAt: string;
    fund?: string;
    id: string;
    image: string;
    imageSvg: string;
    localisation: Localisation;
    pageType?: PROMO_MECHANIC | null;
    slug: string;
    sortOrder: number;
    startAt: string;
    status: PROMOTION_STATUS;
    title: string;
    type: PROMOTION_TYPE;
    gameTypes: TournamentGameTypes[];
    access?: boolean;
    prizeFund?: string;
    tags: string[];
}

export interface IUserTournamentListResource {
    id: string;
    slug: string;
    status: string;
    title: string;
    tags: string[];
    gameTypes: TournamentGameTypes[];
}
