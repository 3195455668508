import type { IUserConsentDTO } from "@api";
import { Currency, type Localisation, registrationTypes } from "@api/resources/commonResources";
import { USER_REGULAR_STATUS, UserVipStatus } from "@controllers/services/user";

// verification.status - legacy
export enum verificationsStatusOld {
    INITIAL = "initial",
    VERIFIED = "verified",
    APPROVED = "approved",
    PENDING = "pending",
}
export enum VerificationsStatus {
    INITIAL = "initial",
    REJECTED = "rejected",
    RECHECK = "recheck",
    APPROVED = "approved",
    PENDING = "pending",
    IN_PROGRESS = "in progress",
}

export enum profileType {
    SIMPLE = "type.profile.simple",
    HARD = "type.profile.hard",
    BRAZIL = "type.profile.brazil"
}

export enum treasuryType {
    SIMPLE = "type.treasury.simple",
    HARD = "type.treasury.hard",
}

export enum NotificationStatus {
    NEW = "new",
    READ = "read"
}

export enum VipManagerGender {
    MALE = "male",
    FEMALE = "female"
}

export enum SelfExclusionStatus {
    COOLING_OFF_INIT = "cooling_off_init",
    COOLING_OFF_ACTIVE = "cooling_off_active",
    SELF_EXCLUSION_WAITING = "self_exclusion_waiting",
}

export interface IUserRestorePasswordResource {
    is_max_attempts_used: boolean;
    success: boolean;
    errors: string[] | Record<string, string[]>;
}

export interface IUserFeatureResource {
    feature: string;
    isAvailable: boolean;
}

export interface IUserBettingTokenResource {
    clientId: number;
    isNew: boolean;
    token: string;
}

export interface IUserWinbackDataResource {
    multiplier: number;
    winback: number;
    winbackStatus: boolean;
}

export interface IUserKYCData {
    token: string;
}

export interface IVerificationPaymentMethod {
    cardNumber: string;
    expiryDate: string;
    icon: string;
    id: string;
    isVerified: boolean;
    methodName: string;
    verificationStatus: VerificationsStatus;
}

export interface IUserConsentsResource {
    consents: IUserConsentDTO[];
    info?: {
        email?: string;
    };
}

export interface IUserStrategiesResource {
    [key: string]: {
        type: string;
    };
}

export interface IUserCallbackDataResource {
    callbackStatus?: "rate-limited" | "queued";
    sentDatetime: string;
}

export interface IUserUpdateProfileResource {
    success: boolean;
    errors?: Record<string, string[]>;
}

export interface IVipManagerResource {
    id: string;
    publicName: string;
    privateName: string;
    email: string;
    whatsapp: string;
    telegram: string;
    profilePicture: string;
    phoneNumbers: string;
    gender: VipManagerGender;
    dateStart: {
        date: string;
        weekday: number;
    };
    dateEnd: {
        date: string;
        weekday: number;
    };
}

export interface IUserProfileResource {
    id: string;
    user_id: string;
    lastname: string | null;
    firstname: string | null;
    middlename: string | null;
    address: string | null;
    country: string | null;
    gender: string | null;
    birthday: string | null;
    street: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    birthday_verified: boolean;
    nick_name: string | null;
    hash: string;
    emails: Array<{
        email: string;
        is_primary: boolean;
        is_verified: boolean;
    }>;
    phones: Array<{
        phone: string;
        is_primary: boolean;
        is_verified: boolean;
    }>;
    registration_contact_type: string;
    multi_account: boolean;
    support_manager_id: string | null;
    status: number;
    currency?: Currency;
    localization: string;
    verification: {
        isAntiFraudVerified: boolean;
        isVerified: boolean;
        status: verificationsStatusOld;
        verificationStatus: VerificationsStatus;
        isFullVerificationPassed?: boolean;
        paymentMethods?: IVerificationPaymentMethod[];
    };
    chosen_country: string;
    user_type: string;
    vipManager: IVipManagerResource | null;
    selfExclusionStatus: SelfExclusionStatus | null;
    isSuspended?: boolean | null;
}

export interface IUserFastTrackSIDResource {
    sid: string;
}

export interface ISportBonus {
    sumWagering: number;
    totalWagering: number;
    sum: number;
}
export interface IUserBalanceBonusesWagering {
    userGiftId: string;
    sum: number;
    sumWagering: number;
}
export interface IUserBalanceResource {
    user_id: string;
    balance: number;
    currency: Currency;
    is_active_bonus: boolean;
    bonus: number;
    wagering: number;
    totalWagering: number;
    bonuses: {
        sport: ISportBonus | null;
        freeBet: { sum: number; } | null;
    };
    bonusesWagering?: {
        sport: IUserBalanceBonusesWagering | null;
        casino: IUserBalanceBonusesWagering | null;
    };
}

export enum UserStatusResource {
    NO_SET = 0,
    NEW = 10,
    ACE = 20,
    ACE2 = 30,
    ACE3 = 40,
    EXVIP = 50,
    STAR = 60,
    BRONZE = 70,
    SILVER = 80,
    GOLD = 90,
    PLATINUM = 100,
    DIAMOND = 110,
}

export interface IUserNotificationVarsResource {
    phaseId?: string;
    phaseName?: string;
    phaseNames?: Record<string, string>;
    [key: string]: unknown;
}
export interface IUserNotificationResource {
    button: {
        link: string;
        name: string;
    };
    code: string;
    created_at: number;
    expired_at: number;
    id: string;
    img_url: string;
    key: string;
    localisation?: Localisation;
    msg: string;
    parent: string | null;
    restricted_countries: string[];
    status: NotificationStatus;
    updated_at: number;
    vars?: IUserNotificationVarsResource;
}

export interface ICreateContactResource {
    expired: null;
    isMaxAttemptUsed: null;
    status: string;
    title: string;
    type: registrationTypes;
    value: string;
    waitTime: null;
}

export interface IUserContactsOnVerificationResource {
    value: string;
    type: registrationTypes;
    title: string;
    expired: string;
}
export interface IUserStatusResource {
    userId: string | null;
    currency: Currency;
    currentStatus: keyof typeof UserStatusResource;
    currentStatusInt: UserStatusResource;
    nextStatus: UserVipStatus;
    nextStatusInt: UserStatusResource;
    activeStatus: UserVipStatus | typeof USER_REGULAR_STATUS;
    depositAmountCents: number;
    depositThresholdCents: number;
    betSumCents: number;
    betSumThresholdCents: number;
    overallProgress: number;
}
