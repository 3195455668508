<template>
    <div class="progress-bar-line">
        <div
            v-for="step in size"
            :key="step"
            class="progress-bar-line__step"
            :class="{ 'progress-bar-line__step--active': activeStep(step) }"
        />
    </div>
</template>

<script>
export default {
    name: "ProgressBarLine",

    props:{
        currentValue:{
            type: Number,
        },
        size: {
            type: Number,
            default: 4,
        },
    },

    methods: {
        activeStep(step) {
            return step <= this.currentValue;
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@theme/styles";

.progress-bar-line {
    width: 100%;
    display: grid;
    grid-gap: 0.25rem;
    grid-auto-flow: column;

    &__step {
        background: $color-secondary-4;
        border-radius: $border-radius-M;
        height: 0.25rem;

        &--active {
            background: $color-primary-1;
        }
    }

}
</style>
