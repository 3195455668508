export enum GetUserGiftsAvailability {
    AVAILABLE = "available",
    ACTIVE = "active",
    HISTORY = "history",
}

export enum GetUserGiftsSubtype {
    SPORT = "sport",
    CASINO = "casino",
}


export interface IGetUserGiftsDTO {
    filter: {
        availability: GetUserGiftsAvailability;
        subType: GetUserGiftsSubtype;
    },
    pagination?: {
        pageNumber: number;
        perPage: number;
    },
}
