<script setup lang="ts">
import type { IAchievementResource } from "@api";
import { useContextStore } from "@store/context";
import { computed } from "vue";
import { routeNames } from "@router/routeNames";
import FeLink from "@ui-kit/FeLink/FeLink.vue";
import QuestsCard from "./QuestsCard.vue";
import FeButton, * as FeButtonConfig from "@ui-kit/FeButton/index.vue";
import CarouselScroll from "@ui-kit/CarouselScroll/CarouselScroll.vue";
import FeIcon from "@ui-kit/FeIcon/index.vue";
import { FeIconConfig } from "@theme/configs/icons";
import { type RouteLocationRaw } from "vue-router";
import { storeToRefs } from "pinia";
import QuestsCardEmpty from "./QuestsCardEmpty.vue";

interface IProps {
    quests: IAchievementResource[];
    title?: string;
    disableFadeEffect?: boolean;
    propsRoute?: RouteLocationRaw;
    showEmptyCard?: boolean;
}

interface IEmits {
    (event: "showAll"): void;
}
defineEmits<IEmits>();

const props = defineProps<IProps>();

const { isMobile } = storeToRefs(useContextStore());

const toRoute = computed(() => {
    if (props.propsRoute) {
        return props.propsRoute;
    }
    return { name: routeNames.promotionsCasinoQuests };
});
</script>
<template>
    <div class="quests-card-carousel">
        <div v-if="title" class="quests-card-carousel__head">
            <div class="h2" data-test="title">
                {{ title }}
            </div>
            <FeLink :to="toRoute" class="quests-card-carousel__link" @click="$emit('showAll')">
                <FeButton v-if="!isMobile" :theme="FeButtonConfig.themes.TertiaryExtraSmall" data-test="show-all">
                    {{ $t("COMMON.SHOW_ALL") }}
                </FeButton>
                <FeIcon v-else class="quests-card-carousel__arrow" :icon="FeIconConfig.dropdownRight" />
            </FeLink>
        </div>
        <CarouselScroll>
            <QuestsCard v-for="item in quests" :key="item.id" :quest="item" />
            <QuestsCardEmpty v-if="showEmptyCard" key="empty" :props-route="toRoute" />
        </CarouselScroll>
    </div>
</template>

<style scoped lang="scss">
@import "~@theme/styles/index";
.quests-card-carousel {
    overflow: hidden;
    --carousel-item-width: 18rem;
    --carousel-gap: 0.5rem;

    @include media(M) {
        --carousel-gap: 1rem;
    }
    &__head {
        display: flex;
        justify-content: space-between;
        color: $color-text-alt;
        margin-bottom: 0.5rem;

        @include media(M) {
            margin-bottom: 1rem;
        }
    }

    &__link {
        text-decoration: none;
    }

    &:deep(.carousel-scroll__item) {
        height: 100%;
    }
}
</style>
