import { achievementsAPI, type IAchievementResource } from "@api";
import { defineStore } from "pinia";
import { ref } from "vue";
import { sortAchievements } from "../helpers/sortAchievements";

export const useAchievements = defineStore("achievements", () => {
    const personalizedAchievements = ref<IAchievementResource[]>([]);
    async function loadPersonalizedAchievements() {
        const data = await achievementsAPI.loadPersonalizedAchievements();
        personalizedAchievements.value = sortAchievements(data);
    }

    function cleanPersonalizedAchievements() {
        personalizedAchievements.value = [];
    }

    function updatePersonalizedAchievements(data: IAchievementResource) {
        let isNew = true;
        const achievements = personalizedAchievements.value.map((achiev: IAchievementResource) => {
            let buffer = achiev;
            if (achiev.id === data.id) {
                isNew = false;
                buffer = data;
            }
            return buffer;
        });

        if (isNew) {
            achievements.push(data);
        }

        personalizedAchievements.value = achievements;
    }

    function userAchievementByPromoId(promoId: string): IAchievementResource[] {
        return personalizedAchievements.value.filter((achiev: IAchievementResource) => {
            if (achiev?.actions.length) {
                return achiev.actions.some((action: { id: string }) => {
                    return action.id === promoId;
                });
            }
            return false;
        });
    }

    return {
        personalizedAchievements,
        loadPersonalizedAchievements,
        cleanPersonalizedAchievements,
        updatePersonalizedAchievements,
        userAchievementByPromoId,
    };
});
