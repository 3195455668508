import { Currency } from "@api/resources/commonResources";
import { COUNTRIES } from "@theme/configs/constsLocales";

const DEFAULT_CURRENCY_BY_GEO = {
    [COUNTRIES.BRASILIA]: Currency.BRL,
    [COUNTRIES.CANADA]: Currency.CAD,
    [COUNTRIES.AUSTRALIA]: Currency.AUD,
    [COUNTRIES.NEW_ZEALAND]: Currency.NZD,
    [COUNTRIES.INDIA]: Currency.INR,
    [COUNTRIES.USA]: Currency.USD,
};

const DEFAULT_CURRENCY = Currency.EUR;

export {
    DEFAULT_CURRENCY_BY_GEO,
    DEFAULT_CURRENCY,
};
